import { forwardRef } from "react";
import {
  Chip as MantineChip,
  ChipProps as MantineChipProps,
  Tooltip,
} from "@mantine/core";
import { Accessibility } from "@/services/Accessibility";

export interface ChipProps extends MantineChipProps {
  onClick?: () => void;
  ariaLabel: string;
}

export const Chip = forwardRef<HTMLInputElement, ChipProps>((props, ref) => {
  const { className, styles, checked, onClick, ariaLabel, ...rest } = props;
  return (
    <Tooltip
      label={ariaLabel}
      events={{ hover: true, focus: true, touch: true }}
      position="top"
      withArrow
    >
      <MantineChip
        role="checkbox"
        aria-checked={checked}
        aria-label={ariaLabel}
        checked={checked}
        className={`adv-chip ${checked ? "adv-chip-checked" : ""} ${className || ""}`}
        tt="none"
        radius="xl"
        opacity={checked ? 1 : 0.75}
        styles={{
          ...styles,
          iconWrapper: {
            display: "none",
          },
        }}
        onClick={onClick}
        onKeyDown={(e) => Accessibility.handleOnKeyDown({ e, onClick })}
        tabIndex={Accessibility.handleTabIndex({ onClick })}
        {...rest}
        ref={ref}
      />
    </Tooltip>
  );
});

Chip.displayName = "Chip";
