import { Text } from "@/components/ui/Text";
import { Title } from "@/components/ui/Title";
import { Stack } from "@mantine/core";
import { Image } from "@/components/ui/Image";
import demoAnayticsImage1 from "@/assets/demo-analytics-1.png";
import demoAnayticsImage2 from "@/assets/demo-analytics-2.png";

export const DemoAnalyticsPage = () => {
  return (
    <Stack
      align="center"
      justify="center"
      // h="100%"
      h="100vh"
      mah="100vh"
      mx="auto"
      ta="left"
      pt={100}
      style={{ overflow: "scroll" }}
    >
      <Stack maw={600} pb={200} pt={100}>
        <Title w="100%">Usage Analytics and Outcome Metrics</Title>
        <Text>
          Advize provides detailed analytics and outcome data that make it easy
          to track the measurable outcomes for your students' progress, alumni
          engagement, and more.
        </Text>
        <Text size="md">
          See below for a sampling of the types of data we provide.
        </Text>

        <Image
          alt="Analytics dashboard Preview 1"
          src={demoAnayticsImage1}
          w={600}
          ratio={16 / 9}
          h="auto"
          fit="contain"
        />
        <Image
          alt="Analytics dashboard Preview 2"
          src={demoAnayticsImage2}
          w={600}
          fit="contain"
          ratio={27 / 9}
        />
      </Stack>
    </Stack>
  );
};
