import {
  useIsTablet,
  useIsDesktop,
  useIsLaptopOrBigger,
  useStyles,
} from "@/styles/useStyles";
import {
  useActiveUserPartner,
  useArrayOfSelectedAdvizerSearchFilters,
  useIsSearchingAdvizers,
} from "@/store/selectors";
import { AdvizerSearchDrawer } from "@/components/drawers/AdvizerSearchDrawer";
import { Stack, Group, Box, Space, Switch } from "@mantine/core";
import { useStore } from "@/hooks";
import { useCallback } from "react";
import { AdvizerSearchInput } from "./AdvizerSearchInput";
import { TotalVisibleAdvizersIndicator } from "./TotalVisibleAdvizersIndicator";
import { QuestionSelector } from "./QuestionSelector";
import { ResetAdvizerSearchButton } from "./ResetAdvizerSearchButton";
import { AdvizerFilterButton } from "./AdvizerFilterButton";
import { SelectedSearchTags } from "./SelectedSearchTags";
import { BarLoader } from "@/components/ui/BarLoader";

export const AdvizerSearchControls = () => {
  const { theme, ...styles } = useStyles();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const activeSearchGradDegree = useStore(
    (state) => state.advizerSearch.activeSearchGradDegree,
  );
  const setActiveSearchGradDegree = useStore(
    (state) => state.advizerSearch.setActiveSearchGradDegree,
  );

  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const userPartner = useActiveUserPartner();

  const { isSearchingAdvizers } = useIsSearchingAdvizers();

  const arrayOfSelectedAdvizerSearchFilters =
    useArrayOfSelectedAdvizerSearchFilters();

  const renderLoadingBar = useCallback(() => {
    if (!isSearchingAdvizers) return null;
    return <BarLoader />;
  }, [isSearchingAdvizers]);

  const renderContent = useCallback(() => {
    // if (isAdvizerProfilePage && false) {
    //   if (isLaptopOrBigger) {
    //     return (
    //       <>
    //         <Group
    //           p={0}
    //           justify="space-between"
    //           wrap="nowrap"
    //           align="flex-start"
    //         >
    //           <Group gap="sm">
    //             <Group flex={1} justify="flex-start" gap={"xs"}>
    //               <Box flex={1} maw={350} miw={200}>
    //                 <AdvizerSearchInput />
    //               </Box>
    //               <Box w={300} flex={1}>
    //                 <QuestionSelector />
    //               </Box>
    //             </Group>
    //             <TotalVisibleAdvizersIndicator />
    //             <SelectedSearchTags />
    //             <ResetAdvizerSearchButton />
    //           </Group>
    //           <Group>
    //             <AdvizerFilterButton />
    //           </Group>
    //         </Group>
    //       </>
    //     );
    //   }

    //   return (
    //     <>
    //       <Stack
    //         gap={arrayOfSelectedAdvizerSearchFilters.length > 0 ? "xs" : 0}
    //       >
    //         <Group
    //           p={0}
    //           justify="space-between"
    //           wrap="nowrap"
    //           align="flex-start"
    //         >
    //           <Group gap="sm" flex={1}>
    //             <QuestionSelector />
    //             <TotalVisibleAdvizersIndicator />
    //           </Group>
    //           <Group>
    //             <AdvizerFilterButton />
    //           </Group>
    //         </Group>
    //         <Group gap="xs">
    //           <SelectedSearchTags />
    //           <ResetAdvizerSearchButton />
    //         </Group>
    //       </Stack>
    //     </>
    //   );
    // }

    if (isLaptopOrBigger) {
      return (
        <>
          <Stack px="sm" gap="xs">
            <Group justify="space-between">
              <Group flex={1} justify="flex-start" gap={"xs"}>
                <Box pr="sm">
                  <AdvizerFilterButton />
                </Box>
                <Box flex={1} maw={300} miw={220}>
                  <AdvizerSearchInput />
                </Box>
                <Box flex={1} maw={300} miw={220}>
                  <QuestionSelector />
                </Box>
                {userPartner?.showMBAToggle && (
                  <>
                    <Space w="xs" />
                    <Box flex={1} maw={300} miw={220}>
                      <Switch
                        size="md"
                        color="primary"
                        label="Show MBAs only"
                        styles={{
                          label: {
                            fontWeight: 500,
                          },
                        }}
                        checked={activeSearchGradDegree === "MBA"}
                        onChange={(e) => {
                          setActiveSearchGradDegree(
                            e.target.checked ? "MBA" : null,
                          );
                        }}
                      />
                    </Box>
                  </>
                )}
                <Box px="xs">
                  <TotalVisibleAdvizersIndicator />
                </Box>
              </Group>
              {/* <Group> */}

              {/* </Group> */}
            </Group>
            {arrayOfSelectedAdvizerSearchFilters.length > 0 && (
              <Group>
                <SelectedSearchTags />
                <ResetAdvizerSearchButton />
              </Group>
            )}
          </Stack>
        </>
      );
    }

    if (isTablet) {
      return (
        <>
          <Group>
            <Box flex={1}>
              <AdvizerSearchInput />
            </Box>
            <Box flex={1}>
              <QuestionSelector />
            </Box>
            <TotalVisibleAdvizersIndicator />
            <AdvizerFilterButton />
            <ResetAdvizerSearchButton />
          </Group>
          <AdvizerSearchDrawer />
        </>
      );
    }

    return (
      <Stack gap="xs">
        <Group w="100%">
          <AdvizerSearchInput />
          <TotalVisibleAdvizersIndicator />
        </Group>
        <Group p={0} gap="xs">
          <QuestionSelector />
          <AdvizerFilterButton />
          <ResetAdvizerSearchButton />
        </Group>

        <AdvizerSearchDrawer />
      </Stack>
    );
  }, [
    activeSearchGradDegree,
    arrayOfSelectedAdvizerSearchFilters.length,
    isLaptopOrBigger,
    isTablet,
    setActiveSearchGradDegree,
    userPartner?.showMBAToggle,
  ]);

  return (
    <Stack
      px="xs"
      pt="xs"
      gap={0}
      pos="sticky"
      top={collapseHeader ? 0 : styles.dimensions.headerHeight}
      // maw={1200}
      style={{
        zIndex: 10,
        boxShadow: `1px 0 9px 0 ${theme.colors.slate[4]}`, // big shadow for nav bar so bottom has a shadow
        // borderBottom: `1px solid ${theme.colors.border[2]}`,
      }}
      bg={isDesktop ? "slate.0" : "slate.0"}
    >
      <Stack
        p={0}
        m={0}
        maw={1200}
        w="100%"
        mx="auto"
        gap={0}
        // style={{ overflowX: "hidden" }}
        pos="relative"
      >
        {renderContent()}
        <Space h="xs" />
        {renderLoadingBar()}
      </Stack>
    </Stack>
  );
};
