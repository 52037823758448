import stanfordLogo from "@/assets/stanford-eng-comp-forum.png";
import stanfordIcon from "@/assets/stanford-icon.jpg";
import uscLogo from "@/assets/usc-aiken-logo.jpg";
import uscIcon from "@/assets/usc-aiken-icon.png";
import purdueLogo from "@/assets/purdue-business-logo.png";
import purdueIcon from "@/assets/purdue-business-icon.webp";
import uvaDardenLogo from "@/assets/uva-darden-logo.svg";
import uvaDardenIcon from "@/assets/uva-darden-icon.jpg";
import sierraLogo from "@/assets/sierra-logo.png";
import sierraIcon from "@/assets/sierra-icon.png";
import jessupLogo from "@/assets/jessup-logo.png";
import jessupIcon from "@/assets/jessup-icon.png";
import pbaLogo from "@/assets/pba-logo.svg";
import pbaIcon from "@/assets/pba-icon.jpeg";
import { IPartner } from "@/types/ApiTypes";

export const DEMO_PARTNERS: (Partial<IPartner> & {
  allowInProduction?: boolean;
  styles?: any;
  slug: string;
  hideFromLogin?: boolean;
  showMBAToggle?: boolean;
})[] = [
  {
    slug: "university-of-arizona---eller-college-of-management",
    showMBAToggle: true,
    allowInProduction: true,
  },
  {
    slug: "usd-knauss-school-of-business",
    showMBAToggle: true,
    allowInProduction: true,
    styles: {
      loginHeaderImageProps: {
        fit: "contain",
      },
    },
  },
  {
    slug: "asu-w.p.-carey-school-of-business",
    showMBAToggle: true,
    allowInProduction: true,
  },
  {
    slug: "ucla-anderson",
    showMBAToggle: true,
    allowInProduction: true,
  },
  {
    id: "stanford-eng-comp-forum",
    allowInProduction: false,
    title: "Stanford Engineering Computer Forum",
    slug: "stanford-eng-comp-forum",
    partnerType: "University Partner",
    emailFormat: "@stanford.edu",
    logoURL: stanfordLogo,
    bannerImageURL: stanfordLogo,
    iconURL: stanfordIcon,
    styles: {
      loginHeaderImageProps: {
        w: "100%",
        maw: 400,
        ratio: 3 / 1,
      },
      signInButtonProps: {
        variant: "filled",
        color: "secondary.9",
      },
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: "usca",
    slug: "usca",
    allowInProduction: true,
    title: "University of South Carolina Aiken",
    partnerType: "University Partner",
    emailFormat: "@usca.edu",
    logoURL: uscLogo,
    bannerImageURL: uscLogo,
    iconURL: uscIcon,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  // {
  //   id: "university-of-arizona---eller-college-of-management",
  //   slug: "university-of-arizona---eller-college-of-management",
  //   allowInProduction: true,
  //   // title: "The University of Arizona Eller College of Management",
  //   // slug: "arizona-eller",
  //   // partnerType: "University Partner",
  //   // emailFormat: "@arizona.edu",
  //   // logoURL: arizonaEllerLogo,
  //   // bannerImageURL: arizonaEllerLogo,
  //   // iconURL: arizonaEllerIcon,
  //   // createdAt: new Date(),
  //   // updatedAt: new Date(),
  //   // styles: {
  //   //   loginHeaderImageProps: {
  //   //     w: "100%",
  //   //     maw: 400,
  //   //     fit: "contain",
  //   //   },
  //   //   signInButtonProps: {
  //   //     variant: "filled",
  //   //     color: "secondary.9",
  //   //   },
  //   // },
  // },
  {
    id: "purdue-university-mitch-daniels-school-of-business",
    slug: "purdue-university-mitch-daniels-school-of-business",
    title: "Purdue University Mitch Daniels School of Business",
    partnerType: "University Partner",
    emailFormat: "@purdue.edu",
    logoURL: purdueLogo,
    bannerImageURL: purdueLogo,
    iconURL: purdueIcon,
    createdAt: new Date(),
    updatedAt: new Date(),
    allowInProduction: false,
    styles: {
      loginHeaderImageProps: {
        w: "100%",
        maw: 350,
        fit: "contain",
      },
    },
  },
  {
    id: "uva-darden-school-of-business",
    slug: "uva-darden-school-of-business",
    title: "University of Virginia Darden School of Business",
    partnerType: "University Partner",
    emailFormat: "@virginia.edu",
    logoURL: uvaDardenLogo,
    bannerImageURL: uvaDardenLogo,
    iconURL: uvaDardenIcon,
    createdAt: new Date(),
    updatedAt: new Date(),
    allowInProduction: false,
    styles: {
      loginHeaderImageProps: {
        w: "100%",
        maw: 400,
        fit: "contain",
      },
    },
  },
  {
    id: "sierra-college",
    slug: "sierra-college",
    title: "Sierra College",
    partnerType: "University Partner",
    emailFormat: "@student.sierracollege.edu",
    allowInProduction: true,
    logoURL: sierraLogo,
    bannerImageURL: sierraLogo,
    iconURL: sierraIcon,
    createdAt: new Date(),
    updatedAt: new Date(),
    styles: {
      loginHeaderImageProps: {
        w: "100%",
        maw: 350,
        fit: "contain",
      },
    },
  },
  {
    id: "jessup-university",
    slug: "jessup-university",
    title: "Jessup University",
    partnerType: "University Partner",
    emailFormat: "@jessup.edu",
    allowInProduction: true,
    logoURL: jessupLogo,
    bannerImageURL: jessupLogo,
    iconURL: jessupIcon,
    createdAt: new Date(),
    updatedAt: new Date(),
    styles: {
      loginHeaderImageProps: {
        w: "100%",
        maw: 400,
        fit: "contain",
      },
      iconStyles: {
        padding: 2,
        objectFit: "contain",
        borderRadius: 6,
      },
    },
  },
  {
    slug: "compass-diversified",
    hideFromLogin: true,
    allowInProduction: true,
  },
  {
    id: "palm-beach-atlantic-university",
    slug: "palm-beach-atlantic-university",
    title: "Palm Beach Atlantic University",
    partnerType: "University Partner",
    emailFormat: "@pba.edu",
    logoURL: pbaLogo,
    bannerImageURL: pbaLogo,
    iconURL: pbaIcon,
    createdAt: new Date(),
    updatedAt: new Date(),
    allowInProduction: true,
    styles: {
      loginHeaderImageProps: {
        w: "100%",
        maw: 350,
        fit: "contain",
      },
    },
  },
];

// Column	Type	Comment
// id	text
// title	text
// wixId	text NULL
// slug	text
// about	text NULL
// partnerType	text
// website	text NULL
// emailFormat	text NULL
// logoURL	text NULL
// bannerImageURL	text NULL
// iconURL	text NULL
// createdAt	timestamp(3) [CURRENT_TIMESTAMP]
// updatedAt	timestamp(3) [CURRENT_TIMESTAMP]
