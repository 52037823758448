import { config } from "@/config";
import { useActiveUserPartner } from "../selectors";
import { useStore } from "@/hooks";

export const useDemoMode = () => {
  const demoMode = useStore((state) => state.demo.demoMode);
  const isDemoApp = useIsDemoApp();
  return isDemoApp ? demoMode || "students" : null;
};

export const useIsDemoApp = () => {
  return config.isDemoApp;
};

export const useIsAlumniApp = () => {
  return config.isAlumniApp;
};

export const useIsPartnerApp = () => {
  const demoMode = useDemoMode();
  return config.isPartnerApp || demoMode === "partner";
};

export const useIsStudentApp = () => {
  const demoMode = useDemoMode();
  return config.isStudentApp || demoMode === "students";
};

export const useShowMBA = () => {
  const userPartner = useActiveUserPartner();
  const isDemoApp = useIsDemoApp();
  return userPartner?.showMBAToggle || isDemoApp;
};

export const useShowPlaylists = () => {
  const userPartner = useActiveUserPartner();
  const isDemoApp = useIsDemoApp();
  return !!userPartner || isDemoApp;
};

export const useRequirePartnerEmailValidation = () => {
  const isAlumniApp = useIsAlumniApp();
  return !isAlumniApp;
};

export const useDidDismissDemoBar = () => {
  return useStore((state) => state.demo.didDismissDemoBar);
};
