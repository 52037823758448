import { ResourceConfigs } from "@/configs/resourceConfigs";
import { useStore } from "@/store";
import { Stack, Group, Space, Alert } from "@mantine/core";
import { Title } from "../ui/Title";
import {
  IconExternalLink,
  IconEye,
  IconLink,
  IconSend,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { ActionIcon } from "../ui/ActionIcon";
import { Button } from "../ui/Button";
import { TextInput } from "../ui/TextInput";
import { Text } from "../ui/Text";
import { Link } from "../ui/Link";
import {
  useActiveAssignment,
  useAssignmentPlaylists,
} from "@/store/selectors/partnerAppSelectors";
import { AssignmentService } from "@/services/Assignments";
import { Textarea } from "../ui/Textarea";
import { useMemo } from "react";

export function AssignmentManager() {
  const activeAssignment = useActiveAssignment();
  const assignmentPlaylists = useAssignmentPlaylists();
  const setActivePlaylistId = useStore(
    (state) => state.partnerApp.setActivePlaylistId,
  );
  const setActiveAssignmentId = useStore(
    (state) => state.partnerApp.setActiveAssignmentId,
  );

  const setIsAssignmentManagerOpen = useStore(
    (state) => state.partnerApp.setIsAssignmentManagerOpen,
  );

  const AssignedPlaylists = useMemo(() => {
    return (
      <Stack gap="xl">
        {assignmentPlaylists.map((playlist, index) => {
          return (
            <Stack key={playlist.id} gap="xs">
              <Text titleStyle="h5" lh={1.2}>
                {index + 1}. {playlist.title}
              </Text>
              <Group>
                <Button
                  variant="light"
                  size="xs"
                  radius="xl"
                  color="primary"
                  leftSection={<IconEye size={16} />}
                  onClick={() => setActivePlaylistId(playlist.id)}
                >
                  Preview
                </Button>
                <Button
                  variant="light"
                  size="xs"
                  radius="xl"
                  leftSection={<IconTrash size={16} />}
                  onClick={() => {
                    AssignmentService.removePlaylistFromAssignment({
                      assignmentId: activeAssignment?.id,
                      playlistId: playlist.id,
                    });
                  }}
                >
                  Remove
                </Button>
              </Group>
            </Stack>
          );
        })}
      </Stack>
    );
  }, [activeAssignment?.id, assignmentPlaylists, setActivePlaylistId]);

  return (
    <Stack px={0} py="md" pt={0} gap={0}>
      <Group justify="flex-end" w="100%" px="sm" py="sm">
        <ActionIcon
          ariaLabel="Close assignment manager"
          variant="light"
          size="md"
          radius="xl"
          color="text.6"
          style={{ zIndex: 100 }}
          onClick={() => {
            setIsAssignmentManagerOpen(false);
            setActiveAssignmentId(null);
          }}
        >
          <IconX size={20} />
        </ActionIcon>
      </Group>

      <Group justify="space-between" wrap="nowrap" px="md">
        <Group align="flex-start" gap="xs" wrap="nowrap">
          <ResourceConfigs.assignment.Icon size={26} />
          <Title order={1} size="h3" lh={1.2} lineClamp={1} pt={2}>
            {activeAssignment?.title || "Untitled Assignment"}
          </Title>
        </Group>
      </Group>

      <Space h="md" />

      <Group px="md">
        <Button
          variant="filled"
          color="primary"
          leftSection={<IconSend size={14} />}
          radius="xl"
          size="xs"
          fz={12}
        >
          Publish
        </Button>
        <Button
          variant="light"
          color="primary"
          leftSection={<IconLink size={14} />}
          radius="xl"
          size="xs"
          fz={12}
          disabled
        >
          Copy link
        </Button>

        <Link
          to={`/assignments/${activeAssignment?.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="light"
            color="primary"
            leftSection={<IconExternalLink size={14} />}
            radius="xl"
            size="xs"
            fz={12}
          >
            View as student
          </Button>
        </Link>
      </Group>

      <Space h="xl" />

      <Stack px="md" gap={0}>
        <TextInput
          id="assignment-title"
          value={activeAssignment?.title || ""}
          onDebouncedChange={(value) => {
            console.log("value", { value, activeAssignment });
            AssignmentService.updateAssignmentTitle({
              assignmentId: activeAssignment?.id,
              title: value,
            });
            // updateEditorState({ prop: "name", value });
          }}
          label="Assignment Name"
          placeholder={`e.g. "Explore careers for finance majors"`}
        />

        <Space h="xs" />

        <Textarea
          id="assignment-instructions"
          value={activeAssignment?.instructions || ""}
          rows={4}
          onDebouncedChange={(value) => {
            AssignmentService.updateAssignmentInstructions({
              assignmentId: activeAssignment?.id,
              instructions: value,
            });
          }}
          label="Assignment Instructions"
          placeholder="Enter assignment instructions here"
        />

        <Space h="xl" />

        <Group gap="xs" align="center">
          {/* <ResourceConfigs.playlist.Icon size={20} /> */}
          <Text titleStyle="h5" lh={1.2}>
            Assigned Playlists
          </Text>
        </Group>

        <Space h={4} />
        <Text size="xs" c="text.7">
          These are short collections of Advizer interview videos that provide
          key insights about specific topics.{" "}
        </Text>

        {assignmentPlaylists.length > 0 ? (
          <>
            <Space h="lg" />
            {AssignedPlaylists}
          </>
        ) : (
          <>
            <Space h="lg" />
            <Alert color="primary" title="How to assign playlists">
              Use your dashboard (to the left) to explore and preview playlists.
              When you find a playlist you like, click the "Assign" button to
              add it to this assignment.
            </Alert>
          </>
        )}
      </Stack>
    </Stack>
  );
}
