import { createBrowserRouter } from "react-router-dom";
import { PageLayout } from "@/pages/PageLayout";
import { AdvizerSearchPage } from "@/pages/AdvizerSearchPage";
import { Login } from "@/pages/Login";
import { AdvizerProfile } from "@/pages/AdvizerProfilePage";
import { LogicWrapper } from "@/components/logic/LogicWrapper";
import { AuthRedirect } from "@/pages/AuthRedirect";
import { RedirectPage } from "@/components/templates/RedirectPage";
import { Page404 } from "./pages/404";
import { PlaylistPage } from "./pages/PlaylistPage";
import { HomePage } from "./pages/HomePage";
import { PlaylistLibrary } from "./pages/PlaylistLibrary";
import { SupportPage } from "./pages/SupportPage";
import { UserProfile } from "./pages/UserProfile";
import { AssignmentPage } from "./pages/AssignmentPage";
import { DemoAnalyticsPage } from "./pages/DemoAnalyticsPage";
import { config } from "./config";

const ROUTES = [
  {
    path: "/",
    element: (
      <PageLayout isPublic={!config.isPartnerApp}>
        <HomePage />
      </PageLayout>
    ),
  },
  {
    path: "/profile",
    element: (
      <PageLayout>
        <UserProfile />
      </PageLayout>
    ),
  },
  {
    path: "/start",
    element: (
      <PageLayout>
        <HomePage />
      </PageLayout>
    ),
  },
  {
    path: "/explore",
    element: (
      <PageLayout isPublic>
        <AdvizerSearchPage />
      </PageLayout>
    ),
  },
  {
    path: "/support",
    element: (
      <PageLayout isPublic>
        <SupportPage />
      </PageLayout>
    ),
  },
  {
    path: "/demo/analytics",
    element: (
      <PageLayout isPublic>
        <DemoAnalyticsPage />
      </PageLayout>
    ),
  },
  {
    path: "/auth/redirect",
    element: (
      <PageLayout>
        <AuthRedirect />
      </PageLayout>
    ),
  },
  {
    path: "/playlists",
    // requiresPartner: true,
    element: (
      <PageLayout requiresPartner>
        <PlaylistLibrary />
      </PageLayout>
    ),
  },
  {
    path: "/playlists/:playlistSlug",
    // requiresPartner: true,
    element: (
      <PageLayout requiresPartner>
        <PlaylistPage />
      </PageLayout>
    ),
  },
  {
    path: "/p/:partnerSlug",
    // requiresPartner: true,
    element: (
      <PageLayout>
        <AdvizerSearchPage />
      </PageLayout>
    ),
  },
  {
    path: "/assignments/:assignmentId",
    // requiresPartner: true,
    element: (
      <PageLayout requiresPartner>
        <AssignmentPage />
      </PageLayout>
    ),
  },
  {
    path: "/login",
    element: (
      <PageLayout isPublic>
        <Login />
      </PageLayout>
    ),
  },
  {
    path: "/login/general",
    element: (
      <PageLayout isPublic>
        <Login isGeneralLogin />
      </PageLayout>
    ),
  },
  {
    path: "/login/partners",
    element: (
      <PageLayout isPublic>
        <Login isPartnerSelection />
      </PageLayout>
    ),
  },
  {
    path: "/login/:partnerSlug",
    element: (
      <PageLayout isPublic>
        <Login />
      </PageLayout>
    ),
  },
  {
    path: "/advizers/:advizerId",
    element: (
      <PageLayout isPublic>
        <AdvizerProfile />
      </PageLayout>
    ),
  },
  {
    path: "/advizers/:advizerId/:tab",
    element: (
      <PageLayout isPublic>
        <AdvizerProfile />
      </PageLayout>
    ),
  },
  {
    path: "/join",
    element: <RedirectPage />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export const router = createBrowserRouter(
  ROUTES.map((route) => ({
    ...route,
    element: <LogicWrapper>{route.element}</LogicWrapper>,
  })),
);
