import { useMemo, useState } from "react";
import { Button } from "../ui/Button";
import { IconCheck, IconLink } from "@tabler/icons-react";
import { Space } from "@mantine/core";
import { useActiveUserPartner } from "@/store/selectors";

export function CopyPlaylistLink({
  playlistId,
  size = "xs",
}: {
  playlistId: string;
  size?: "xs" | "sm";
  iconSize?: number;
}) {
  const [copied, setCopied] = useState(false);
  const userPartner = useActiveUserPartner();
  const partnerSlug = userPartner?.slug;

  const copyToClipboard = () => {
    let link = `${window.location.origin}/playlists/${playlistId}`;
    if (link.includes("localhost:5183")) {
      link = link.replace("localhost:5183", "localhost:5173");
    }

    if (link.includes("partner.advizehub.com")) {
      link = link.replace("partner.advizehub.com", "students.advizehub.com");
    }
    if (partnerSlug) {
      link = `${link}?partner=${partnerSlug}`;
    }
    navigator.clipboard.writeText(link);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const iconSize = useMemo(() => {
    if (size === "xs") return 12;
    if (size === "sm") return 16;
    return 12;
  }, [size]);

  const buttonHeight = useMemo(() => {
    if (size === "xs") return 24;
    if (size === "sm") return 32;
    return 24;
  }, [size]);

  const fontSize = useMemo(() => {
    if (size === "xs") return 10;
    if (size === "sm") return 12;
    return 10;
  }, [size]);

  return (
    <Button
      variant="light"
      radius="xl"
      size={size}
      h={buttonHeight}
      fz={fontSize}
      py={0}
      px={size}
      onClick={copyToClipboard}
    >
      {copied ? <IconCheck size={iconSize} /> : <IconLink size={iconSize} />}
      <Space w={4} />
      {copied ? "Copied!" : "Copy link"}
    </Button>
  );
}
