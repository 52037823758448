const envVars = import.meta.env;

const localConfig = {
  apiUrl: "http://localhost:5000",
};

const stagingConfig = {
  apiUrl: "https://advize-staging-api-645456828348.us-central1.run.app",
};

const prodConfig = {
  apiUrl: "https://advize-production-api-980910018979.us-central1.run.app",
};

let configToUse = localConfig;

if (envVars.VITE_ADVIZE_ENV === "prod") {
  configToUse = prodConfig;
} else if (envVars.VITE_ADVIZE_ENV === "staging") {
  configToUse = stagingConfig;
}

const hostname = window.location.hostname;
let clientEnv = "local";

if (hostname.includes("staging") || hostname.includes("netlify")) {
  clientEnv = "staging";
} else if (hostname.includes("advizehub.com")) {
  clientEnv = "prod";
}

const isDemoApp =
  localStorage.getItem("appType") === "demo" ||
  window.location.hostname.includes("demo") ||
  envVars.VITE_APP_TYPE === "demo";

export const config = {
  ...configToUse,
  env: import.meta.env,
  cacheInvalidator: 22, // increase this to force user client to refetch data from server
  clientEnv,
  isEmulate: envVars.VITE_ADVIZE_ENV === "local",
  isLocal: envVars.VITE_ADVIZE_ENV === "local",
  isLocalClient: clientEnv === "local",
  isStaging: envVars.VITE_ADVIZE_ENV === "staging" || isDemoApp,
  isStagingClient: clientEnv === "staging",
  isProduction:
    envVars.VITE_ADVIZE_ENV === "prod" ||
    envVars.VITE_ADVIZE_ENV === "production",
  isProductionClient: clientEnv === "prod",
  isPartnerApp:
    localStorage.getItem("appType") === "partner" ||
    window.location.hostname.includes("partner") ||
    window.location.hostname.includes("deploy-preview-27") ||
    window.location.hostname.includes("deploy-preview-29") ||
    envVars.VITE_APP_TYPE === "partner",
  isAlumniApp:
    localStorage.getItem("appType") === "alumni" ||
    window.location.hostname.includes("alumni") ||
    envVars.VITE_APP_TYPE === "alumni",
  isStudentApp:
    localStorage.getItem("appType") === "students" ||
    window.location.hostname.includes("students") ||
    envVars.VITE_APP_TYPE === "students",
  isDemoApp,
  siteUrl: window.location.origin,
  supportEmail: "dev@advizehub.com",
  supportEmailLink: `/support`,
  supportMailtoLink: `mailto:dev@advizehub.com?subject=Support%20Request%20-%20Advize`,
  termsUrl: "https://www.advizehub.com/terms-conditions",
  privacyUrl: "https://www.advizehub.com/privacy-policy",
  slackInviteLink:
    "https://join.slack.com/t/advize-talk/shared_invite/zt-2y9sajo9f-hUkhWhpYKT6hzKmYu_6RmA",
  authRedirectUrl: `${window.location.origin}/auth/redirect`,
  socialLinks: {
    linkedin: "https://www.linkedin.com/company/advizehub/",
    tiktok: "https://www.tiktok.com/@advizehub.com",
    instagram: "https://www.instagram.com/advizehub",
  },
  dbTotals: {
    videos: 9000,
    advizers: 730,
  },
};

console.log({ config });
