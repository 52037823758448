import { IconX } from "@tabler/icons-react";
import { ResourceConfigs } from "@/configs/resourceConfigs";
import {
  useAllQuestions,
  useIsFilterOptionSelected,
  useToggleAdvizerSearchFilterOption,
  useUIFlags,
} from "@/store/selectors";
import { Badge, BadgeProps } from "@/components/ui/Badge";
import { Analytics } from "@/services/Analytics";
import { useCallback } from "react";
import { useStore } from "@/store";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";
import { IQuestion } from "@/types/ApiTypes";

export type IAdvizerTagTypes = keyof typeof ResourceConfigs;

export interface IAdvizerTag {
  id: string;
  title: string;
  type: IAdvizerTagTypes;
}

export interface AdvizerTagProps extends Omit<BadgeProps, "ariaLabel"> {
  tag: IAdvizerTag;
  index?: number;
  disableClick?: boolean;
}

export const AdvizerTag = (props: AdvizerTagProps) => {
  const { tag, index, disableClick, ...rest } = props;
  const resourceConfig = ResourceConfigs[tag.type];
  const isSelectedFilterOption = useIsFilterOptionSelected({
    filterKey: resourceConfig.filtersKey,
    resourceId: tag.id,
  });
  const activeQuestionNumberRaw = useStore(
    (state) => state.advizerSearch.activeQuestionNumber,
  );
  const setActiveQuestionNumber = useStore(
    (state) => state.advizerSearch.setActiveQuestionNumber,
  );
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const allQuestions = useAllQuestions();
  const activeQuestionNumber =
    activeQuestionId && allQuestions?.length
      ? allQuestions?.find((q: IQuestion) => q.id === activeQuestionId)
          ?.number || activeQuestionNumberRaw
      : activeQuestionNumberRaw;
  const activeUser = useStore((state) => state.user.activeUser);
  const { highlightAdvizerTags } = useUIFlags();
  const isQuestion = tag.type === "question";
  const isSelectedQuestion =
    isQuestion && activeQuestionNumber === Number(tag.id);
  const isSelected = isSelectedFilterOption || isSelectedQuestion;

  const toggleFilterOption = useToggleAdvizerSearchFilterOption();

  const handleToggleFilterOption = useCallback(() => {
    if (isQuestion) {
      console.log({
        activeQuestionNumber,
        tagId: tag.id,
        tag,
      });
      if (activeQuestionNumber === Number(tag.id)) {
        setActiveQuestionNumber(null);
      } else {
        setActiveQuestionNumber(Number(tag.id));
      }
    } else {
      toggleFilterOption(resourceConfig.filtersKey, tag.id);
    }

    if (!isSelected) {
      Analytics.filterAdvizers({
        filterType: resourceConfig.filtersKey,
        filterValue: tag.title,
        resourceId: tag.id,
        context: "Advizer Tag",
      });
    }

    if (activeUser && !activeUser.behavior?.didClickAdvizerTags) {
      RealtimeDatabase.updateCurrentUserBehaviorData({
        didClickAdvizerTags: true,
      });
    }
  }, [
    activeQuestionNumber,
    activeUser,
    isQuestion,
    isSelected,
    resourceConfig.filtersKey,
    setActiveQuestionNumber,
    tag,
    toggleFilterOption,
  ]);

  return (
    <Badge
      ariaLabel={tag.title}
      className={index === 0 && highlightAdvizerTags ? "pulseBorderLight" : ""}
      variant={isSelected ? "filled" : "light"}
      color={`${resourceConfig.color || "primary"}.7`}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: disableClick ? "default" : "pointer",
      }}
      leftSection={
        isSelected ? <IconX size={16} /> : <resourceConfig.Icon size={16} />
      }
      onClick={!disableClick ? handleToggleFilterOption : undefined}
      {...rest}
    >
      <span title={tag.title}>{tag.title}</span>
    </Badge>
  );
};
