import { Select } from "@/components/ui/Select";
import { Analytics } from "@/services/Analytics";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";
import { useStore } from "@/store";
import {
  useActiveUser,
  useCurrentPartnerHasSpecialQuestions,
  useQuestions,
  useUIFlags,
} from "@/store/selectors";
import { useIsLaptopOrBigger } from "@/styles/useStyles";

export const QuestionSelector = ({ size = "md" }: { size?: "md" | "sm" }) => {
  const currentPartnerHasSpecialQuestions =
    useCurrentPartnerHasSpecialQuestions();
  const questions = useQuestions();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const activeQuestionIdRaw = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const activeUser = useActiveUser();
  const { highlightQuestionSelector } = useUIFlags();
  const setActiveQuestionId = useStore(
    (state) => state.advizerSearch.setActiveQuestionId,
  );
  const activeQuestionNumber = useStore(
    (state) => state.advizerSearch.activeQuestionNumber,
  );
  const setActiveQuestionNumber = useStore(
    (state) => state.advizerSearch.setActiveQuestionNumber,
  );

  const activeQuestionId =
    activeQuestionIdRaw ||
    questions.find((q) => q.number === activeQuestionNumber)?.id;

  return (
    <Select
      flex={1}
      maw={600}
      bold={currentPartnerHasSpecialQuestions}
      highlight={!activeQuestionId && highlightQuestionSelector ? true : false}
      // classNames={{
      //   input: "shimmer-border",
      // }}
      clearable
      placeholder={
        currentPartnerHasSpecialQuestions
          ? "⭐ Click for special questions!"
          : "Click to focus on a question"
      }
      variant={isLaptopOrBigger ? "default" : "default"}
      size={size ? size : isLaptopOrBigger ? "md" : "sm"}
      radius="xl"
      value={activeQuestionId || null}
      onChange={(value) => {
        if (value) {
          const question = questions.find((q) => q.id === value);
          setActiveQuestionNumber(question?.number || null);

          Analytics.filterAdvizers({
            filterType: "question",
            filterValue: question
              ? `Q${question?.number}: ${question?.title}`
              : value,
            resourceId: value,
          });
        } else {
          setActiveQuestionNumber(null);
        }

        if (activeUser && !activeUser.behavior?.didSelectQuestion) {
          RealtimeDatabase.updateCurrentUserBehaviorData({
            didSelectQuestion: true,
          });
        }

        setActiveQuestionId(value || null);
      }}
      data={[
        {
          value: "",
          label: "All Questions",
        },
        ...questions.map((question: any) => ({
          value: question.id,
          label: `${!question.isStandard ? "⭐" : ""} Q${question.number}: ${
            question.title
          }`,
        })),
      ]}
    />
  );
};
