import { NAV_ITEMS } from "@/configs/navItems";
import {
  useActiveUser,
  useActiveUserPartner,
  useDoesUserHaveAnyPartners,
  // useDoesUserHaveAnyPartners,
} from "../selectors";
import { useIsDemoApp } from "./featureSelectors";

export const useShowPlaylists = () => {
  const activeUser = useActiveUser();
  const activeUserPartner = useActiveUserPartner();

  if (!activeUser || !activeUserPartner) return false;

  const hasNumberInEmail = /\d/.test(activeUser.email || "");

  return activeUser.email.includes("asu.edu") && !hasNumberInEmail;
};

export const useNavItems = () => {
  const doesUserHaveAnyPartners = useDoesUserHaveAnyPartners();
  const isDemoApp = useIsDemoApp();
  return NAV_ITEMS.filter(
    (item) => !item.requiresPartner || doesUserHaveAnyPartners || isDemoApp,
  );
};
