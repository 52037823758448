import { Group, Stack } from "@mantine/core";
import {
  IconArrowRight,
  IconNumber1Small,
  IconNumber2Small,
  IconNumber3Small,
  IconNumber4Small,
  IconNumber5Small,
  IconNumber6Small,
  IconNumber7Small,
  IconMoodCry,
  IconMoodSad,
  IconMoodSmile,
  IconMoodHappy,
  IconMoodCrazyHappy,
} from "@tabler/icons-react";
import { ActionIcon } from "../ui/ActionIcon";
import { useMemo } from "react";
import { Text } from "../ui/Text";
import { useStyles } from "@/styles/useStyles";

import {} from "@tabler/icons-react";
import {
  useActivePlaylistId,
  useUserPlaylistStepData,
  useActivePlaylistStepKey,
  useActivePlaylist,
  useGoToNextStepInPlaylist,
  useIsOnboardingPlaylist,
  useIsSecondToLastStepInActivePlaylist,
} from "@/store/selectors/playlistSelectors";
import { PlaylistService } from "@/services/PlaylistService";
import { Analytics } from "@/services/Analytics";

const numberIcons = [
  IconNumber1Small,
  IconNumber2Small,
  IconNumber3Small,
  IconNumber4Small,
  IconNumber5Small,
  IconNumber6Small,
  IconNumber7Small,
];

const faceIcons = [
  IconMoodCry,
  IconMoodSad,
  IconMoodSmile,
  IconMoodHappy,
  IconMoodCrazyHappy,
];

const faceIconColors = ["red", "orange", "yellow", "lime", "green"];

export const PlaylistScale = (props: {
  id: string;
  playlistId: string;
  lowerText: string;
  upperText: string;
  shouldShowFaceIcons?: boolean;
  onSelect?: (value: number) => void;
  readOnly?: boolean;
}) => {
  const { lowerText, upperText, shouldShowFaceIcons, readOnly } = props;
  const { theme } = useStyles();
  const icons = shouldShowFaceIcons ? faceIcons : numberIcons;
  const activePlaylistId = useActivePlaylistId();
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylistStepData = useUserPlaylistStepData();
  const activePlaylist = useActivePlaylist();
  const selectedValue = useMemo(() => {
    return activePlaylistStepData?.value;
  }, [activePlaylistStepData]);
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();
  const isSecondToLastStepInActivePlaylist =
    useIsSecondToLastStepInActivePlaylist();
  const isOnboardingPlaylist = useIsOnboardingPlaylist();

  return (
    <Stack gap="xs">
      <Group
        justify={readOnly ? "flex-start" : "center"}
        gap={shouldShowFaceIcons ? "xs" : "lg"}
      >
        {Array.from({ length: 5 }).map((_, index) => {
          const Icon = icons[index];
          const isSelected = !readOnly && selectedValue === index + 1;
          const color = shouldShowFaceIcons ? faceIconColors[index] : "primary";
          return (
            <ActionIcon
              key={index}
              variant={
                shouldShowFaceIcons
                  ? "transparent"
                  : isSelected
                    ? "filled"
                    : "outline"
              }
              radius={shouldShowFaceIcons ? "xl" : "sm"}
              className={
                selectedValue || shouldShowFaceIcons || readOnly
                  ? undefined
                  : "pulseBorder"
              }
              size={shouldShowFaceIcons ? 60 : readOnly ? "md" : "xl"}
              bg={isSelected ? `${color}.9` : "background.0"}
              color={
                isSelected
                  ? `${color}.1`
                  : shouldShowFaceIcons
                    ? `${color}.3`
                    : `${color}.7`
              }
              ariaLabel={`Select ${index + 1} on 1-5 scale`}
              onClick={() => {
                if (!activePlaylistId || !activePlaylistStepKey || readOnly)
                  return;
                const value = index + 1;
                PlaylistService.updateUserPlaylistStepData({
                  playlistId: activePlaylistId,
                  stepKey: activePlaylistStepKey,
                  data: {
                    value: index + 1,
                  },
                });

                Analytics.playlistScaleSelect({
                  value,
                  playlist: activePlaylist,
                });

                console.log({
                  isSecondToLastStepInActivePlaylist,
                  isOnboardingPlaylist,
                });

                if (
                  isSecondToLastStepInActivePlaylist &&
                  !isOnboardingPlaylist
                ) {
                  PlaylistService.markPlaylistAsCompleted({
                    playlistId: activePlaylistId,
                    playlist: activePlaylist,
                  });
                }

                goToNextStepInPlaylist();

                // setSelectedValue(index + 1);
                // onSelect?.(index + 1);
              }}
              style={{
                border: shouldShowFaceIcons ? "none" : undefined,
              }}
            >
              <Icon size={shouldShowFaceIcons ? 60 : 36} />
            </ActionIcon>
          );
        })}
      </Group>
      <Group justify={readOnly ? "flex-start" : "center"}>
        <Text size="xs" c="slate.7">
          {lowerText}
        </Text>
        <IconArrowRight size={16} color={theme.colors.text[5]} />
        <Text size="xs" c="slate.7">
          {upperText}
        </Text>
      </Group>
    </Stack>
  );
};
