import { RealtimeDatabase } from "./RealtimeDatabase";

class AssignmentsService {
  updateAssignmentTitle({
    assignmentId,
    title,
  }: {
    assignmentId: string;
    title: string;
  }) {
    RealtimeDatabase.updateAssignment({
      assignmentId,
      data: { title },
      shouldUpdateUserAssignment: true,
    });
  }

  updateAssignmentInstructions({
    assignmentId,
    instructions,
  }: {
    assignmentId: string;
    instructions: string;
  }) {
    RealtimeDatabase.updateAssignment({
      assignmentId,
      data: { instructions },
    });
  }

  addPlaylistToAssignment({
    assignmentId,
    playlistId,
  }: {
    assignmentId: string;
    playlistId: string;
  }) {
    RealtimeDatabase.addPlaylistToAssignment({
      assignmentId,
      playlistId,
    });
  }

  removePlaylistFromAssignment({
    assignmentId,
    playlistId,
  }: {
    assignmentId: string;
    playlistId: string;
  }) {
    RealtimeDatabase.removePlaylistFromAssignment({
      assignmentId,
      playlistId,
    });
  }
}

export const AssignmentService = new AssignmentsService();
