import { useStore } from "@/store";
import { useUserPlaylistData } from "./playlistSelectors";
import { useAdvizerMap, useVimeoIdVideoMap } from "../selectors";
import { IAdvizerVideo } from "@/models/AdvizerVideo";

export const useUserProfileData = () => {
  return useStore((state) => state.user?.profileData);
};

export const useUserRecommendedPlaylists = () => {
  return (
    useStore((state) => state.user?.playlistData?.recommendedPlaylists) || {}
  );
};

export const useUserCompletedPlaylistKeys = () => {
  const playlistData = useUserPlaylistData();
  const completedPlaylistKeys = Object.keys(
    playlistData?.playlists || {},
  ).filter((playlistId) => {
    const playlist = playlistData?.playlists[playlistId];
    const steps = playlist?.steps;
    return (
      // steps?.["interest-response-after"] &&
      steps?.["understanding-response-after"]
    );
  });
  return completedPlaylistKeys;
};

export const useUserInProgressPlaylistKeys = () => {
  const playlistData = useUserPlaylistData();
  const inProgressPlaylistKeys = Object.keys(
    playlistData?.playlists || {},
  ).filter((playlistId) => {
    const playlist = playlistData?.playlists[playlistId];
    const steps = playlist?.steps;
    return (
      // !steps?.["interest-response-after"] ||
      !steps?.["understanding-response-after"]
    );
  });
  return inProgressPlaylistKeys;
};

export const useUserVideoData = () => {
  return useStore((state) => state.user?.videoData?.videos) || {};
};

export const useUserSubjects = () => {
  const profileData = useUserProfileData();
  return profileData?.subjects || {};
};

export const useUserHasSelectedSubjects = () => {
  const subjects = useUserSubjects();
  return Object.keys(subjects).length > 0;
};

export const useUserIndustries = () => {
  const profileData = useUserProfileData();
  return profileData?.industries || {};
};

export const useUserHasSelectedIndustries = () => {
  const industries = useUserIndustries();
  return Object.keys(industries).length > 0;
};

export const useUserPlaylistKeys = () => {
  const playlistData = useUserPlaylistData();
  return Object.keys(playlistData?.playlists || {});
};

export const useUserVideosWatched = (): IAdvizerVideo[] => {
  const videoMap = useVimeoIdVideoMap();
  const userVideoData = useUserVideoData();
  const videosWatched = Object.keys(userVideoData)
    .filter((vimeoId) => {
      return videoMap[vimeoId];
    })
    .map((vimeoId) => {
      return videoMap[vimeoId];
    });

  return videosWatched;
};

export const useUserAdvizersWatched = () => {
  const advizerMap = useAdvizerMap();
  const videosWatched = useUserVideosWatched();
  const advizerIdsWatched = videosWatched.map((video) => video.advizerId);
  // return unique advizers
  return Array.from(
    new Set([...advizerIdsWatched].map((advizerId) => advizerMap[advizerId])),
  );
};
