import {
  Text as MantineText,
  TextProps as MantineTextProps,
} from "@mantine/core";
import { forwardRef, ReactNode, useMemo } from "react";
import { IHeaderSize, headerSizeToFontSize } from "@/components/ui/Title";

export interface TextProps extends MantineTextProps {
  titleStyle?: IHeaderSize | boolean;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (props, ref) => {
    const { titleStyle, style, children, ...rest } = props;

    const childrenAsString = useMemo(() => {
      return (children?.toString() || "")
        .replace(/<br\s*\/?>/g, "\n")
        .replace(/\[object Object\]/g, "");
    }, [children]);
    return (
      <MantineText
        title={childrenAsString}
        ref={ref}
        fw={titleStyle ? 700 : undefined}
        fz={
          titleStyle
            ? typeof titleStyle === "string"
              ? headerSizeToFontSize[titleStyle]
              : undefined
            : undefined
        }
        style={{
          ...style,
          fontFamily: titleStyle ? "League Spartan, sans-serif" : undefined,
          whiteSpace: "pre-wrap",
        }}
        {...rest}
      >
        {children}
      </MantineText>
    );
  },
);

Text.displayName = "Text";
