import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/carousel/styles.css";
import "react-virtualized/styles.css";

import "@/styles/global.css";
import "@/styles/uiComponents.css";

import { MantineProvider } from "@mantine/core";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { useTheme } from "@/styles/theme";
import { router } from "@/routing";
import "@/firebase/firebase";
import { useEffect } from "react";
import { useActiveUser } from "@/store/selectors";
import { useStore } from "./store";

function App() {
  const theme = useTheme();
  const activeUser = useActiveUser();
  const setShowUserInterviewForm = useStore(
    (state) => state.user.setShowUserInterviewForm,
  );

  useEffect(() => {
    if (activeUser?.behavior?.didClickUserInterviewSurvey) {
      return;
    }

    const appLoadedCount = localStorage.getItem("advize_appLoadedCount");
    const didClickUserInterviewSurvey = localStorage.getItem(
      "advize_didClickUserInterviewSurvey",
    );

    localStorage.setItem(
      "advize_appLoadedCount",
      (Number(appLoadedCount) + 1).toString(),
    );

    if (didClickUserInterviewSurvey) {
      return;
    }

    // if (!didClickUserInterviewSurvey && Number(appLoadedCount) > 1) {
    //   setShowUserInterviewForm(true);
    // }
  }, [activeUser, setShowUserInterviewForm]);

  return (
    <HelmetProvider>
      <MantineProvider theme={theme}>
        <RouterProvider router={router} />
      </MantineProvider>
    </HelmetProvider>
  );
}

export default App;
