import { onValue, ref, update, off, remove, set, get } from "firebase/database";
import { db } from "@/firebase/firebase";
import { getPartners } from "@/store/externalSelectors";
import { StoreService } from "./StoreService";
import { v4 as generateUuid } from "uuid";

class RealtimeDatabaseService {
  updateUser(userId: string, data: any) {
    const userRef = ref(db, `users/${userId}`);
    update(userRef, data);
  }

  updateCurrentUser(data: any) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    this.updateUser(activeUser.id, data);
  }

  updateCurrentUserBehaviorData(data: any) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const behaviorRef = ref(db, `users/${activeUser.id}/behavior`);
    update(behaviorRef, data);
  }

  updateCurrentUserUiStateData(data: any) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const uiStateRef = ref(db, `users/${activeUser.id}/uiState`);
    update(uiStateRef, data);
  }

  async subscribeToUser(
    userId: string,
    callback: (userData: any) => void,
  ): Promise<any> {
    this.unsubscribeFromUser(userId);
    const userRef = ref(db, `users/${userId}`);
    off(userRef, "value");

    onValue(
      userRef,
      (snapshot) => {
        const userData = snapshot.val();
        callback(userData);
      },
      (error) => {
        console.error("Error subscribing to user", error);
      },
    );
  }

  async subscribeToUserVideoData(
    userId: string,
    callback: (videoData: any) => void,
  ) {
    const videoDataRef = ref(db, `videoDataByUser/${userId}`);
    off(videoDataRef, "value");

    onValue(
      videoDataRef,
      (snapshot) => {
        const videoData = snapshot.val();
        callback(videoData);
      },
      (error) => {
        console.error("Error subscribing to user video data", error);
      },
    );
  }

  unsubscribeFromUser(userId: string) {
    const userRef = ref(db, `users/${userId}`);
    off(userRef, "value");
  }

  updateCurrentUserPartner(userId: string, partnerId: string) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const partner = getPartners()?.find((partner) => partner.id === partnerId);

    if (partner) {
      this.updateUser(userId, {
        partnerId,
        partnerSlug: partner?.slug || partner?.title,
      });
    }
  }

  updateUserPlaylistTimestamps(params: { playlistId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const playlistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/playlists/${params.playlistId}`,
    );
    update(playlistsRef, {
      lastUpdatedAt: Date.now(),
    });
  }

  updateCurrentUserPlaylistStepData(params: {
    playlistId: string;
    stepKey: string;
    data: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    console.log("Updating current user playlist step data", {
      activeUser,
      params,
    });

    const playlistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/playlists/${params.playlistId}/steps/${params.stepKey}`,
    );
    update(playlistsRef, params.data);

    this.updateUserPlaylistTimestamps({ playlistId: params.playlistId });
  }

  addCurrentUserSubject({
    subjectId,
    subjectData,
  }: {
    subjectId: string;
    subjectData: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const subjectsRef = ref(
      db,
      `users/${activeUser.id}/profileData/subjects/${subjectId}`,
    );
    update(subjectsRef, subjectData);
  }

  removeCurrentUserSubject({ subjectId }: { subjectId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const subjectsRef = ref(
      db,
      `users/${activeUser.id}/profileData/subjects/${subjectId}`,
    );
    remove(subjectsRef);
  }

  addCurrentUserIndustry({
    industryId,
    industryData,
  }: {
    industryId: string;
    industryData: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const industriesRef = ref(
      db,
      `users/${activeUser.id}/profileData/industries/${industryId}`,
    );
    update(industriesRef, industryData);
  }

  removeCurrentUserIndustry({ industryId }: { industryId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const industriesRef = ref(
      db,
      `users/${activeUser.id}/profileData/industries/${industryId}`,
    );
    remove(industriesRef);
  }

  updateCurrentUserRecommendedPlaylists({
    playlistIds,
  }: {
    playlistIds: string[];
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const recommendedPlaylistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/recommendedPlaylists`,
    );
    update(
      recommendedPlaylistsRef,
      playlistIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as any),
    );
  }

  clearUserRecommendedPlaylists() {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const recommendedPlaylistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/recommendedPlaylists`,
    );
    remove(recommendedPlaylistsRef);
  }

  updateUserVideoData(params: { vimeoId: string; data: any }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const videoWatchingDataRef = ref(
      db,
      `videoDataByUser/${activeUser.id}/videos/${params.vimeoId}`,
    );

    update(videoWatchingDataRef, params.data);
  }

  createNewBlankAssignment() {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const uuid = generateUuid();
    const assignmentRef = ref(db, `assignments/${uuid}`);
    const userAssignmentsRef = ref(
      db,
      `userAssignments/${activeUser.id}/${uuid}`,
    );

    const baseAssignmentData = {
      id: uuid,
      title: "",
      createdAt: Date.now(),
      updatedAt: Date.now(),
      status: "public",
    };

    set(userAssignmentsRef, baseAssignmentData);
    set(assignmentRef, {
      ...baseAssignmentData,
      instructions: "",
      playlists: {},
      advizers: {},
      videos: {},
      users: {
        [activeUser.id]: "owner",
      },
    });

    return uuid;
  }

  updateAssignment({
    assignmentId,
    data,
    shouldUpdateUserAssignment,
  }: {
    assignmentId: string;
    data: any;
    shouldUpdateUserAssignment?: boolean;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const assignmentRef = ref(db, `assignments/${assignmentId}`);
    update(assignmentRef, data);

    if (shouldUpdateUserAssignment) {
      const userAssignmentsRef = ref(
        db,
        `userAssignments/${activeUser.id}/${assignmentId}`,
      );
      update(userAssignmentsRef, data);
    }
  }

  addPlaylistToAssignment({
    assignmentId,
    playlistId,
  }: {
    assignmentId: string;
    playlistId: string;
  }) {
    const assignmentRef = ref(
      db,
      `assignments/${assignmentId}/playlists/${playlistId}`,
    );

    update(assignmentRef, {
      id: playlistId,
      updatedAt: Date.now(),
    });
  }

  removePlaylistFromAssignment({
    assignmentId,
    playlistId,
  }: {
    assignmentId: string;
    playlistId: string;
  }) {
    const assignmentRef = ref(
      db,
      `assignments/${assignmentId}/playlists/${playlistId}`,
    );
    remove(assignmentRef);
  }

  subscribeToUserAssignments(callback: (assignments: any) => void) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const assignmentsRef = ref(db, `userAssignments/${activeUser.id}`);
    onValue(assignmentsRef, (snapshot) => {
      const assignments = snapshot.val();
      callback(assignments || []);
    });
  }

  subscribeToFullAssignment(
    assignmentId: string,
    callback: (assignment: any) => void,
  ) {
    const assignmentRef = ref(db, `assignments/${assignmentId}`);
    onValue(assignmentRef, (snapshot) => {
      const assignment = snapshot.val();
      callback(assignment);
    });
  }

  markPlaylistAsCompleted(params: { playlistId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const playlistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/playlists/${params.playlistId}`,
    );
    update(playlistsRef, { completed: Date.now() });
  }

  async fetchPlaylistCompletionStatus(params: { playlistId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const playlistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/playlists/${params.playlistId}/completed`,
    );
    const snapshot = await get(playlistsRef);
    return snapshot.val() || null;
  }
}

export const RealtimeDatabase = new RealtimeDatabaseService();
