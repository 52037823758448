import { Space, Stack } from "@mantine/core";
import { Image } from "@/components/ui/Image";
import welcomeMeme from "@/assets/memes/welcome-meme.jpg";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";

export function WelcomeDialog() {
  // const { closeActiveDialog } = useDialog();
  // const { openDialog: openCookieDialog } = useDialog("cookieConsent");

  return (
    <Stack p={0}>
      <Image
        src={welcomeMeme}
        alt="Drake good bad option meme, Advize is the good option lol"
      />
      <Stack py="sm" gap={0}>
        <Text titleStyle="h4" c="text.7">
          Welcome to Advize!
        </Text>
        <Text>
          Explore careers by searching through our library of 650+ Advizers and
          their 8,000+ short interview videos.
        </Text>
      </Stack>
      <Space h="xs" />
      <Button
        fullWidth
        // onClick={() => {
        //   closeActiveDialog();
        //   setTimeout(() => {
        //     openCookieDialog();
        //   }, 3000);
        // }}
        size="md"
        variant="filled"
      >
        Close
      </Button>
    </Stack>
  );
}
