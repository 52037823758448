import { AdvizeApi } from "@/api/api";
import { Navigation } from "@/services/Navigation";
import { PlaylistService } from "@/services/PlaylistService";
import { useStore } from "@/store";
import { useActiveUser } from "@/store/selectors";
import {
  useActivePlaylist,
  useHasRecommendedPlaylists,
  useRecommendedPlaylists,
} from "@/store/selectors/playlistSelectors";
import {
  useUserHasSelectedSubjects,
  useUserProfileData,
} from "@/store/selectors/userProfileSelectors";
import { Space, Stack } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useEffect } from "react";
import { Button } from "../ui/Button";
import { PlaylistCard } from "./PlaylistCard";
import { Text } from "../ui/Text";
import { Analytics } from "@/services/Analytics";

let totalRequests = 0;
let didRequestRecommendedPlaylists = false;

export const RecommendPlaylists = () => {
  const activeUser = useActiveUser();

  const userProfileData = useUserProfileData();
  const hasRecommendedPlaylists = useHasRecommendedPlaylists();
  const userHasSelectedSubjects = useUserHasSelectedSubjects();
  const userCustomPlaylistRecommendationRequest = useStore(
    (state) => state.playlists.userCustomPlaylistRecommendationRequest,
  );
  const setExplicitPlaylistMessage = useStore(
    (state) => state.playlists.setExplicitPlaylistMessage,
  );
  const setShowPlaylistLoading = useStore(
    (state) => state.playlists.setShowPlaylistLoading,
  );
  // const [didRequestRecommendedPlaylists, setDidRequestRecommendedPlaylists] =
  //   useState(false);

  const didLoadUserData = useStore((state) => state.user.didLoadUserData);

  useEffect(() => {
    if (!hasRecommendedPlaylists) {
      setExplicitPlaylistMessage("Finding the best playlists for you...");
      setShowPlaylistLoading(true);
    } else {
      setExplicitPlaylistMessage("Hope these playlists are helpful!");
      setShowPlaylistLoading(false);
    }

    return () => {
      setExplicitPlaylistMessage("");
      setShowPlaylistLoading(false);
    };
  }, [
    hasRecommendedPlaylists,
    setExplicitPlaylistMessage,
    setShowPlaylistLoading,
  ]);

  useEffect(() => {
    return () => {
      totalRequests = 0;
    };
  }, []);

  useEffect(() => {
    async function getRecommendedPlaylists() {
      totalRequests++;
      didRequestRecommendedPlaylists = true;

      const startTime = Date.now();

      console.log("Get recommended playlists", {
        userProfileData,
        userCustomPlaylistRecommendationRequest,
      });

      PlaylistService.clearUserRecommendedPlaylists();

      Analytics.getRecommendedPlaylists({
        inputValue: userCustomPlaylistRecommendationRequest,
      });

      const playlistRecommendations = await AdvizeApi.recommendPlaylists({
        userProfileData,
        customRequest: userCustomPlaylistRecommendationRequest,
      });

      const duration = Date.now() - startTime;
      if (duration > 5000) {
        console.error(`Playlist recommendations took too long: ${duration}ms`);
        Analytics.playlistError({
          error: "Playlist recommendations took too long",
          playlist: null,
          value: duration || -1,
        });
      }

      // Check if recommendations are empty or invalid
      if (!playlistRecommendations || playlistRecommendations.length === 0) {
        Analytics.playlistError({
          error: "No playlist recommendations were returned",
          playlist: null,
          value: duration || -1,
        });
      }

      console.log("Playlist Recommendations", { playlistRecommendations });

      PlaylistService.updateUserRecommendedPlaylists(
        playlistRecommendations || [],
      );

      didRequestRecommendedPlaylists = false;
    }

    if (didRequestRecommendedPlaylists) return;
    if (!userProfileData) return;
    if (hasRecommendedPlaylists) return;
    if (totalRequests >= 5) return;
    if (activeUser && !didLoadUserData) return;
    if (!userHasSelectedSubjects) return;

    getRecommendedPlaylists();

    // console.log("WAITING FOR PLAYLIST", loadingText);
    // setTimeout(() => {
    //   console.log("NAV TO PLAYLIST");
    //   Navigation.navToPlaylist("industry-tech-day-in-the-life");
    //   setPlaylistLoading(false);
    // }, 1500);

    // return () => {
    //   setPlaylistLoading(false);
    // };
  }, [
    activeUser,
    didLoadUserData,
    // didRequestRecommendedPlaylists,
    hasRecommendedPlaylists,
    userCustomPlaylistRecommendationRequest,
    userHasSelectedSubjects,
    userProfileData,
  ]);

  return (
    <Stack maw={650} align="center">
      {hasRecommendedPlaylists ? <ListOfRecommendedPlaylists /> : null}

      <Space h="xl" />
      {hasRecommendedPlaylists && (
        <Text size="xl  " ta="center">
          Don't see what you're looking for?
        </Text>
      )}
      {hasRecommendedPlaylists && (
        <Button
          radius="xl"
          leftSection={<IconSearch size={18} />}
          onClick={() => {
            Navigation.navToPlaylistLibrary();
            Analytics.click({
              action: "browse_all_playlists",
              label: "Browse all playlists",
              context: "recommended_playlists",
            });
          }}
          maw={300}
          fullWidth
        >
          Browse all playlists
        </Button>
      )}
    </Stack>
  );
};

export const ListOfRecommendedPlaylists = () => {
  const recommendedPlaylists = useRecommendedPlaylists();
  const activePlaylist = useActivePlaylist();
  return (
    <Stack>
      {Object.keys(recommendedPlaylists)
        .filter((playlistId) => playlistId !== activePlaylist?.id)
        .map((playlistId) => (
          <PlaylistCard
            key={playlistId}
            playlistId={playlistId}
            isPlaylistConclusion
          />
        ))}
    </Stack>
  );
};
