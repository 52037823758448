import { forwardRef, useState, useEffect, useRef, useCallback } from "react";
import {
  ActionIcon,
  Textarea as MantineTextarea,
  TextareaProps as MantineTextareaProps,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import classes from "@/styles/TextInput.module.css";

type TextareaValue = string;

export interface TextareaProps extends Omit<MantineTextareaProps, "value"> {
  id: string;
  value: TextareaValue;
  defaultValue?: TextareaValue;
  onDebouncedChange?: (value: TextareaValue) => void;
  debounceDelay?: number;
  onClear?: () => void;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { id, onDebouncedChange, debounceDelay = 300, onClear, value, ...props },
    ref,
  ) => {
    const [localValue, setLocalValue] = useState<TextareaValue>(
      value || props.defaultValue || "",
    );

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      setLocalValue(value || "");
    }, [value]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value;
        setLocalValue(newValue);
        props.onChange?.(event);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          onDebouncedChange?.(newValue);
        }, debounceDelay);
      },
      [props, debounceDelay, onDebouncedChange],
    );

    const handleClear = useCallback(() => {
      setLocalValue("");
      onClear?.();

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }, [onClear]);

    return (
      <MantineTextarea
        ref={ref}
        id={id}
        name={id}
        value={localValue}
        aria-invalid={props.error ? true : undefined}
        aria-errormessage={props.error ? `${id}-error` : undefined}
        onChange={handleChange}
        classNames={{ input: classes.textInput }}
        rightSection={
          value && onClear ? (
            <ActionIcon
              onClick={handleClear}
              variant="transparent"
              color={!value ? "text.4" : "text.6"}
            >
              <IconX />
            </ActionIcon>
          ) : null
        }
        styles={{
          label: {
            color: "text.1",
          },
        }}
        {...props}
      />
    );
  },
);

Textarea.displayName = "Textarea";
