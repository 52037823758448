import { RealtimeDatabase } from "./RealtimeDatabase";
import { Analytics } from "./Analytics";
import { StoreService } from "./StoreService";
import { Experience } from "./Experience";

class PlaylistServiceClass {
  constructor() {}

  async getAllPlaylists() {
    const playlistLibraryResponse = await fetch(
      "/playlists/playlistLibrary.json",
    );
    const playlistLibraryRaw = await playlistLibraryResponse.json();
    const playlistLibrary = Object.values(playlistLibraryRaw)
      .filter((playlist: any) => !!playlist.advizers)
      .reduce((acc: any, playlist: any) => {
        acc[playlist.id] = playlist;
        return acc;
      }, {} as any);

    const playlists = Object.values(playlistLibrary || {})
      .filter((playlist: any) => !!playlist.advizers)
      .map((playlist: any) => {
        return {
          ...playlist,
          // advizers: playlist.advizers.map((advizer: any) => advizer.id),
        };
      });

    StoreService.setAllPlaylists(
      playlists.sort(() => {
        return Math.random() - 0.5;
      }),
    );
    StoreService.setPlaylistLibrary(playlistLibrary);

    return playlistLibrary;
  }

  async fetchPlaylistById(playlistId: string) {
    const playlistResponse = await fetch(`/playlists/${playlistId}.json`);
    const playlist = await playlistResponse.json();
    return playlist;
  }

  getUserPlaylistStepData(params: { playlistId: string; stepKey: string }) {
    return StoreService.getUserState().playlistData?.playlists?.[
      params.playlistId
    ]?.steps?.[params.stepKey];
  }

  updateUserPlaylistStepData(params: {
    playlistId: string;
    stepKey: string;
    data: any;
  }) {
    // console.log("updateUserPlaylistStepData", { params });

    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.updateCurrentUserPlaylistStepData({
        playlistId: params.playlistId,
        stepKey: params.stepKey,
        data: params.data,
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        if (!draft.user.playlistData) {
          draft.user.playlistData = {
            playlists: {},
            recommendedPlaylists: {},
          };
        }

        if (!draft.user.playlistData.playlists[params.playlistId]) {
          draft.user.playlistData.playlists[params.playlistId] = {};
        }

        if (!draft.user.playlistData.playlists[params.playlistId].steps) {
          draft.user.playlistData.playlists[params.playlistId].steps = {};
        }

        // dont overwrite the data if it already exists, loop over the data and merge it
        const existingData =
          draft.user.playlistData.playlists[params.playlistId].steps[
            params.stepKey
          ];
        const mergedData = { ...existingData, ...params.data };

        draft.user.playlistData.playlists[params.playlistId].steps[
          params.stepKey
        ] = mergedData;
      },
      { saveLocal: true },
    );
  }

  async markPlaylistAsCompleted(params: { playlistId: string; playlist: any }) {
    const activeUser = StoreService.getActiveUser();
    console.log("markPlaylistAsCompleted", { params, activeUser });

    if (!activeUser) {
      StoreService.createStateUpdater(
        (draft) => {
          if (!draft.user.playlistData.playlists[params.playlistId]) {
            draft.user.playlistData.playlists[params.playlistId] = {};
          }

          draft.user.playlistData.playlists[params.playlistId].completed =
            Date.now();
        },
        { saveLocal: true },
      );
      return;
    }

    const currentCompletionStatus =
      await RealtimeDatabase.fetchPlaylistCompletionStatus({
        playlistId: params.playlistId,
      });

    RealtimeDatabase.markPlaylistAsCompleted({
      playlistId: params.playlistId,
    });

    if (!currentCompletionStatus) {
      Experience.showConfetti();
      Analytics.playlistComplete({
        playlist: params.playlist,
      });
    }
    return;
  }

  updateUserPlaylistVideoStepData(params: {
    playlistId: string;
    stepKey: string;
    data: any;
  }) {
    console.log("updateUserPlaylistVideoStepData", { params });

    const existingTotalSecondsWatched =
      StoreService.getUserState().playlistData.playlists[params.playlistId]
        ?.steps[params.stepKey]?.totalSecondsWatched || 0;

    const existingData =
      StoreService.getUserState().playlistData.playlists[params.playlistId]
        ?.steps[params.stepKey];

    const newTotalSecondsWatched =
      existingTotalSecondsWatched + (params.data.secondsToAdd || 0);

    const newData = { ...existingData };
    if (params.data.secondsToAdd) {
      newData.totalSecondsWatched = newTotalSecondsWatched;
    }

    if (params.data.percentProgress) {
      newData.percentProgress = params.data.percentProgress;
    }

    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.updateCurrentUserPlaylistStepData({
        playlistId: params.playlistId,
        stepKey: params.stepKey,
        data: newData,
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        if (!draft.user.playlistData) {
          draft.user.playlistData = {
            playlists: {},
            recommendedPlaylists: {},
          };
        }

        if (!draft.user.playlistData.playlists) {
          draft.user.playlistData.playlists = {};
        }

        if (!draft.user.playlistData.playlists[params.playlistId]) {
          draft.user.playlistData.playlists[params.playlistId] = {};
        }

        if (!draft.user.playlistData.playlists[params.playlistId].steps) {
          draft.user.playlistData.playlists[params.playlistId].steps = {};
        }

        if (
          !draft.user.playlistData.playlists[params.playlistId].steps[
            params.stepKey
          ]
        ) {
          draft.user.playlistData.playlists[params.playlistId].steps[
            params.stepKey
          ] = {};
        }

        draft.user.playlistData.playlists[params.playlistId].steps[
          params.stepKey
        ] = newData;
      },
      { saveLocal: true },
    );
  }

  updateUserRecommendedPlaylists(playlistIds: string[]) {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.updateCurrentUserRecommendedPlaylists({
        playlistIds,
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        draft.user.playlistData.recommendedPlaylists = playlistIds.reduce(
          (acc, id) => {
            acc[id] = true;
            return acc;
          },
          {} as any,
        );
      },
      { saveLocal: true },
    );
  }

  clearUserRecommendedPlaylists() {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.clearUserRecommendedPlaylists();
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        draft.user.playlistData.recommendedPlaylists = {};
      },
      { saveLocal: true },
    );
  }
}

export const PlaylistService = new PlaylistServiceClass();
