import { useStore } from "@/store";
import {
  Avatar,
  Box,
  Group,
  lighten,
  Progress,
  RingProgress,
  Space,
  Stack,
} from "@mantine/core";
import { Text } from "@/components/ui/Text";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import emilyAvatar from "@/assets/emily-avatar.jpeg";
import {
  useActivePlaylist,
  useActivePlaylistAdvizer,
  useActivePlaylistProgress,
  useActivePlaylistStep,
  useActiveStepNumber,
  useFirstStepKeyInPlaylist,
  useIsFirstStepInPlaylist,
  useIsOnboardingPlaylist,
  useIsVideoStep,
  useTotalStepsInPlaylist,
} from "@/store/selectors/playlistSelectors";
import { IconCheck, IconLink, IconPlaylist } from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "../ui/Link";
import { Button } from "../ui/Button";
import { useIsLoggedIn } from "@/store/selectors";

export const PlaylistTopBar = (props: {
  hideAvatar?: boolean;
  maxWidth?: number;
  handleClickNextButton?: () => void;
  bg?: string;
}) => {
  const { hideAvatar = false, maxWidth } = props;
  const { theme } = useStyles();
  let progress = useActivePlaylistProgress();
  const avatarSize = progress ? 100 : 100;
  const ringThickness = 8;
  const ringSize = avatarSize + ringThickness * 4;
  const playlist = useActivePlaylist() || {};
  const showMainNavbarRaw = useStore((state) => state.showMainNavbar);
  const activeAdvizer = useActivePlaylistAdvizer();
  const isFirstStep = useIsFirstStepInPlaylist();
  const isOnboarding = useIsOnboardingPlaylist();
  const activePlaylistStep = useActivePlaylistStep();
  const showMainNavbar =
    showMainNavbarRaw && activePlaylistStep?.stepType !== "video";
  const showAvatar = showMainNavbar && !hideAvatar;
  const isPlaylistLoading = useStore(
    (state) => state.playlists.isPlaylistLoading,
  );
  const showPlaylistLoading = useStore(
    (state) => state.playlists.showPlaylistLoading,
  );
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isLoadingState = isPlaylistLoading || !playlist || showPlaylistLoading;
  const [, setIsUpdatingProgress] = useState(false);
  const isVideoStep = useIsVideoStep();
  const firstStepKey = useFirstStepKeyInPlaylist();
  const [copied, setCopied] = useState(false);
  const activeStepNumber = useActiveStepNumber();
  const totalStepsInPlaylist = useTotalStepsInPlaylist();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  if (isOnboarding) {
    progress = 0;
  }

  const renderAvatar = useCallback(() => {
    return (
      <Group
        pos="absolute"
        bottom={-ringSize / 2}
        left={0}
        w="100%"
        justify="center"
        align="center"
        bg={"transparent"}
        display={showAvatar ? undefined : "none"}
      >
        <Box pos="relative">
          <Avatar
            src={activeAdvizer ? activeAdvizer.profilePictureURL : emilyAvatar}
            size={avatarSize}
            alt="Emily, CEO of Advize"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          />
          <RingProgress
            size={ringSize}
            thickness={ringThickness}
            transitionDuration={1000}
            style={
              isLoadingState
                ? {
                    transform: "rotate(0deg)",
                    animation: "spin 1s linear infinite",
                  }
                : undefined
            }
            sections={
              isLoadingState
                ? [
                    {
                      value: 25, // Quarter of the circle
                      color: theme.colors.secondary[7],
                    },
                  ]
                : [{ value: 100, color: theme.colors.background[2] }]
            }
            label={<></>}
          />
        </Box>
      </Group>
    );
  }, [
    activeAdvizer,
    avatarSize,
    isLoadingState,
    ringSize,
    ringThickness,
    showAvatar,
    theme.colors.background,
    theme.colors.secondary,
  ]);

  useEffect(() => {
    setIsUpdatingProgress(true);
    const timeout = setTimeout(() => {
      setIsUpdatingProgress(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [progress]);

  const StepCount = useMemo(() => {
    return (
      <Text titleStyle="h5" c="text.7">
        Level <span style={{ fontWeight: 700 }}>{activeStepNumber}</span>{" "}
        <span style={{ opacity: 0.75 }}>of {totalStepsInPlaylist}</span>
      </Text>
    );
  }, [activeStepNumber, totalStepsInPlaylist]);

  const CopyLinkButton = useMemo(() => {
    return (
      <Button
        variant="light"
        size="xs"
        radius="xl"
        color="primary"
        w={110}
        style={{ display: "flex", alignItems: "flex-start" }}
        leftSection={copied ? <IconCheck size={14} /> : <IconLink size={14} />}
        onClick={() => {
          setCopied(true);
          navigator.clipboard.writeText(
            `${window.location.origin}/playlists/${playlist.slug}`,
          );
        }}
      >
        {copied ? "Copied!" : "Copy Link"}
      </Button>
    );
  }, [copied, playlist?.slug]);

  const PlaylistTitle = useMemo(() => {
    if (!playlist?.title || isFirstStep || !isLoggedIn) return null;

    return (
      <Stack
        w="100%"
        justify="flex-start"
        align="flex-start"
        px={6}
        py={4}
        maw={maxWidth}
        gap="xs"
      >
        <Group flex={1} justify="flex-start">
          <Link to={`/playlists/${playlist.slug}?step=${firstStepKey}`}>
            <Group gap={4} align="flex-start">
              <IconPlaylist
                size={isLaptopOrBigger ? 22 : 18}
                color={theme.colors.primary[9]}
              />{" "}
              <Text
                titleStyle={isLaptopOrBigger ? "h4" : "h5"}
                c="primary.9"
                ta="left"
                flex={1}
                lineClamp={showMainNavbar ? (isLaptopOrBigger ? 1 : 2) : 1}
                lh={1.2}
              >
                {playlist.title}
              </Text>
            </Group>
          </Link>
        </Group>
        {/* {showMainNavbar ? (
          <Group align="flex-end" justify="space-between" w="100%">
            <Group>{CopyLinkButton}</Group>
            {StepCount}
          </Group>
        ) : null} */}
      </Stack>
    );
  }, [
    playlist.title,
    playlist.slug,
    isFirstStep,
    maxWidth,
    firstStepKey,
    isLaptopOrBigger,
    theme.colors.primary,
    showMainNavbar,
    isLoggedIn,
  ]);

  // if (!playlist?.title && !showMainNavbar) return null;

  return (
    <Stack
      px="xs"
      pt="xs"
      gap={0}
      pos="sticky"
      bg={lighten(theme.colors.background[1], 0.25)}
      w="100%"
      top={0}
      style={{
        zIndex: 10,
        borderBottom: isVideoStep
          ? `1px solid ${theme.colors.background[2]}`
          : `4px solid ${theme.colors.background[2]}`,
        boxShadow: !showMainNavbar
          ? "0 0 12px 0 rgba(0, 0, 0, 0.2)"
          : undefined,
      }}
      align="center"
      justify="space-between"
      pb={showAvatar ? avatarSize / 2 + 4 : "xs"}
    >
      {PlaylistTitle}

      <Space h={showMainNavbar && !playlist?.title ? "xl" : 4} />

      {!!PlaylistTitle && (
        <Group
          w="100%"
          justify="center"
          align="center"
          px={4}
          pt={4}
          maw={maxWidth}
        >
          {CopyLinkButton}
          <Progress
            value={progress}
            color="primary.7"
            flex={1}
            size="md"
            transitionDuration={1000}
            // animated={isUpdatingProgress || isAnyVideoPlaying}
          />
          {StepCount}
        </Group>
      )}

      <Space h={showMainNavbar && playlist?.title ? "xl" : 0} />

      {renderAvatar()}
    </Stack>
  );
};
