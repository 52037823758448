import { useStore } from "@/store";
import {
  useActiveUserPartner,
  useAdvizer,
  useAdvizerMap,
  useAdvizerSearchText,
  useAllQuestions,
  useApiDescriptorsVideoMap,
  useFilteredAdvizers,
  useIndustryFilterOptions,
  useIsLoggedIn,
  useJobFunctionFilterOptions,
  useSelectedAdvizerSearchFilters,
  useSubjectFilterOptions,
  useTraitFilterOptions,
} from "../selectors";
import { useSearchParams } from "react-router-dom";
import { Navigation } from "@/services/Navigation";
import { useCallback } from "react";
import { PLAYLIST_OUTCOMES } from "@/configs/playlists/playlistOutcomeConfigs";
import {
  PLAYLIST_STEP_TYPE_CONFIGS,
  USER_PROFILE_DATA_COLLECTION_STEP_TYPES,
} from "@/configs/playlists/playlistStepTypeConfigs";
import {
  useUserCompletedPlaylistKeys,
  useUserInProgressPlaylistKeys,
  useUserProfileData,
} from "./userProfileSelectors";
import { IAdvizerTag } from "@/components/AdvizerTag";
import { IAdvizerModel } from "@/models/Advizer";
import { IQuestion } from "@/types/ApiTypes";
import { useIsDemoApp } from "./featureSelectors";

/*===========================================
 Accessss 
===========================================*/

export const useUserCanAccessPlaylists = () => {
  const isLoggedIn = useIsLoggedIn();
  const userPartner = useActiveUserPartner();
  const isDemoApp = useIsDemoApp();
  return (isLoggedIn && !!userPartner) || isDemoApp;
};

/*===========================================
 Basics 
===========================================*/

export const useActivePlaylistId = () => {
  // const { playlistSlug } = useParams();
  // console.log({ playlistSlug });
  // if (playlistSlug === "start") return "onboarding";
  // return playlistSlug || "";
  const activePlaylistId = useStore(
    (state) => state.playlists.activePlaylistId,
  );

  return activePlaylistId;
};

export const useActivePlaylistStepKeyRaw = () => {
  const [searchParams] = useSearchParams();
  const stepKey = searchParams.get("step");
  return stepKey;
};

export const useActiveStepNumber = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const stepNumber = stepOrder.indexOf(activePlaylistStepKey);
  return stepNumber + 1;
};

export const useTotalStepsInPlaylist = () => {
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  return stepOrder.length;
};

export const useActivePlaylistStepKey = () => {
  const activePlaylistStepKeyRaw = useActivePlaylistStepKeyRaw();
  const activePlaylistStepKeyFromStore = useStore(
    (state) => state.playlists.activePlaylistStepKey,
  );
  return activePlaylistStepKeyRaw || activePlaylistStepKeyFromStore || "";
};

export const useActivePlaylistStep = (playlistId?: string) => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist(playlistId || "");

  if (!activePlaylist?.steps) return null;
  return activePlaylist.steps[activePlaylistStepKey];
};

export const useNextPlaylistStepKey = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const nextStepKey = stepOrder[stepOrder.indexOf(activePlaylistStepKey) + 1];
  return nextStepKey;
};

export const useNextPlaylistStep = () => {
  const nextStepKey = useNextPlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  return activePlaylist?.steps?.[nextStepKey];
};

export const useShouldAutoNavToNextStepInPlaylist = () => {
  const [searchParams] = useSearchParams();
  const shouldAutoNav = searchParams.get("autoNav");
  return !!shouldAutoNav && shouldAutoNav !== "false";
};

export const useIsOnboardingPlaylist = () => {
  const activePlaylistId = useActivePlaylistId();
  return activePlaylistId === "onboarding" || activePlaylistId === "start";
};

/*===========================================
  useActivePlaylist
===========================================*/
export const useActivePlaylist = (playlistId?: string) => {
  const isOnboardingPlaylist = useIsOnboardingPlaylist();

  let playlist = useStore((state) => state.playlists.activePlaylist);
  const playlistFromId = usePlaylistById(playlistId || "");
  if (playlistFromId) {
    playlist = playlistFromId;
  }

  const activePlaylistOutcome = useActivePlaylistOutcome();

  if (!playlist) return null;

  let steps = Object.entries(playlist.steps || {}).reduce(
    (acc, [stepKey, stepConfig]: [string, any]) => {
      const isInterestStep =
        stepKey.includes("interest-response-before") ||
        stepKey.includes("interest-response-after");

      if (isInterestStep) return acc;

      acc[stepKey] = {
        stepType: stepKey,
        ...PLAYLIST_STEP_TYPE_CONFIGS[stepConfig.stepType],
        ...stepConfig,
      };
      return acc;
    },
    {} as Record<string, any>,
  );

  if (isOnboardingPlaylist) {
    steps = {
      ...steps,
      ...USER_PROFILE_DATA_COLLECTION_STEP_TYPES,
    };
  }

  return {
    ...playlist,
    steps,
    stepOrder: [
      ...(isOnboardingPlaylist
        ? activePlaylistOutcome?.requiredProfileDataSteps || []
        : []),
      ...(playlist?.stepOrder || Object.keys(steps || {})),
    ].sort((a, b) => {
      // make sure "get-suggestions" is last
      if (a === "get-playlists") return 1;
      if (b === "get-playlists") return -1;

      // make sure welcome is first
      if (a === "welcome") return -1;
      if (b === "welcome") return 1;

      return 0;
    }),
  };
};

/*===========================================
 Steps
===========================================*/

export const useFirstStepKeyInPlaylist = () => {
  const activePlaylist = useActivePlaylist();
  return activePlaylist?.stepOrder[0];
};

export const useIsVideoStep = () => {
  const activePlaylistStep = useActivePlaylistStep();
  return activePlaylistStep?.stepType === "video";
};

/*===========================================
 Step completion statuses
===========================================*/

export const useIsPlaylistInProgressForUser = (playlistId: string) => {
  const inProgressPlaylistKeys = useUserInProgressPlaylistKeys();
  return inProgressPlaylistKeys.includes(playlistId);
};

export const useIsCurrentStepComplete = (playlistId?: string) => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylistId = useActivePlaylistId();
  const activePlaylistStep = useActivePlaylistStep(playlistId || "");

  //@ts-expect-error need to force render
  const userPlaylistData = useUserPlaylistData();

  //@ts-expect-error need to force render
  const userProfileData = useUserProfileData();

  if (!activePlaylistStep?.isStepComplete) return true;

  return activePlaylistStep?.isStepComplete({
    playlistId: activePlaylistId,
    stepKey: activePlaylistStepKey,
  });
};

export const useStepCompletionStatuses = () => {
  const activePlaylist = useActivePlaylist();
  const activePlaylistId = useActivePlaylistId();
  const stepOrder = activePlaylist?.stepOrder || [];
  const steps = activePlaylist?.steps || {};
  const stepCompletionStatuses = stepOrder
    .map((stepKey: string) => {
      const isStepComplete = steps[stepKey]?.isStepComplete;

      const finalIsStepComplete = isStepComplete
        ? isStepComplete({
            playlistId: activePlaylistId,
            stepKey,
          })
        : true;

      return {
        stepKey,
        isComplete: finalIsStepComplete,
      };
    })
    .reduce(
      (acc: any, status: any) => {
        acc[status.stepKey] = status.isComplete;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  return stepCompletionStatuses;
};

export const useNextIncompleteStepKeyInPlaylist = () => {
  const activePlaylist = useActivePlaylist();
  const stepCompletionStatuses = useStepCompletionStatuses();
  if (!activePlaylist) return null;
  const nextIncompleteStepKey = activePlaylist?.stepOrder.find(
    (stepKey: any) => !stepCompletionStatuses[stepKey],
  );
  const indexOfNextIncompleteStepKey = activePlaylist?.stepOrder.indexOf(
    nextIncompleteStepKey,
  );
  const isSecondToLastStep =
    indexOfNextIncompleteStepKey === activePlaylist?.stepOrder?.length - 2;

  if (nextIncompleteStepKey?.endsWith("after") && !isSecondToLastStep) {
    return "video-1";
  }

  if (nextIncompleteStepKey) {
    return nextIncompleteStepKey;
  }

  const lastStepKey =
    activePlaylist?.stepOrder[activePlaylist?.stepOrder.length - 1];

  // return the last step
  return lastStepKey;
};

export const useIsPlaylistCompleted = (playlistId: string) => {
  const userCompletedPlaylistKeys = useUserCompletedPlaylistKeys();
  return userCompletedPlaylistKeys.includes(playlistId);
};

/*===========================================
  Playlist Duration
===========================================*/

export const useEstimatedPlaylistDuration = (playlistId?: string) => {
  const activePlaylistVideos = useActivePlaylistVideos(playlistId || "");

  if (!activePlaylistVideos.length) return null;
  const totalSeconds = activePlaylistVideos.reduce(
    (acc, video) => acc + (video.duration || 2),
    0,
  );
  const minutes = Math.ceil(totalSeconds / 60);
  return minutes + 1;
};

/*===========================================
  Playlist Progress
===========================================*/

export const useActivePlaylistProgress = (playlistId?: string) => {
  const activePlaylistId = useActivePlaylistId();
  const activePlaylist = useActivePlaylist(playlistId || "");
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const stepOrder = activePlaylist?.stepOrder || [];
  const activePlaylistStepIndex = stepOrder.indexOf(activePlaylistStepKey);
  const totalSteps = stepOrder.length - 1; // excl conclusion
  // const percentagePerStep = 100 / totalSteps;

  // @ts-expect-error need to force render
  const userProfileData = useUserProfileData();

  if (!activePlaylistId) return 0;

  // total percentage complete should just be percentage of steps up to current step
  const totalPercentageComplete = (activePlaylistStepIndex / totalSteps) * 100;

  // const totalPercentageComplete = stepOrder.reduce(
  //   (acc: number, stepKey: string) => {
  //     const activePlaylistStep = activePlaylist?.steps?.[stepKey];
  //     if (
  //       activePlaylistStep?.isStepComplete?.({
  //         playlistId: activePlaylistId,
  //         stepKey,
  //       })
  //     ) {
  //       return acc + percentagePerStep;
  //     }

  //     const stepIndex = stepOrder.indexOf(stepKey);
  //     const someStepAfterIsComplete = stepOrder.some(
  //       (sk: string) =>
  //         stepOrder.indexOf(sk) > stepIndex &&
  //         activePlaylist?.steps?.[sk]?.isStepComplete?.({
  //           playlistId: activePlaylistId,
  //           stepKey: sk,
  //         }),
  //     );

  //     if (someStepAfterIsComplete) {
  //       return acc + percentagePerStep;
  //     }

  //     if (stepOrder.indexOf(stepKey) < activePlaylistStepIndex) {
  //       return acc + percentagePerStep;
  //     }

  //     return acc;
  //   },
  //   0,
  // );

  return totalPercentageComplete;
};

export const useDidUserCompletePlaylist = (playlistId: string) => {
  const activePlaylistProgress = useActivePlaylistProgress(playlistId);
  return activePlaylistProgress >= 100;
};

/*===========================================
  Playlist Recommendations
===========================================*/

export const useRecommendedPlaylists = () => {
  const recommendedPlaylists = useStore(
    (state) => state.user.playlistData?.recommendedPlaylists,
  );
  return recommendedPlaylists || {};
};

export const useHasRecommendedPlaylists = () => {
  const recommendedPlaylists = useRecommendedPlaylists();
  return Object.keys(recommendedPlaylists).length > 0;
};

export const useIsRecommendedPlaylist = (playlistId: string) => {
  const recommendedPlaylists = useRecommendedPlaylists();
  return recommendedPlaylists?.[playlistId];
};

/*===========================================
  Playlist search
===========================================*/

export const useFilteredPlaylists = () => {
  const playlists = useAllPlaylists();
  const { advizers: filteredAdvizers, isShowingAllAdvizers } =
    useFilteredAdvizers();

  const {
    traits: selectedTraitIds,
    industries: selectedIndustryIds,
    jobFunctions: selectedJobFunctionIds,
    subjects: selectedSubjectIds,
  } = useSelectedAdvizerSearchFilters();
  const activeQuestionNumberRaw = useStore(
    (state) => state.advizerSearch.activeQuestionNumber,
  );
  const { traits: traitOptions } = useTraitFilterOptions();
  const { industries: industryOptions } = useIndustryFilterOptions();
  const { jobFunctions: jobFunctionOptions } = useJobFunctionFilterOptions();
  const { subjects: subjectOptions } = useSubjectFilterOptions();
  const { searchText } = useAdvizerSearchText();
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const questions = useAllQuestions();
  const activeQuestionNumber =
    activeQuestionId && questions?.length
      ? questions?.find((q: IQuestion) => q.id === activeQuestionId)?.number ||
        activeQuestionNumberRaw
      : activeQuestionNumberRaw;

  const selectedTraits = Object.keys(selectedTraitIds).reduce(
    (acc, traitId) => {
      const trait = (traitOptions || []).find((t: any) => t.id === traitId);
      if (trait) {
        acc[trait.slug] = true;
      }
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const selectedIndustries = Object.keys(selectedIndustryIds).reduce(
    (acc, industryId) => {
      const industry = industryOptions.find((i: any) => i.id === industryId);
      if (industry) {
        acc[industry.slug] = true;
      }
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const selectedJobFunctions = Object.keys(selectedJobFunctionIds).reduce(
    (acc, jobFunctionId) => {
      const jobFunction = jobFunctionOptions.find(
        (j: any) => j.id === jobFunctionId,
      );
      if (jobFunction) {
        acc[jobFunction.slug] = true;
      }
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const selectedSubjects = Object.keys(selectedSubjectIds).reduce(
    (acc, subjectId) => {
      const subject = subjectOptions.find((s: any) => s.id === subjectId);
      if (subject) {
        acc[subject.slug] = true;
      }
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const hasSelectedTraits = Object.keys(selectedTraitIds).length > 0;
  const hasSelectedIndustries = Object.keys(selectedIndustryIds).length > 0;
  const hasSelectedJobFunctions =
    Object.keys(selectedJobFunctionIds).length > 0;
  const hasSelectedSubjects = Object.keys(selectedSubjectIds).length > 0;

  const filteredAdvizersMap = (filteredAdvizers || []).reduce(
    (acc, advizer) => {
      acc[advizer.id] = advizer;
      return acc;
    },
    {} as Record<string, IAdvizerModel>,
  );

  const filteredByAdvizerMatchPlaylistMap: Record<string, any> = {};

  if (!isShowingAllAdvizers && !activeQuestionNumber) {
    playlists.forEach((playlist: any) => {
      Object.keys(playlist.advizers || {}).forEach((advizerId) => {
        if (filteredAdvizersMap[advizerId]) {
          filteredByAdvizerMatchPlaylistMap[playlist.id] = playlist;

          filteredByAdvizerMatchPlaylistMap[playlist.id].sortRanking =
            (filteredByAdvizerMatchPlaylistMap[playlist.id].sortRanking || 0) +
            1;

          filteredByAdvizerMatchPlaylistMap[
            playlist.id
          ].advizerIdsMatchingFilters = [
            ...(filteredByAdvizerMatchPlaylistMap[playlist.id]
              .advizerIdsMatchingFilters || []),
            advizerId,
          ];

          filteredByAdvizerMatchPlaylistMap[
            playlist.id
          ].isFilteredForMatchingAdvizer = true;
        }
      });
    });
  }

  const matchingPlaylistMap: Record<string, any> = {};

  playlists.forEach((playlist: any) => {
    let matches = 0;
    const { uniqId } = playlist;

    if (isShowingAllAdvizers && !activeQuestionNumber) {
      matches = 0;
      matchingPlaylistMap[playlist.id] = playlist;
      matchingPlaylistMap[playlist.id].sortRanking = matches;
    } else {
      const isTraitMatch = hasSelectedTraits
        ? uniqId?.traits?.some((traitSlug: string) => {
            return selectedTraits[traitSlug];
          })
        : true;

      const isIndustryMatch = hasSelectedIndustries
        ? uniqId?.industries?.some((industrySlug: string) => {
            return selectedIndustries[industrySlug];
          })
        : true;

      const isJobFunctionMatch = hasSelectedJobFunctions
        ? uniqId?.jobFunctions?.some((jobFunctionSlug: string) => {
            return selectedJobFunctions[jobFunctionSlug];
          })
        : true;

      const isSubjectMatch = hasSelectedSubjects
        ? uniqId?.subjects?.some((subjectSlug: string) => {
            return selectedSubjects[subjectSlug];
          })
        : true;

      const isQuestionMatch = activeQuestionNumber
        ? Number(uniqId?.questionNumber) === Number(activeQuestionNumber)
        : true;

      if (isTraitMatch) {
        matches += 100;
      }

      if (isIndustryMatch) {
        matches += 100;
      }

      if (isJobFunctionMatch) {
        matches += 100;
      }

      if (isSubjectMatch) {
        matches += 100;
      }

      if (isQuestionMatch) {
        matches += 10;
      }

      const isFilteredForMatchingAdvizer =
        filteredByAdvizerMatchPlaylistMap[playlist.id]
          ?.advizerIdsMatchingFilters?.length > 1;

      const isSelectedTagMatch =
        (hasSelectedTraits ? isTraitMatch : true) &&
        (hasSelectedIndustries ? isIndustryMatch : true) &&
        (hasSelectedJobFunctions ? isJobFunctionMatch : true) &&
        (hasSelectedSubjects ? isSubjectMatch : true) &&
        (activeQuestionNumber ? isQuestionMatch : true);

      if (isSelectedTagMatch) {
        if (
          filteredByAdvizerMatchPlaylistMap[playlist.id]
            ?.advizerIdsMatchingFilters
        ) {
          filteredByAdvizerMatchPlaylistMap[
            playlist.id
          ].advizerIdsMatchingFilters = [];
        }
      } else if (isFilteredForMatchingAdvizer) {
        matches +=
          filteredByAdvizerMatchPlaylistMap[playlist.id]
            ?.advizerIdsMatchingFilters?.length;

        filteredByAdvizerMatchPlaylistMap[
          playlist.id
        ].isFilteredOnlyForMatchingAdvizers = true;
      }

      // console.log("====\n", {
      //   isFilteredForMatchingAdvizer,
      //   isTraitMatch,
      //   isIndustryMatch,
      //   isJobFunctionMatch,
      //   isSubjectMatch,
      //   isQuestionMatch,
      // });

      const isSearchTextMatch = `${playlist.title} ${playlist.description}`
        .toLowerCase()
        .includes(searchText.toLowerCase());

      if (
        (isSelectedTagMatch || isFilteredForMatchingAdvizer) &&
        isSearchTextMatch
      ) {
        // console.log({ match: playlist });
        matchingPlaylistMap[playlist.id] = playlist;
        matchingPlaylistMap[playlist.id].sortRanking = matches;

        if (isFilteredForMatchingAdvizer) {
          // matchingPlaylistMap[playlist.id].isFilteredForMatchingAdvizer = true;
          matchingPlaylistMap[playlist.id].advizerIdsMatchingFilters =
            filteredByAdvizerMatchPlaylistMap[
              playlist.id
            ].advizerIdsMatchingFilters;
          matchingPlaylistMap[playlist.id].isFilteredOnlyForMatchingAdvizers =
            filteredByAdvizerMatchPlaylistMap[
              playlist.id
            ].isFilteredOnlyForMatchingAdvizers;
        }

        delete filteredByAdvizerMatchPlaylistMap[playlist.id];
      }
    }
  });

  // TODO sort exact matches first, then by advizer match

  const visiblePlaylists = [
    ...Object.values(matchingPlaylistMap),
    // ...Object.values(filteredByAdvizerMatchPlaylistMap),
  ].sort((a, b) => {
    return (b.sortRanking || 0) - (a.sortRanking || 0);
  });

  return {
    playlists: visiblePlaylists,
    filteredAdvizersMap,
    totalFilteredPlaylists: visiblePlaylists.length,
  };

  // const { searchText } = useAdvizerSearchText();
  // const { subjects, industries, jobFunctions, traits, companies } =
  //   useSelectedAdvizerSearchFilters();
};

/*===========================================
  Everything else
===========================================*/

export const usePlaylistLibrary = () => {
  const playlistLibrary = useStore((state) => state.playlists.playlistLibrary);
  return playlistLibrary;
};

export const useAllPlaylists = () => {
  const playlistLibrary = usePlaylistLibrary();
  const allPlaylists = useStore((state) => state.playlists.allPlaylists) || [];
  const activeUserPartner = useActiveUserPartner();
  return allPlaylists
    .filter((playlist: any) => {
      if (playlist?.partners) {
        return !!playlist.partners[activeUserPartner?.slug];
      }
      return true;
    })
    .map((playlist: any) => ({
      ...playlist,
      config: playlistLibrary?.[playlist.id],
    }));
};

export const usePlaylistById = (playlistId: string) => {
  const allPlaylists = useAllPlaylists();
  const activePlaylist = useStore((state) => state.playlists.activePlaylist);
  if (activePlaylist?.id === playlistId) return activePlaylist;
  return allPlaylists.find((playlist: any) => playlist.id === playlistId);
};

export const useGoToNextStepInPlaylist = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const nextStepKey = stepOrder[stepOrder.indexOf(activePlaylistStepKey) + 1];

  const func = useCallback(() => {
    if (!nextStepKey) return;

    Navigation.updateQueryParams({ step: nextStepKey });
  }, [nextStepKey]);

  return func;
};

export const useGoToPreviousStepInPlaylist = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const previousStepKey =
    stepOrder[Math.max(0, stepOrder.indexOf(activePlaylistStepKey) - 1)];

  const func = useCallback(() => {
    if (!previousStepKey) return;
    Navigation.updateQueryParams({ step: previousStepKey });
  }, [previousStepKey]);

  return func;
};

export const useUserPlaylistData = () => {
  const playlistData = useStore((state) => state.user.playlistData);
  return playlistData;
};

export const useUserDataForActivePlaylist = () => {
  const activePlaylistId = useActivePlaylistId();
  const userPlaylistData = useUserPlaylistData();

  if (!activePlaylistId) return {};
  return userPlaylistData?.playlists?.[activePlaylistId];
};

export const useUserDataForActivePlaylistStep = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const userPlaylistDataForPlaylist = useUserDataForActivePlaylist();
  return userPlaylistDataForPlaylist?.steps?.[activePlaylistStepKey] || {};
};

export const useUserPlaylistStepData = () => {
  return useUserDataForActivePlaylistStep();
  // const activePlaylistId = useActivePlaylistId();
  // const activePlaylistStepKey = useActivePlaylistStepKey();
  // const userPlaylistData = useUserPlaylistData();

  // return userPlaylistData?.playlists?.[activePlaylistId]?.steps?.[
  //   activePlaylistStepKey
  // ];
};

export const useHidePlaylistBackButton = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const isFirstStep = useIsFirstStepInPlaylist();

  if (!activePlaylist || isFirstStep) return true;

  return (
    activePlaylist.steps[activePlaylistStepKey]?.hideBackButton || isFirstStep
  );
};

export const useIsFirstStepInPlaylist = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const isOnboardingPlaylist = useIsOnboardingPlaylist();

  if (isOnboardingPlaylist) return false;

  const stepOrder = activePlaylist?.stepOrder || [];
  return stepOrder.indexOf(activeStepKey) === 0;
};

export const useIsLastStepInActivePlaylist = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const index = stepOrder.indexOf(activeStepKey);
  return index === stepOrder.length - 1;
};

export const useIsSecondToLastStepInActivePlaylist = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const index = stepOrder.indexOf(activeStepKey);
  return index === stepOrder.length - 2;
};

export const useActivePlaylistStepParams = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  return activePlaylist?.steps?.[activeStepKey]?.stepParams;
};

export const useActivePlaylistAdvizer = () => {
  const activePlaylistStepParams = useActivePlaylistStepParams();
  const advizerId = activePlaylistStepParams?.advizerId;
  const advizer = useAdvizer(advizerId);
  return advizer;
};

export const usePlaylistVideo = (params: {
  advizerId: string;
  videoQuestionNumber: number;
}) => {
  const { advizerId, videoQuestionNumber } = params;
  const advizer = useAdvizer(advizerId);
  if (!advizer || !videoQuestionNumber) return null;
  const video = advizer.videos.find(
    (v) => v.questionNumber === videoQuestionNumber,
  );

  return video;
};

export const useActivePlaylistOutcomeKey = () => {
  const activePlaylistOutcomeKey = useStore(
    (state) => state.playlists.activePlaylistOutcomeKey,
  );

  // const isOnboardingPlaylist = useIsOnboardingPlaylist();
  // if (!isOnboardingPlaylist) return null; // TODO remove this

  return activePlaylistOutcomeKey || "narrow-down-career-options";
};

export const useActivePlaylistOutcome = () => {
  const activePlaylistOutcomeKey = useActivePlaylistOutcomeKey();
  const activePlaylistOutcome =
    PLAYLIST_OUTCOMES[
      activePlaylistOutcomeKey as keyof typeof PLAYLIST_OUTCOMES
    ];
  return { ...activePlaylistOutcome, outcomeKey: activePlaylistOutcomeKey };
};

export const useActivePlaylistVideo = () => {
  const stepParams = useActivePlaylistStepParams();
  const video = usePlaylistVideo({
    advizerId: stepParams?.advizerId,
    videoQuestionNumber: stepParams?.videoQuestionNumber,
  });
  return video;
};

export const useActivePlaylistAdvizers = (playlistId?: string) => {
  const activePlaylist = useActivePlaylist(playlistId || "");
  const advizerIds = activePlaylist?.advizers
    ? Object.keys(activePlaylist?.advizers || {})
    : Object.values(activePlaylist?.steps || {})
        .map((step: any) => step.stepParams?.advizerId)
        .filter(Boolean);

  const advizerMap = useAdvizerMap();
  const advizers = advizerIds
    .map((id) => advizerMap[id])
    .filter((advizer) => advizer !== undefined);

  return advizers;
};

export const useActivePlaylistVideos = (playlistId?: string) => {
  const activePlaylistId = useActivePlaylistId();
  const activePlaylist = useActivePlaylist(
    playlistId || activePlaylistId || undefined,
  );
  const advizers = useActivePlaylistAdvizers(
    playlistId || activePlaylistId || undefined,
  );

  const videos: any[] = [];

  advizers.forEach((advizer) => {
    const matchingStep: any = Object.values(activePlaylist?.steps || {}).find(
      (step: any) => step.stepParams?.advizerId === advizer.id,
    );

    if (matchingStep) {
      const video = advizer.videos.find(
        (v: any) =>
          v.questionNumber === matchingStep.stepParams?.videoQuestionNumber,
      );
      if (video) {
        videos.push(video);
      }
    } else {
      const video = advizer.videos.find(
        (v: any) => v.questionNumber === activePlaylist?.uniqId?.questionNumber,
      );
      if (video) {
        videos.push(video);
      }
    }
  });

  return videos;
};

export const useActivePlaylistDescriptors = (playlistId?: string) => {
  const activePlaylistVideos = useActivePlaylistVideos(playlistId || "");
  const descriptorMap = useApiDescriptorsVideoMap();

  const allDescriptors = activePlaylistVideos.flatMap((video) => {
    return descriptorMap[video.id] || [];
  });

  // get unique list of descriptors
  const uniqueDescriptors = allDescriptors.filter(
    (descriptor, index, self) =>
      self.findIndex((t) => t.text === descriptor.text) === index,
  );

  return uniqueDescriptors;
  // return activePlaylist?.descriptors || [];
};

export const usePlaylistTags = (playlistId?: string) => {
  const activePlaylist = useActivePlaylist(playlistId || "");
  const uniqId = activePlaylist?.uniqId || {};
  const industries = useStore((state) => state.advizeApiData.industries);
  const jobFunctions = useStore((state) => state.advizeApiData.jobFunctions);
  const subjects = useStore((state) => state.advizeApiData.subjects);
  const traits = useStore((state) => state.advizeApiData.traits);
  const questions = useStore((state) => state.advizeApiData.questions);
  const tags: IAdvizerTag[] = [];

  if (uniqId?.questionNumber) {
    const questionTitle = questions?.find(
      (q: any) => q.number === uniqId.questionNumber,
    )?.title;

    if (questionTitle) {
      tags.push({
        id: String(uniqId.questionNumber),
        title: `${questionTitle}`,
        type: "question",
      });
    }
  }

  uniqId.industries?.forEach((industry: any) => {
    const industryOption = industries?.find((i: any) => i.slug === industry);
    if (industryOption) {
      tags.push({
        id: industryOption.id,
        title: industryOption.title,
        type: "industry",
      });
    }
  });

  uniqId.jobFunctions?.forEach((jobFunction: any) => {
    const jobFunctionOption = jobFunctions?.find(
      (j: any) => j.slug === jobFunction,
    );
    if (jobFunctionOption) {
      tags.push({
        id: jobFunctionOption.id,
        title: jobFunctionOption.title,
        type: "jobFunction",
      });
    }
  });

  uniqId.traits?.forEach((trait: any) => {
    const traitOption = traits?.find((t: any) => t.slug === trait);
    if (traitOption) {
      tags.push({
        id: traitOption.id,
        title: traitOption.title,
        type: "trait",
      });
    }
  });

  uniqId.subjects?.forEach((subject: any) => {
    const subjectOption = subjects?.find((s: any) => s.slug === subject);
    if (subjectOption) {
      tags.push({
        id: subjectOption.id,
        title: subjectOption.title,
        type: "subject",
      });
    }
  });

  return tags;
  // return activePlaylist?.tags || [];
};
