import "@mantine/core/styles.css";
import { AppShell, Group, Burger, Skeleton, Box } from "@mantine/core";
import logo from "@/assets/advize-logo.webp";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import {
  MainMenuDrawer,
  drawerId as mainMenuDrawerId,
} from "@/components/drawers/MainMenuDrawer";
import { UserDrawer } from "@/components/drawers/UserDrawer";
import {
  useDrawer,
  useIsLoggedIn,
  useActiveUserPartner,
  useUIFlags,
  useIsPartnersLoading,
  useIsUserProfile,
  // useDoesUserHaveAnyPartners,
} from "@/store/selectors";
import { useStore } from "@/hooks";
import { Link } from "@/components/ui/Link";
import { NavLink } from "@/components/ui/NavLink";
import { PartnerLogo } from "@/components/PartnerLogo";
import { Button } from "@/components/ui/Button";
import { Analytics } from "@/services/Analytics";
import { DialogContainer } from "@/components/dialogs/DialogContainer";
import { Confetti } from "@/components/Confetti";
import { Image } from "@/components/ui/Image";
import { Text } from "@/components/ui/Text";
import { LoadingScreen } from "@/components/templates/LoadingScreen";
import { Banner } from "@/components/Banner";
import { useNavItems } from "@/store/selectors/uiSelectors";
import { IconHome, IconPhotoPlus, IconUser } from "@tabler/icons-react";
import { useActivePlaylistId } from "@/store/selectors/playlistSelectors";
import {
  useIsDemoApp,
  useIsPartnerApp,
} from "@/store/selectors/featureSelectors";
import { Login } from "./Login";
import { Page404 } from "./404";
import { DemoBar } from "@/components/DemoBar";
import { Navigation } from "@/services/Navigation";

export function PageLayout({
  children,
  isPublic = false,
  requiresPartner = false,
}: {
  children: React.ReactNode;
  requiresPartner?: boolean;
  isPublic?: boolean;
}) {
  const { theme, ...styles } = useStyles();
  const { pathname } = useLocation();
  const showMainNavbar = useStore((state) => state.showMainNavbar);
  // const navigate = useNavigate();
  const {
    openDrawer: openMainMenuDrawer,
    isOpen: isMainMenuDrawerOpen,
    closeDrawer,
  } = useDrawer(mainMenuDrawerId);
  const partner = useActiveUserPartner();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isLoggedIn = useIsLoggedIn();
  const isUserProfile = useIsUserProfile();
  const activePlaylistId = useActivePlaylistId();
  const isPartnerApp = useIsPartnerApp();
  const isDemoApp = useIsDemoApp();
  const setIsLoggingInDuringPlaylist = useStore(
    (state) => state.playlists.setIsLoggingInDuringPlaylist,
  );
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const resetAdvizerSearch = useStore(
    (state) => state.advizerSearch.resetAdvizerSearch,
  );
  const isAppLoading = useStore((state) => state.isAppLoading);
  const isPartnersLoading = useIsPartnersLoading();
  const { highlightSignInButton } = useUIFlags();
  const isLoginPage = useMemo(() => {
    return pathname.includes("login");
  }, [pathname]);
  const NAV_ITEMS = useNavItems();
  // const doesUserHaveAnyPartners = useDoesUserHaveAnyPartners();

  const scrollOverflow = useMemo(() => {
    return pathname.includes("login");
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  // useEffect(() => {
  //   if (requiresPartner && !doesUserHaveAnyPartners) {
  //     navigate("/login/partners");
  //   }
  // }, [requiresPartner, doesUserHaveAnyPartners]);

  const renderBurgerButton = useCallback(() => {
    return (
      <Burger
        opened={isMainMenuDrawerOpen}
        onClick={isMainMenuDrawerOpen ? closeDrawer : openMainMenuDrawer}
        color="border.3"
        size={isLaptopOrBigger ? "md" : "sm"}
        lineSize={1}
        aria-label="Open main menu"
      />
    );
  }, [isMainMenuDrawerOpen, closeDrawer, openMainMenuDrawer, isLaptopOrBigger]);

  const renderMainTitle = useCallback(() => {
    if (isDemoApp) {
      return (
        <Group style={{ display: "flex", flex: "1 1 0%" }}>
          {partner ? (
            <Group align="center" justify="center" pt={4} flex={1}>
              <PartnerLogo width={34} partnerId={partner?.id} noLink />
              <Text
                titleStyle={isLaptopOrBigger ? "h4" : "h5"}
                c="white"
                lineClamp={1}
                maw="100%"
                flex={1}
              >
                {partner.title}
              </Text>
            </Group>
          ) : (
            <Group gap={2}>
              {/* <Image
                src={logo}
                w={isLaptopOrBigger ? 34 : 26}
                mb={2}
                alt="Advize logo"
                mr={4}
              /> */}
              <Group
                align="center"
                justify="center"
                gap={4}
                pos="relative"
                onClick={() => {
                  Navigation.navigate("/");
                }}
                // bg={theme.colors.primary[9]}
              >
                <Box
                  w={36}
                  h={36}
                  mr={12}
                  // bg={theme.colors.secondary[0]}
                  style={{
                    // border: `1px solid ${theme.colors.secondary[9]}`,
                    borderRadius: 6,
                  }}
                />
                <IconPhotoPlus
                  size={36}
                  style={{
                    position: "absolute",
                    left: 0,
                    // top: 6,
                    // left: 6,
                    zIndex: 100,
                    color: theme.colors.secondary[8],
                  }}
                />
              </Group>
              <Text
                titleStyle={isLaptopOrBigger ? "h4" : "h5"}
                c="white"
                onClick={(e) => {
                  if (isDemoApp) {
                    e.currentTarget.contentEditable = "true";
                  }
                }}
              >
                {isDemoApp ? "Your organization name here..." : "advize"}
              </Text>
            </Group>
          )}
        </Group>
      );
    }

    if (isLaptopOrBigger) {
      return (
        <Link
          to={"/"}
          onClick={() => {
            resetAdvizerSearch();
            if (pathname == "/") {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          }}
          style={{ display: "flex", flex: "1 1 0%" }}
        >
          {partner ? (
            <Group align="center" justify="center" pt={4} flex={1}>
              <PartnerLogo width={34} partnerId={partner?.id} noLink />
              <Text
                titleStyle={isLaptopOrBigger ? "h4" : "h5"}
                c="white"
                lineClamp={1}
                maw="100%"
                flex={1}
              >
                {partner.title}
              </Text>
            </Group>
          ) : (
            <Group gap={2}>
              <Image
                src={logo}
                w={isLaptopOrBigger ? 34 : 26}
                mb={2}
                alt="Advize logo"
              />
              <Text titleStyle={isLaptopOrBigger ? "h2" : "h3"} c="white">
                advize
              </Text>
            </Group>
          )}
        </Link>
      );
    }
    return (
      <Link
        to={"/"}
        onClick={() => {
          resetAdvizerSearch();
          if (pathname == "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
        style={{ display: "flex", flex: 1 }}
      >
        {partner ? (
          <Group align="center" justify="center" pt={4} flex={1}>
            {/* <PartnerLogo width={24} /> */}
            <Text titleStyle="h5" c="white" lineClamp={1} maw="100%" flex={1}>
              {partner.title}
            </Text>
          </Group>
        ) : (
          <Group gap={2}>
            <Image src={logo} w={26} mb={2} alt="Advize logo" />
            <Text titleStyle="h3" c="white">
              advize
            </Text>
          </Group>
        )}
      </Link>
    );
  }, [
    isDemoApp,
    isLaptopOrBigger,
    partner,
    theme.colors.secondary,
    resetAdvizerSearch,
    pathname,
  ]);

  const renderAvatar = useCallback(() => {
    if (!isLoggedIn) {
      return (
        <Button
          variant="filled"
          onClick={(event) => {
            // openUserDrawer();
            Analytics.click({ action: "Go to sign in from header", event });
            if (activePlaylistId) {
              setIsLoggingInDuringPlaylist(true);
            }
          }}
          size={isLaptopOrBigger ? "sm" : "xs"}
          className={highlightSignInButton ? "pulseBorder" : ""}
          // bg="secondary.9"
          color="secondary.7"
          radius="xl"
          to="/login"
          {...partner?.styles?.signInButtonProps}
        >
          Sign in
        </Button>
      );
    }

    if (isUserProfile && !isLaptopOrBigger) {
      return (
        <Button
          variant="light"
          onClick={(event) => {
            // openUserDrawer();
            Analytics.click({ action: "Go to my home from my profile", event });
          }}
          size={isLaptopOrBigger ? "sm" : "xs"}
          className={highlightSignInButton ? "pulseBorder" : ""}
          leftSection={<IconHome size={16} />}
          // bg="secondary.9"
          color="primary.0"
          radius="xl"
          to="/"
          {...partner?.styles?.signInButtonProps}
        >
          Go home
        </Button>
      );
    }
    return (
      <Button
        variant="light"
        onClick={(event) => {
          // openUserDrawer();
          if (isPartnerApp) {
            Analytics.click({
              action: "Go to my dashboard from header",
              event,
            });
          } else {
            Analytics.click({ action: "Go to my profile from header", event });
          }
        }}
        size={isLaptopOrBigger ? "sm" : "xs"}
        className={highlightSignInButton ? "pulseBorder" : ""}
        leftSection={
          isPartnerApp ? <IconHome size={16} /> : <IconUser size={16} />
        }
        // bg="secondary.9"
        color="primary.0"
        radius="xl"
        to={isPartnerApp ? "/" : "/profile"}
        {...partner?.styles?.signInButtonProps}
      >
        {isPartnerApp ? "Dashboard" : "My Profile"}
      </Button>
    );
  }, [
    activePlaylistId,
    highlightSignInButton,
    isLaptopOrBigger,
    isLoggedIn,
    isUserProfile,
    partner?.styles?.signInButtonProps,
    setIsLoggingInDuringPlaylist,
    isPartnerApp,
  ]);

  const renderHeaderContent = useCallback(() => {
    if (isAppLoading) {
      return (
        <Group>
          <Skeleton
            w="100%"
            h={styles.dimensions.headerHeight}
            radius={0}
            opacity={1}
          />
        </Group>
      );
    }

    if (isLaptopOrBigger) {
      return (
        <Group
          h="100%"
          w="100%"
          px="md"
          justify="space-between"
          style={{ flex: 1, position: "relative" }}
        >
          <Group w="100%" flex={1}>
            {renderBurgerButton()}
            {renderMainTitle()}
          </Group>

          <Group pl="xl" gap="md" justify="flex-end" style={{ flex: 1 }}>
            {NAV_ITEMS.filter(
              (navItem) => !!navItem?.showOnDesktopNavbar?.({ isPartnerApp }),
            ).map((navItem, index) => {
              return (
                <NavLink
                  key={index}
                  to={navItem.href}
                  textProps={{ c: theme.colors.text[2] }}
                  linkProps={{ target: navItem.target }}
                  active={false}
                  // isNew={navItem.isNew}
                  onClick={(event) => {
                    Analytics.click({
                      action: `Nav Menu > ${navItem.label}`,
                      event,
                    });
                  }}
                >
                  {navItem.label}
                </NavLink>
              );
            })}
            {/* <Space w={4} /> */}
            {renderAvatar()}
          </Group>
        </Group>
      );
    }

    return (
      <Group h="100%" px="md" maw="100%" justify="space-between">
        {renderBurgerButton()}
        {renderMainTitle()}
        {renderAvatar()}
      </Group>
    );
  }, [
    NAV_ITEMS,
    isAppLoading,
    isLaptopOrBigger,
    isPartnerApp,
    renderAvatar,
    renderBurgerButton,
    renderMainTitle,
    styles.dimensions.headerHeight,
    theme.colors.text,
  ]);

  const childrenToRender = useMemo(() => {
    if (isDemoApp) {
      return children;
    }

    if (isPublic) {
      return children;
    }

    if (!isLoggedIn) {
      return <Login />;
    }

    if (requiresPartner && !partner && !isPartnersLoading) {
      return <Page404 />;
    }

    return children;
  }, [
    isDemoApp,
    isPublic,
    isLoggedIn,
    requiresPartner,
    partner,
    isPartnersLoading,
    children,
  ]);

  return (
    <AppShell
      header={{
        height: styles.dimensions.headerHeight,
        collapsed: collapseHeader || !showMainNavbar,
        offset: false,
      }}
      padding="md"
      style={{
        position: "relative",
      }}
      styles={{
        root: {
          width: "100%",
        },
      }}
    >
      <AppShell.Header bg="primary.9">{renderHeaderContent()}</AppShell.Header>

      <AppShell.Main
        bg={theme.colors.background[0]}
        p={0}
        h="100vh"
        style={{ overflow: scrollOverflow ? "auto" : "hidden" }}
      >
        {isAppLoading || (isPartnersLoading && isLoginPage) ? (
          <LoadingScreen />
        ) : (
          childrenToRender
        )}
        <Confetti />
        <Banner />
      </AppShell.Main>

      <MainMenuDrawer />
      <UserDrawer />
      <DialogContainer />
      <DemoBar />
    </AppShell>
  );
}
