import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { lighten, Table as MantineTable } from "@mantine/core";
import { Text } from "../ui/Text";
import { useStyles } from "@/styles/useStyles";

export function Table({
  data,
  columns,
  minWidth,
  activeRowId,
}: {
  data: any[];
  columns: any[];
  minWidth: number;
  activeRowId?: string;
}) {
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const { theme } = useStyles();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // initialState: {
    //   sorting: [
    //     {
    //       id: "sortRanking",
    //       desc: true, // sort by name in descending order by default
    //     },
    //   ],
    // },
    // debugTable: true,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLTableRowElement>({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <div
      ref={tableContainerRef}
      style={{
        overflow: "auto", //our scrollable table container
        position: "relative", //needed for sticky header
        height: "1000px", //should be a fixed height
        width: "100%",
        minWidth: minWidth,
      }}
    >
      <MantineTable style={{ display: "grid", width: "100%" }}>
        <MantineTable.Thead
          bg="background.1"
          style={{
            display: "grid",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <MantineTable.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  header={header}
                  style={{ width: header.getSize() }}
                />
              ))}
            </MantineTable.Tr>
          ))}
        </MantineTable.Thead>
        <MantineTable.Tbody
          style={{
            display: "grid",
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: "relative", //needed for absolute positioning of rows
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<any>;
            const isActive = row.original.id === activeRowId;

            return (
              <MantineTable.Tr
                key={row.id}
                bg={
                  isActive
                    ? lighten(theme.colors.primary[1], 0.75)
                    : row.index % 2 !== 0
                      ? lighten(theme.colors.primary[0], 1)
                      : "background.0"
                }
                data-index={virtualRow.index}
                ref={(node) => rowVirtualizer.measureElement(node)} //measure
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: "100%",
                  borderTop: isActive
                    ? `1px solid ${theme.colors.primary[2]}`
                    : "none",
                  borderBottom: isActive
                    ? `1px solid ${theme.colors.primary[2]}`
                    : "none",
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <MantineTable.Td
                    key={cell.id}
                    style={{
                      display: "flex",
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </MantineTable.Td>
                ))}
              </MantineTable.Tr>
            );
          })}
        </MantineTable.Tbody>
      </MantineTable>
    </div>
  );
}

function TableHeader({ header, style }: { header: any; style: any }) {
  return (
    <MantineTable.Th flex={1} style={style}>
      <Text titleStyle="h6">
        {flexRender(header.column.columnDef.header, header.getContext())}
      </Text>
    </MantineTable.Th>
  );
}
