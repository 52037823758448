import {
  useActivePlaylistId,
  useActivePlaylistStepKey,
  usePlaylistVideo,
} from "@/store/selectors/playlistSelectors";
import { AdvizerVideo } from "../AdvizerVideo";
import { Group, Space, Stack } from "@mantine/core";
import { useAdvizer, useAdvizerQuotes } from "@/store/selectors";
import { useMemo } from "react";
import { AdvizerProfileCard } from "@/pages/AdvizerProfilePage";
import { AdvizerQuotes } from "../AdvizerQuotes";
import { PlaylistService } from "@/services/PlaylistService";
import ReactPlayer from "react-player";
import { useIsPartnerApp } from "@/store/selectors/featureSelectors";

export const PlaylistVideo = ({
  advizerId,
  videoQuestionNumber,
  minimal = false,
}: {
  advizerId: string;
  videoQuestionNumber: number;
  minimal?: boolean;
}) => {
  const video = usePlaylistVideo({ advizerId, videoQuestionNumber });
  const advizer = useAdvizer(advizerId);
  const quotes = useAdvizerQuotes(advizerId || "");
  const activePlaylistId = useActivePlaylistId();
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const isPartnerApp = useIsPartnerApp();

  const matchingQuote = useMemo(() => {
    return quotes.find((quote) => quote.videoId === video?.id);
  }, [quotes, video?.id]);

  if (!video) return null;

  return (
    <Stack gap={0} pt={0}>
      <Group
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          overflow: "hidden",
        }}
        p={0}
        gap={0}
      >
        <AdvizerVideo
          isPlaylistVideo
          videoId={video.id}
          video={video}
          title={video.title}
          url={video.url}
          questionNumber={video.questionNumber}
          light={false}
          onPercentProgress={(percentProgress: number) => {
            if (isPartnerApp) return;
            if (!activePlaylistId || !activePlaylistStepKey) return;
            PlaylistService.updateUserPlaylistVideoStepData({
              playlistId: activePlaylistId,
              stepKey: activePlaylistStepKey,
              data: { percentProgress },
            });
          }}
          onProgressEvery5Seconds={() => {
            if (isPartnerApp) return;
            if (!activePlaylistId || !activePlaylistStepKey) return;
            PlaylistService.updateUserPlaylistVideoStepData({
              playlistId: activePlaylistId,
              stepKey: activePlaylistStepKey,
              data: { secondsToAdd: 5 },
            });
            // console.log("secondsWatched", secondsWatched);
          }}
        />
        {advizer && !minimal ? (
          <AdvizerQuotes advizer={advizer} activeQuote={matchingQuote} />
        ) : (
          <Space h="xs" />
        )}
      </Group>

      {minimal ? <Space h="xs" /> : <Space h="lg" />}

      <AdvizerProfileCard
        advizerId={advizerId}
        linkToProfilePage
        disableTags={true}
      />
    </Stack>
  );
};

export const PlaylistVideoPreloader = ({ url }: { url: string }) => {
  const videoUrl = url;

  // useEffect(() => {
  //   if (!videoUrl) return;

  //   // Create a link element for preloading
  //   // const linkElement = document.createElement("link");
  //   // linkElement.rel = "preload";
  //   // linkElement.as = "video"; // Uncomment and specify the correct resource type
  //   // linkElement.href = videoUrl;
  //   // linkElement.type = "video/mp4"; // Add MIME type for better browser handling
  //   // linkElement.crossOrigin = "anonymous"; // Add if you're using a CDN

  //   // console.log("preloading video", videoUrl);

  //   // // Add the link element to document head
  //   // document.head.appendChild(linkElement);

  //   const videoElement = document.createElement("video");
  //   videoElement.style.display = "none"; // Hide the video element
  //   videoElement.preload = "auto"; // Force preloading
  //   videoElement.src = videoUrl;
  //   videoElement.load(); // Explicitly tell the browser to load the video

  //   console.log("preloading video", videoUrl);

  //   document.body.appendChild(videoElement);

  //   // Cleanup function
  //   return () => {
  //     document.head.removeChild(videoElement);
  //   };
  // }, [videoUrl]);

  return (
    <ReactPlayer
      url={videoUrl}
      style={{ display: "none" }}
      config={{
        file: {
          attributes: {
            preload: "auto", // or 'metadata' or 'none'
          },
        },
      }}
    />
  );
};
