import { IStoreState } from "@/types/StoreTypes";
import { UpdateConfig } from "..";

export function createPartnerAppSlice(params: {
  localStorageState: IStoreState;
  setAndProduce: (
    fn: (state: IStoreState, config?: UpdateConfig) => void,
    config?: UpdateConfig,
  ) => void;
}) {
  const { setAndProduce, localStorageState } = params;

  return {
    activePlaylistId: null,
    setActivePlaylistId: (playlistId: string | null) =>
      setAndProduce((state) => {
        state.partnerApp.activePlaylistId = playlistId;
      }),

    fullActiveAssignment: null,
    setFullActiveAssignment: (assignment: any) =>
      setAndProduce((state) => {
        state.partnerApp.fullActiveAssignment = assignment;
      }),
    assignments: {},
    setAssignments: (assignments: Record<string, any>) =>
      setAndProduce((state) => {
        state.partnerApp.assignments = assignments;
      }),
    activeAssignmentId: null,
    setActiveAssignmentId: (assignmentId: string | null) =>
      setAndProduce((state) => {
        state.partnerApp.activeAssignmentId = assignmentId;
      }),

    isAssignmentManagerOpen:
      typeof localStorageState.partnerApp?.isAssignmentManagerOpen === "boolean"
        ? localStorageState.partnerApp?.isAssignmentManagerOpen
        : true,
    setIsAssignmentManagerOpen: (isOpen: boolean) =>
      setAndProduce(
        (state) => {
          state.partnerApp.isAssignmentManagerOpen = isOpen;
        },
        { saveLocal: true },
      ),
    // assignmentEditorState: {
    //   name: localStorageState.partnerApp?.assignmentEditorState?.name || "",
    //   instructions:
    //     localStorageState.partnerApp?.assignmentEditorState?.instructions || "",
    //   updateEditorState: ({ prop, value }: { prop: string; value: string }) =>
    //     setAndProduce(
    //       (state) => {
    //         console.log({ prop, value, state });
    //         state.partnerApp.assignmentEditorState[prop] = value;
    //       },
    //       { saveLocal: true },
    //     ),
    // },
  };
}
