import { Avatar, Group, Space, Stack } from "@mantine/core";
import { useActiveUser } from "@/store/selectors";
import { useIsTabletOrBigger, useStyles } from "@/styles/useStyles";
import { Button } from "@/components/ui/Button";
import { IconBrain, IconClock, IconHistory } from "@tabler/icons-react";
import { Analytics } from "@/services/Analytics";
import { Firebase } from "@/firebase/firebase";
import { Text } from "@/components/ui/Text";
import { useEffect, useMemo, useState } from "react";
import {
  useUserAdvizersWatched,
  useUserCompletedPlaylistKeys,
  useUserInProgressPlaylistKeys,
  useUserRecommendedPlaylists,
  useUserVideosWatched,
} from "@/store/selectors/userProfileSelectors";
import { PlaylistCard } from "@/components/playlists/PlaylistCard";
import { Select } from "@/components/ui/Select";
import { Chip } from "@/components/ui/Chip";
import { AdvizerVideoCard } from "@/components/AdvizerVideoCard";
import { LoadingScreen } from "@/components/templates/LoadingScreen";
import { Navigation } from "@/services/Navigation";
import { useUserCanAccessPlaylists } from "@/store/selectors/playlistSelectors";

export function UserProfile() {
  const { theme, ...styles } = useStyles();
  const activeUser = useActiveUser();
  const isTabletOrBigger = useIsTabletOrBigger();
  const userVideosWatched = useUserVideosWatched();
  const userRecommendedPlaylists = useUserRecommendedPlaylists();
  const completedPlaylistKeys = useUserCompletedPlaylistKeys();
  const inProgressPlaylistKeys = useUserInProgressPlaylistKeys();
  const userAdvizersWatched = useUserAdvizersWatched();
  const hasRecommendedPlaylists =
    Object.keys(userRecommendedPlaylists).length > 0;
  const hasCompletedPlaylists = completedPlaylistKeys.length > 0;
  const hasInProgressPlaylists = inProgressPlaylistKeys.length > 0;
  const hasWatchedVideos = userVideosWatched.length > 0;
  const hasAdvizersWatched = userAdvizersWatched.length > 0;
  const userCanAccessPlaylists = useUserCanAccessPlaylists();
  const [activeTab, setActiveTab] = useState<string>(
    userCanAccessPlaylists ? "recommendations" : "videos",
  );

  // const UserProfileInfo = useMemo(() => {

  useEffect(() => {
    setTimeout(() => {
      if (!activeUser) {
        Navigation.navigate("/login");
      }
    }, 3000);
  }, [activeUser]);
  //   return (
  //     <Stack>
  //       <Text titleStyle="h4">{activeUser?.email}</Text>
  //     </Stack>
  //   );
  // }, [activeUser]);

  // const WatchedVideos = useMemo(() => {
  //   // return (
  // }, []);

  const playlistContentPadding = "xs";

  const LatestRecommendations = useMemo(() => {
    if (!userCanAccessPlaylists) return null;
    if (!hasRecommendedPlaylists) {
      return (
        <Stack p="md" py={0} align="center" pt="xl">
          <Text titleStyle="h4">You don't have any recommendations yet.</Text>
          <Button
            variant="light"
            size="sm"
            to="/?step=select-subjects"
            color="primary"
            radius="xl"
            px="xl"
          >
            Get recommendations
          </Button>
        </Stack>
      );
    }

    return (
      <Stack p={playlistContentPadding} py={0} align="center">
        {Object.keys(userRecommendedPlaylists).map((playlistId) => (
          <PlaylistCard key={playlistId} playlistId={playlistId} />
        ))}
      </Stack>
    );
  }, [
    hasRecommendedPlaylists,
    userCanAccessPlaylists,
    userRecommendedPlaylists,
  ]);

  const VideosWatched = useMemo(() => {
    if (!hasWatchedVideos) {
      return (
        <Stack p="md" py={0} align="center" pt="xl">
          <Text titleStyle="h4">You haven't watched any videos yet.</Text>
          <Button
            variant="light"
            size="sm"
            to="/explore"
            color="primary"
            radius="xl"
            px="xl"
          >
            Browse Advizers
          </Button>
        </Stack>
      );
    }

    return (
      <Stack p={playlistContentPadding} py={0} align="center">
        {userVideosWatched.map((video) => (
          <AdvizerVideoCard
            key={video.id}
            advizerId={video.advizerId}
            simple={isTabletOrBigger}
            questionNumber={video.questionNumber}
            disableTags
          />
        ))}
      </Stack>
    );
  }, [hasWatchedVideos, isTabletOrBigger, userVideosWatched]);

  const PlaylistsStarted = useMemo(() => {
    if (!hasInProgressPlaylists) {
      return (
        <Stack p="md" py={0} align="center" pt="xl">
          <Text titleStyle="h4">No playlists to show right now.</Text>
          <Button
            variant="light"
            size="sm"
            to="/?step=select-subjects"
            color="primary"
            radius="xl"
            px="xl"
          >
            Get recommendations
          </Button>
        </Stack>
      );
    }

    return (
      <Stack p={playlistContentPadding} py={0} align="center">
        {inProgressPlaylistKeys.map((playlistId) => (
          <PlaylistCard key={playlistId} playlistId={playlistId} />
        ))}
      </Stack>
    );
  }, [hasInProgressPlaylists, inProgressPlaylistKeys]);

  const PlaylistsCompleted = useMemo(() => {
    if (!hasCompletedPlaylists) {
      return (
        <Stack p="md" py={0} align="center" pt="xl">
          <Text titleStyle="h4">You haven't completed any playlists yet.</Text>
          <Button
            variant="light"
            size="sm"
            to="/?step=select-subjects"
            color="primary"
            radius="xl"
            px="xl"
          >
            Get recommendations
          </Button>
        </Stack>
      );
    }

    return (
      <Stack p={playlistContentPadding} py={0} align="center">
        {completedPlaylistKeys.map((playlistId) => (
          <PlaylistCard key={playlistId} playlistId={playlistId} />
        ))}
      </Stack>
    );
  }, [hasCompletedPlaylists, completedPlaylistKeys]);

  const AdvizersWatched = useMemo(() => {
    if (!hasAdvizersWatched) {
      return (
        <Stack p="md" py={0} align="center" pt="xl">
          <Text titleStyle="h4">
            You haven't watched any advizer videos yet.
          </Text>
          <Button
            variant="light"
            size="sm"
            to="/explore"
            color="primary"
            radius="xl"
            px="xl"
          >
            Browse Advizers
          </Button>
        </Stack>
      );
    }

    return (
      <Stack p={playlistContentPadding} py={0} align="center">
        {userAdvizersWatched.map((advizer) => (
          <AdvizerVideoCard
            key={advizer.id}
            advizerId={advizer.id}
            simple={isTabletOrBigger}
            hideVideo
          />
        ))}
      </Stack>
    );
  }, [hasAdvizersWatched, isTabletOrBigger, userAdvizersWatched]);

  const TabsOptions = useMemo(() => {
    const tabs = [
      {
        value: "playlistsStarted",
        leftSection: <IconClock size={16} />,
        label: "Playlists in progress",
        hide: !userCanAccessPlaylists,
      },
      {
        value: "recommendations",
        leftSection: <IconBrain size={16} />,
        label: "Latest recommendations",
        hide: !userCanAccessPlaylists,
      },
      {
        value: "playlistsCompleted",
        leftSection: <IconClock size={16} />,
        label: "Playlists completed",
        hide: !userCanAccessPlaylists,
      },
      {
        value: "videos",
        leftSection: <IconHistory size={16} />,
        label: "Videos watched",
      },
      {
        value: "advizers",
        leftSection: <IconClock size={16} />,
        label: "Advizers watched",
      },
    ];

    if (isTabletOrBigger) {
      return (
        <Group p="lg" justify="flex-start" gap="sm">
          {tabs
            .filter((tab) => !tab.hide)
            .map((tab) => (
              <Chip
                key={tab.value}
                variant="outline"
                color="primary"
                size="md"
                ariaLabel={tab.label}
                checked={activeTab === tab.value}
                // fw={activeTab === tab.value ? "bold" : "normal"}
                onClick={() => {
                  setActiveTab(tab.value as any);
                  Analytics.click({
                    context: "User profile history",
                    action: "Filter history",
                    label: tab.label,
                  });
                }}
                styles={{
                  label: {
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                }}
              >
                {tab.label}
              </Chip>
            ))}
        </Group>
      );
    }

    return (
      <Group w="100%" p="sm">
        <Select
          value={activeTab}
          onChange={(value) => {
            setActiveTab(value as any);
          }}
          w="100%"
          maw={400}
          data={tabs
            .filter((tab) => !tab.hide)
            .map((tab) => ({
              value: tab.value,
              label: tab.label,
            }))}
        />
      </Group>
    );
  }, [activeTab, isTabletOrBigger, userCanAccessPlaylists]);

  const MainContent = useMemo(() => {
    switch (activeTab) {
      case "playlistsStarted":
        return PlaylistsStarted;
      case "playlistsCompleted":
        return PlaylistsCompleted;
      case "recommendations":
        return LatestRecommendations;
      case "videos":
        return VideosWatched;
      case "advizers":
        return AdvizersWatched;
      default:
        return null;
    }
  }, [
    LatestRecommendations,
    PlaylistsCompleted,
    PlaylistsStarted,
    VideosWatched,
    AdvizersWatched,
    activeTab,
  ]);

  if (!activeUser) {
    return <LoadingScreen />;
  }

  return (
    <Stack
      pt={styles.dimensions.headerHeight + (isTabletOrBigger ? 0 : 0)}
      pb={200}
      px={isTabletOrBigger ? "xl" : 0}
      h="100%"
      mih="100vh"
      w="100%"
      bg={theme.colors.background[1]}
      align="center"
      style={{
        overflowY: "auto",
        // border: `1px solid ${theme.colors.border[4]}`,
      }}
    >
      <Stack
        gap={0}
        maw={700}
        px={0}
        w="100%"
        style={{
          borderRadius: 6,
          // border: `1px solid ${theme.colors.border[2]}`,
        }}
      >
        <Stack gap={0} w="100%">
          <Group
            gap="sm"
            p="md"
            style={{ borderRadius: 6 }}
            align="center"
            justify="space-between"
          >
            <Group justify="flex-start" gap="xs" flex={1} wrap="nowrap">
              <Avatar color="primary.7" radius="xl" size={36} />
              <Text titleStyle="h5" lineClamp={1} c="text.8">
                {activeUser?.email || "Demo User"}
              </Text>
            </Group>
            <Button
              size="xs"
              opacity={0.8}
              miw="max-content"
              variant="transparent"
              ariaLabel="logout"
              onClick={() => {
                Analytics.logout();
                Firebase.logout();
                Navigation.navigate("/");
              }}
            >
              Logout
            </Button>
          </Group>
        </Stack>

        <Space h="xs" />
        <Space h="md" />

        <Group
          py={0}
          justify="flex-start"
          align="flex-start"
          gap="xs"
          pos="sticky"
          top={0}
          style={{ zIndex: 100 }}
          bg="background.1"
        >
          {TabsOptions}
        </Group>

        <Stack py="xs" pb={100} px={0}>
          {MainContent}
        </Stack>
      </Stack>
    </Stack>
  );
}
