import { Divider, Space, Stack } from "@mantine/core";
import { PlaylistRadioButtons } from "./PlaylistRadioButtons";
import {
  IconArrowRight,
  IconPlaylist,
  IconRocket,
  IconUserCog,
} from "@tabler/icons-react";
import { Title } from "../ui/Title";
import { AdvizerProfile } from "@/pages/AdvizerProfilePage";
import { useIsLoggedIn, useRandomAdvizer } from "@/store/selectors";
import { Navigation } from "@/services/Navigation";
import { useGoToNextStepInPlaylist } from "@/store/selectors/playlistSelectors";
import { useMemo } from "react";
import { Text } from "../ui/Text";
import { Button } from "../ui/Button";
import { useIsDemoApp } from "@/store/selectors/featureSelectors";

export const PlaylistInitialOptions = () => {
  const randomAdvizer = useRandomAdvizer();
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();
  const isLoggedIn = useIsLoggedIn();
  const isDemoApp = useIsDemoApp();
  // ... existing code ...

  const PlaylistMainOptions = useMemo(() => {
    if (!isLoggedIn && !isDemoApp) {
      return (
        <Button
          // variant="outline"
          size="lg"
          radius="xl"
          onClick={() => {
            Navigation.navigate("/explore");
          }}
          rightSection={<IconArrowRight />}
          px="xl"
        >
          Explore career interviews
        </Button>
      );
    }
    return (
      <PlaylistRadioButtons
        options={[
          {
            label:
              "Get personalized recommendations that match your career goals",
            // subLabel:
            //   "Get recommended interview videos that match your career goals",
            value: "getRecommendedPlaylists",
            Icon: IconRocket,
            color: "primary",
            onClick: () => {
              goToNextStepInPlaylist();
            },
          },
          {
            label:
              "Browse short video playlists that give you key insights about specific topics",
            // subLabel:
            //   "Browse bite-sized video playlists that give you key insights about specific topics",
            value: "browseAllPlaylists",
            Icon: IconPlaylist,
            color: "violet",
            onClick: () => {
              Navigation.navigate("/playlists");
            },
          },
          {
            label:
              "Explore Advizer profiles to find the most helpful interviews for you",
            // subLabel:
            //   "Explore Advizer profiles to find the most helpful interviews for you",
            value: "exploreProfessionals",
            Icon: IconUserCog,
            color: "orange",
            onClick: () => {
              Navigation.navigate("/explore");
            },
          },
        ]}
        selectedValue={null}
      />
    );
  }, [goToNextStepInPlaylist, isLoggedIn]);

  return (
    <Stack w="100%" align="center" p={0}>
      <Stack maw={500} align="center">
        {PlaylistMainOptions}
      </Stack>

      <Space h="xl" />
      <Space h="xl" />
      <Divider w="100%" />

      <Space h="xl" />
      <Title order={2} ta="center" maw={600}>
        Every "Advizer" answers the same 12 questions about their daily work
        life, critical skills, entry level job ideas and more. Here's a random
        Advizer!
      </Title>

      <Space h="xl" />
      <AdvizerProfile
        standalone
        advizerIdFromProps={randomAdvizer?.id}
        disableTags={true}
        noBottomPadding={true}
      />

      <Space h="xl" />
      <Text titleStyle="h3" ta="center" maw={500}>
        {isLoggedIn ? "Start with one of these options!" : "Get started now!"}
      </Text>
      <Stack maw={500} align="center">
        {PlaylistMainOptions}
      </Stack>
    </Stack>
  );
};

// const questions = [
//   {
//     id: 1,
//     title:
//       "Could you walk me through your career path, starting with your experiences in college, any internships or jobs you had before your current role?",
//   },
//   {
//     id: 2,
//     title: "What are your main responsibilities within your role?",
//   },
//   {
//     id: 3,
//     title: "What does a day in the life of a (insert role here) look like?",
//   },
//   {
//     id: 4,
//     title: "What skills are most important for a job like yours?",
//   },
//   {
//     id: 5,
//     title: "What do you enjoy most about being in your role?",
//   },
//   {
//     id: 6,
//     title: "What is your biggest challenge in your role?",
//   },
//   {
//     id: 7,
//     title: "What do you enjoy most about being in your industry?",
//   },
//   {
//     id: 8,
//     title:
//       "How would you describe people who typically thrive in this industry?",
//   },
//   {
//     id: 9,
//     title:
//       "What have you learned about this role that you wish someone would have told you before you entered the industry?",
//   },
//   {
//     id: 10,
//     title:
//       "What entry-level positions are there in this field that an undergraduate or graduate student might consider [to get to your position someday]?",
//   },
//   {
//     id: 11,
//     title:
//       "What is one lesson that you have learned that has proven significant in your career?",
//   },
//   {
//     id: 12,
//     title:
//       "What did you do in college to set you up for success in your career?",
//   },
//   { icon: IconStar },
// ];
