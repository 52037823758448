import { useStore } from "@/hooks";
import { useAllPlaylists } from "./playlistSelectors";

export const useAssignments = () => {
  const assignments = useStore((state) => state.partnerApp?.assignments);
  return assignments;
};

export const usePartnerUserHasAnyAssignments = () => {
  const assignments = useAssignments();
  return Object.keys(assignments).length > 0;
};

export const useActiveAssignmentId = () => {
  const explicitActiveAssignmentId = useStore(
    (state) => state.partnerApp?.activeAssignmentId,
  );
  return explicitActiveAssignmentId || null;
};

export const useActiveAssignment = () => {
  // const activeAssignmentId = useStore(
  //   (state) => state.partnerApp?.activeAssignmentId,
  // );
  const fullActiveAssignment = useStore(
    (state) => state.partnerApp?.fullActiveAssignment,
  );

  return fullActiveAssignment;
  // const assignments = useAssignments();

  // if (!activeAssignmentId) return Object.values(assignments)[0];
  // return assignments[activeAssignmentId];
};

export const useAssignmentPlaylists = () => {
  const activeAssignment = useActiveAssignment();
  console.log({ activeAssignment });
  const allPlaylists = useAllPlaylists();
  return Object.keys(activeAssignment?.playlists || {}).map((playlistId) => {
    return allPlaylists.find((playlist) => playlist.id === playlistId);
  });
};
