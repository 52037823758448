import { TextInput } from "@/components/ui/TextInput";
import { Analytics } from "@/services/Analytics";
import { useAdvizerSearchText } from "@/store/selectors";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { IconSearch } from "@tabler/icons-react";

export const AdvizerSearchInput = ({
  size = "md",
  placeholder = "Search Advizers...",
}: {
  size?: "md" | "sm";
  placeholder?: string;
}) => {
  const { searchText, setSearchText } = useAdvizerSearchText();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const { theme } = useStyles();

  return (
    <TextInput
      id="searchAdvizers"
      value={searchText || ""}
      onClear={() => setSearchText("")}
      onDebouncedChange={(value) => {
        setSearchText(value);
        Analytics.filterAdvizers({
          filterType: "search",
          filterValue: value,
        });
      }}
      placeholder={placeholder}
      radius="xl"
      flex={1}
      leftSection={<IconSearch size={18} color={theme.colors.text[4]} />}
      variant={isLaptopOrBigger ? "default" : "default"}
      size={size ? size : isLaptopOrBigger ? "md" : "sm"}
    />
  );
};
