import { ColorInput, Divider, Group, Space, Stack } from "@mantine/core";
import { Button } from "./ui/Button";
import { useMemo } from "react";
import { useStyles } from "@/styles/useStyles";
import { Title } from "./ui/Title";
import { Navigation } from "@/services/Navigation";
import { useStore } from "@/store";
import { IconArrowRight, IconExternalLink } from "@tabler/icons-react";
import {
  useDemoMode,
  useDidDismissDemoBar,
  useIsDemoApp,
} from "@/store/selectors/featureSelectors";

export const DemoBar = () => {
  const { theme } = useStyles();
  const isDemoApp = useIsDemoApp();
  const demoMode = useDemoMode();
  const demoPrimaryColor = useStore((state) => state.demo.demoPrimaryColor);
  const demoSecondaryColor = useStore((state) => state.demo.demoSecondaryColor);
  const setDemoMode = useStore((state) => state.demo.setDemoMode);
  const setDemoPrimaryColor = useStore(
    (state) => state.demo.setDemoPrimaryColor,
  );
  const setDemoSecondaryColor = useStore(
    (state) => state.demo.setDemoSecondaryColor,
  );
  const setDidDismissDemoBar = useStore(
    (state) => state.demo.setDidDismissDemoBar,
  );
  const didDismissDemoBar = useDidDismissDemoBar();
  const buttonProps = useMemo(() => {
    return {
      variant: "filled",
      //   color: theme.colors.primary[0],
      radius: "xl",
      size: "xs",
    };
  }, []);

  const DemoOptions = useMemo(() => {
    return (
      <Stack
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: theme.colors.primary[9],
          zIndex: 20,
        }}
        onClick={() => {
          setDidDismissDemoBar(true);
        }}
      >
        <Group px="md" py="sm" gap="md" justify="center">
          {/* <Text titleStyle="h4" fw={600} c="white">
            Demo Options
          </Text> */}
          <Button
            onClick={() => {
              setDemoMode("students");
              Navigation.navigate("/");
              setDidDismissDemoBar(true);
            }}
            {...buttonProps}
            variant={demoMode === "students" ? "filled" : "light"}
            c={demoMode === "students" ? "white" : theme.colors.primary[1]}
          >
            Student View
          </Button>
          <Button
            onClick={() => {
              setDemoMode("partner");
              Navigation.navigate("/");
              setDidDismissDemoBar(true);
            }}
            {...buttonProps}
            variant={demoMode === "partner" ? "filled" : "light"}
            c={demoMode === "partner" ? "white" : theme.colors.primary[1]}
          >
            Partner View
          </Button>
          <Divider orientation="vertical" opacity={0.5} />
          <ColorInput
            value={demoPrimaryColor || theme.colors.primary[7]}
            c={theme.colors.primary[1]}
            label="Primary Color"
            maw={120}
            size="xs"
            onChange={(value) => {
              setDemoPrimaryColor(value);
            }}
          />
          <ColorInput
            value={demoSecondaryColor || theme.colors.secondary[7]}
            c={theme.colors.secondary[1]}
            label="Secondary Color"
            maw={120}
            size="xs"
            onChange={(value) => {
              setDemoSecondaryColor(value);
            }}
          />
          <Divider orientation="vertical" opacity={0.5} />
          <Button
            to="/login/demo"
            target="_blank"
            rightSection={<IconExternalLink size={14} />}
            {...buttonProps}
            variant="outline"
            c={theme.colors.primary[1]}
          >
            Login Page
          </Button>
          <Button
            to="/demo/analytics"
            target="_blank"
            rightSection={<IconExternalLink size={14} />}
            {...buttonProps}
            variant="outline"
            c={theme.colors.primary[1]}
          >
            Analytics
          </Button>
        </Group>
      </Stack>
    );
  }, [
    buttonProps,
    demoMode,
    demoPrimaryColor,
    demoSecondaryColor,
    setDemoMode,
    setDemoPrimaryColor,
    setDemoSecondaryColor,
    setDidDismissDemoBar,
    theme.colors.primary,
    theme.colors.secondary,
  ]);

  if (!isDemoApp) return null;

  if (didDismissDemoBar) return DemoOptions;

  return (
    <>
      <Stack
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(10px)",
          zIndex: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Title order={1} fw={700}>
          Welcome to the Advize Demo!
        </Title>
        <Title order={2} size="h3" fw={500}>
          Use the toolbar below to explore the platform.
        </Title>
        <Space h="xs" />
        <Button
          onClick={() => {
            setDidDismissDemoBar(true);
          }}
          {...buttonProps}
          size="lg"
          variant="light"
          color="primary"
          rightSection={<IconArrowRight />}
        >
          Start exploring demo
        </Button>
      </Stack>
      {DemoOptions}
    </>
  );
};
