import { Text } from "@/components/ui/Text";
import { Title } from "@/components/ui/Title";
import { ResourceConfigs } from "@/configs/resourceConfigs";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";
import { useStore } from "@/store";
import {
  useActiveAssignment,
  useAssignmentPlaylists,
} from "@/store/selectors/partnerAppSelectors";
import { useStyles } from "@/styles/useStyles";
import { Group, Space, Stack } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { PlaylistCard } from "@/components/playlists/PlaylistCard";

export function AssignmentPage() {
  const { assignmentId } = useParams();
  const { theme, ...styles } = useStyles();
  const activeAssignment = useActiveAssignment();
  const assignmentPlaylists = useAssignmentPlaylists();
  const setFullActiveAssignment = useStore(
    (state) => state.partnerApp.setFullActiveAssignment,
  );

  useEffect(() => {
    if (assignmentId) {
      RealtimeDatabase.subscribeToFullAssignment(assignmentId, (assignment) => {
        setFullActiveAssignment(assignment);
      });
    }
  }, [assignmentId, setFullActiveAssignment]);

  const MainInfo = useMemo(() => {
    return (
      <Stack px="md" maw={700} gap={0} w="100%">
        <Group gap="xs" align="center">
          <ResourceConfigs.assignment.Icon size={24} />
          <Text titleStyle="h5" c="text.7" lh={1} pt={2}>
            Assignment
          </Text>
        </Group>
        <Space h={"sm"} />
        <Title>{activeAssignment?.title}</Title>
        <Text c="text.7" fw={500}>
          {activeAssignment?.instructions}
        </Text>
      </Stack>
    );
  }, [activeAssignment]);

  const Playlists = useMemo(() => {
    return (
      <Stack px="md" maw={700} gap="xl">
        {assignmentPlaylists.map((playlist) => (
          <PlaylistCard key={playlist.id} playlistId={playlist.id} />
        ))}
      </Stack>
    );
  }, [assignmentPlaylists]);

  return (
    <>
      <Helmet>
        <title>{activeAssignment?.title}</title>
      </Helmet>

      <Stack
        pt={styles.dimensions.headerHeight + 80}
        pb={100}
        style={{ overflowY: "auto" }}
        bg="background.1"
        h="100vh"
        align="center"
      >
        {MainInfo}
        <Space h={"md"} />
        {Playlists}
      </Stack>
    </>
  );
}
