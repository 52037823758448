import {
  IconBrain,
  IconBrandSlack,
  IconHelp,
  IconHome,
  IconInfoCircle,
  IconLogout,
  IconPlaylist,
  IconSearch,
} from "@tabler/icons-react";
import { PartnerLogo } from "@/components/PartnerLogo";
import { config } from "@/config";
import { Navigation } from "@/services/Navigation";
import { Firebase } from "@/firebase/firebase";
import { useStore } from "@/store";
export const NAV_ITEMS = [
  {
    href: "/",
    label: "Home",
    Icon: IconHome,
    showOnDesktopNavbar: () => false,
  },
  {
    href: "/playlists",
    label: "Playlists",
    Icon: IconPlaylist,
    showOnDesktopNavbar: (params: any) => !params.isPartnerApp,
    isNew: true,
    requiresPartner: true,
  },
  {
    href: "/explore",
    label: "Advizers",
    Icon: IconSearch,
    showOnDesktopNavbar: () => true,
  },
  {
    label: "Alum Advizers",
    requiresPartner: true,
    hide: (partnerId: string, showPartnerToggle?: boolean) =>
      !partnerId || !showPartnerToggle,
    CustomIcon: (partnerId: string) => (
      <PartnerLogo width={26} disableClick partnerId={partnerId} />
    ),
    onClick: (params?: any) => {
      Navigation.navigate("/explore");
      useStore
        .getState()
        .advizerSearch.setActiveFilterPartnerId(params?.partnerId || null);
    },
  },
  {
    href: "https://www.advizehub.com/insights",
    target: "_blank",
    label: "Blog",
    Icon: IconBrain,
  },
  {
    href: config.supportEmailLink,
    label: "Support",
    Icon: IconHelp,
    // target: "_blank",
    // dontClose: true,
    showOnDesktopNavbar: (params: any) =>
      params.isPartnerApp && !config.isDemoApp,
  },
  {
    href: config.slackInviteLink,
    label: "Join Slack",
    Icon: IconBrandSlack,
    target: "_blank",
    dontClose: true,
    showOnDesktopNavbar: () => false,
  },
  {
    href: "https://www.advizehub.com/about",
    label: "About Advize",
    Icon: IconInfoCircle,
  },
  {
    label: "Logout",
    Icon: IconLogout,
    onClick: () => {
      Firebase.logout();
      Navigation.navigate("/");
    },
  },
];
