import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth, Firebase } from "@/firebase/firebase";
import { useStore } from "@/store";
import mixpanel from "mixpanel-browser";
import { useActiveUser, useDialog } from "@/store/selectors";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";
import { StoreService } from "@/services/StoreService";
import { UserProfileService } from "@/services/UserProfileService";
import { useIsPartnerApp } from "@/store/selectors/featureSelectors";
import { config } from "@/config";

let didShowCookieDialog = false;
export function Auth() {
  const setUser = useStore((state) => state.user.setUser);
  const { closeActiveDialog } = useDialog();
  const updateActiveUser = useStore((state) => state.user.updateActiveUser);
  const activeUser = useActiveUser();
  const { activeDialogId, openDialog: openCookieDialog } =
    useDialog("cookieConsent");
  const [didSubscribeToUser, setDidSubscribeToUser] = useState(false);
  const clearNumVideosPlayed = useStore(
    (state) => state.user.clearNumVideosPlayed,
  );
  const isPartnerApp = useIsPartnerApp();

  const mostRecentPathname = useStore((state) => state.mostRecentPathname);

  useEffect(() => {
    if (config.isDemoApp) {
      Firebase.signInAnon();
    }
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setDidSubscribeToUser(false);

      if (user) {
        setUser(user);
        clearNumVideosPlayed();
        try {
          mixpanel.identify(user.uid);
          mixpanel.people.set({
            $name: user.displayName || user.email?.split("@")[0] || "Anonymous",
            $email: user.email,
            isAnonymous: user.isAnonymous,
          });
        } catch (error) {
          console.warn("Error identifying and setting Mixpanel user", error);
        }
      } else {
        setUser(null);
        closeActiveDialog();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeUser || didSubscribeToUser) return;

    setDidSubscribeToUser(true);

    RealtimeDatabase.subscribeToUser(activeUser.id, (userData: any) => {
      const copy = { ...userData };
      userData = userData || {};
      const keysToExclude = ["profileData", "playlistData"];

      keysToExclude.forEach((key) => {
        delete copy[key];
      });

      updateActiveUser({
        ...copy,
        acceptedCookies: userData?.acceptedCookies || false,
        rejectedCookies: userData?.rejectedCookies || false,
      });

      const playlistData = userData?.playlistData || {};
      const userProfileData = userData?.profileData || {};
      // const partnerData = userData?.partnerData || {};

      if (!userData?.playlistData && !userData?.profileData) {
        UserProfileService.updateUserProfileAndPlaylistDataFromStore();
      }

      if (isPartnerApp && !userData?.partnerData) {
        RealtimeDatabase.updateCurrentUser({
          partnerData: {
            isPartnerUser: true,
            partnerId: userData?.partnerId || null,
            partnerSlug: userData?.partnerSlug || null,
          },
        });
      }

      StoreService.createStateUpdater(
        (draft) => {
          if (draft.user) {
            draft.user.playlistData = playlistData;
          }
        },
        { saveLocal: false },
      );

      StoreService.createStateUpdater(
        (draft) => {
          if (draft.user) {
            draft.user.profileData = userProfileData || {};
          }
        },
        { saveLocal: false },
      );

      StoreService.setDidLoadUserData(true);

      if (
        !userData?.acceptedCookies &&
        !userData?.rejectedCookies &&
        !activeDialogId &&
        !config.isDemoApp
      ) {
        console.log("showing cookie dialog", {
          didShowCookieDialog,
          userData,
          activeDialogId,
        });
        if (!didShowCookieDialog) {
          didShowCookieDialog = true;
          setTimeout(() => {
            openCookieDialog();
          }, 3000);
        }
      }

      if (!userData?.email && activeUser?.email) {
        RealtimeDatabase.updateCurrentUser({
          email: activeUser.email,
        });
      }

      if (userData?.rejectedCookies) {
        console.log("OPTING OUT OF MIXPANEL");
        try {
          mixpanel.opt_out_tracking();
        } catch (error) {
          console.warn("Error opting out of Mixpanel", error);
        }
      }
    });

    RealtimeDatabase.subscribeToUserVideoData(
      activeUser.id,
      (videoData: any) => {
        StoreService.createStateUpdater((draft) => {
          if (draft.user) {
            draft.user.videoData = videoData;
          }
        });
      },
    );
  }, [
    activeUser,
    didSubscribeToUser,
    openCookieDialog,
    updateActiveUser,
    activeDialogId,
    mostRecentPathname,
    isPartnerApp,
  ]);

  return null;
}
