import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Group, Space, Stack } from "@mantine/core";
import { TextInput } from "@/components/ui/TextInput";
import { IconPlaylist, IconSearch } from "@tabler/icons-react";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import {
  useAllPlaylists,
  useRecommendedPlaylists,
} from "@/store/selectors/playlistSelectors";
import { PlaylistCard } from "@/components/playlists/PlaylistCard";
import { Navigation } from "@/services/Navigation";
import { Text } from "@/components/ui/Text";
import { useStore, useValueFromBreakpoint } from "@/hooks";
import { Analytics } from "@/services/Analytics";
import { useUserInProgressPlaylistKeys } from "@/store/selectors/userProfileSelectors";

import {
  FixedSizeList as _FixedSizeList,
  FixedSizeListProps,
} from "react-window";
import { AutoSizer as _AutoSizer, AutoSizerProps } from "react-virtualized";

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const FixedSizeList = _FixedSizeList as unknown as FC<FixedSizeListProps>;

export function PlaylistLibrary() {
  const [search, setSearch] = useState("");
  const searchDebounceTimer = useRef<NodeJS.Timeout>();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const allPlaylists = useAllPlaylists();
  const headerHeight = useStyles().dimensions.headerHeight;
  const recommendedPlaylists = useRecommendedPlaylists();
  const inProgressPlaylistKeys = useUserInProgressPlaylistKeys();
  const { theme, ...styles } = useStyles();
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const setCollapseHeader = useStore(
    (state) => state.advizerSearch.setCollapseHeader,
  );

  const playlistGap = 32;

  // if search isnt empty and it's been longer than 5 seconds since the last change, then track the search
  useEffect(() => {
    if (search.length > 0) {
      // Clear any existing timer
      if (searchDebounceTimer.current) {
        clearTimeout(searchDebounceTimer.current);
      }

      // Set new timer
      searchDebounceTimer.current = setTimeout(() => {
        Analytics.filterPlaylists({
          filterType: "search",
          filterValue: search,
        });
      }, 2000);
    }

    // Cleanup timer on unmount or when search changes
    return () => {
      if (searchDebounceTimer.current) {
        clearTimeout(searchDebounceTimer.current);
      }
    };
  }, [search]);

  const playlistCardHeight = useValueFromBreakpoint({
    mobile: 550,
    tablet: 400,
    laptop: 380,
    desktop: 380,
  });

  const maxPlaylistCardWidth = useValueFromBreakpoint({
    mobile: 500,
    tablet: 650,
    laptop: 700,
    desktop: 700,
  });

  const maxAdvizerListHeight = useValueFromBreakpoint({
    mobile: 120,
    tablet: 100,
    laptop: 100,
    desktop: 100,
  });

  const maxSpoilerHeight = useValueFromBreakpoint({
    mobile: 100,
    tablet: 80,
    laptop: 75,
    desktop: 75,
  });

  const advizerQuoteLineClamp = useValueFromBreakpoint({
    mobile: 4,
    tablet: 3,
    laptop: 2,
    desktop: 2,
  });

  const inProgressPlaylistKeyMap = useMemo(() => {
    return inProgressPlaylistKeys.reduce((acc: any, playlistId: string) => {
      acc[playlistId] = true;
      return acc;
    }, {});
  }, [inProgressPlaylistKeys]);

  const visiblePlaylists = useMemo(() => {
    return allPlaylists
      .filter((playlist: any) => {
        const uniqIdStuff = Object.values(playlist.config?.uniqId || {})
          .map((arr: any) => (Array.isArray(arr) ? arr.join(" ") : ""))
          .join(" ");

        const textToSearch = `${playlist.id} ${playlist.title} ${playlist.description} ${uniqIdStuff}`;
        return textToSearch.toLowerCase().includes(search.toLowerCase());
      })
      .sort((a, b) => {
        const aIsRecommended = recommendedPlaylists?.[a.id];
        const bIsRecommended = recommendedPlaylists?.[b.id];

        const aIsInProgress = inProgressPlaylistKeyMap[a.id];
        const bIsInProgress = inProgressPlaylistKeyMap[b.id];

        if (aIsInProgress && !bIsInProgress) return -1;
        if (!aIsInProgress && bIsInProgress) return 1;

        if (aIsRecommended && !bIsRecommended) return -1;
        if (!aIsRecommended && bIsRecommended) return 1;
        return 0;
      });
  }, [allPlaylists, inProgressPlaylistKeyMap, recommendedPlaylists, search]);

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const playlist = visiblePlaylists[index];
      return (
        <Stack
          w="100%"
          style={style}
          key={playlist.id}
          // h={playlistCardHeight}
          // maw={maxPlaylistCardWidth}
          align="center"
          pb={playlistGap}
        >
          <PlaylistCard
            playlistId={playlist.id}
            maxAdvizerListHeight={maxAdvizerListHeight}
            maxSpoilerHeight={maxSpoilerHeight}
            advizerQuoteLineClamp={advizerQuoteLineClamp}
            maxWidth={maxPlaylistCardWidth}
            height={playlistCardHeight}
            isPlaylistLibrary={true}
          />
        </Stack>
      );
    },
    [
      maxAdvizerListHeight,
      maxPlaylistCardWidth,
      playlistCardHeight,
      visiblePlaylists,
      maxSpoilerHeight,
      advizerQuoteLineClamp,
    ],
  );

  const renderListOfPlaylists = useCallback(() => {
    return (
      <AutoSizer>
        {({ width, height }) => {
          return (
            <FixedSizeList
              width={width}
              height={height}
              itemCount={visiblePlaylists.length}
              itemSize={playlistCardHeight}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // paddingBottom: 1000
                // width: "100%",
              }}
              onScroll={(props) => {
                if (
                  props.scrollDirection === "forward" &&
                  props.scrollOffset > styles.dimensions.headerHeight
                ) {
                  setCollapseHeader(true);
                } else {
                  setCollapseHeader(false);
                }
              }}
            >
              {rowRenderer}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
    );
  }, [
    visiblePlaylists.length,
    playlistCardHeight,
    rowRenderer,
    styles.dimensions.headerHeight,
    setCollapseHeader,
  ]);

  return (
    <Box
      // pt="xs"
      bg="background.1"
      h="100vh"
      w="100%"
      style={{ overflow: "auto" }}
      // align="center"
    >
      <Stack
        w="100%"
        mx="auto"
        px="md"
        py="xs"
        align="center"
        pos="sticky"
        top={collapseHeader ? 0 : styles.dimensions.headerHeight}
        bg="white"
        style={{
          zIndex: 10,
          boxShadow: `1px 0 9px 0 ${theme.colors.slate[4]}`,
        }}

        // maw={1200}
        // style={{ borderBottom: `1px solid ${theme.colors.border[2]}` }}
      >
        <Group
          w="100%"
          justify="center"
          wrap="wrap"
          maw={isLaptopOrBigger ? 700 : 500}
        >
          <Button
            radius="xl"
            size="sm"
            variant="filled"
            px="md"
            leftSection={<IconPlaylist size={18} />}
            color="primary"
            onClick={() => {
              Navigation.navToPlaylistAndStep({
                playlistId: "onboarding",
                step: "select-subjects",
              });
              Analytics.click({
                action: "Get Recommended Playlists from Playlists Library",
              });
            }}
          >
            {isLaptopOrBigger
              ? "Get Recommended Playlists"
              : "Get Recomendations"}
          </Button>
          <TextInput
            id="playlistLibrarySearch"
            value={search}
            // onChange={(e) => setSearch(e.target.value)}
            placeholder="Type to search for playlists..."
            size={isLaptopOrBigger ? "sm" : "sm"}
            radius="xl"
            leftSection={<IconSearch size={18} />}
            // miw={150}
            flex={1}
            onClear={() => {
              setSearch("");
            }}
            onDebouncedChange={(value) => {
              console.log({ value });
              setSearch(value);
            }}
          />
          {isLaptopOrBigger && (
            <Text titleStyle="h5" fw={500}>
              {isLaptopOrBigger ? "Showing" : ""} {visiblePlaylists.length}{" "}
              playlist{visiblePlaylists.length > 1 ? "s" : ""}
            </Text>
          )}
        </Group>
      </Stack>
      {!collapseHeader ? <Space h="md" /> : null}
      <Box
        w="100%"
        h="100%"
        mx="auto"
        pt={collapseHeader ? 0 : headerHeight * 1}
      >
        {renderListOfPlaylists()}
      </Box>
      {/* <Group
        gap="xl"
        w="100%"
        wrap="wrap"
        justify="center"
        style={{ alignItems: "stretch" }} // Ensure all items have the same height
      > */}
      {/* {visiblePlaylists.map((playlist: any) => (
          <Stack key={playlist.id} w={600} maw="100%" justify="flex-start">
            <PlaylistCard playlistId={playlist.id} />
          </Stack>
        ))} */}
      {/* </Group> */}
    </Box>
  );
}
