import axios from "axios";
import { config } from "@/config";
import pako from "pako";

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export type IGenericApiQueryParams = {
  model?: string;
  include?: Record<string, any>;
  where?: Record<string, any>;
  take?: number;
  skip?: number;
  noCache?: boolean;
  cacheInvalidator?: number;
};

const NO_CACHE = false;
class AdvizeApiClass {
  async recommendPlaylists(params: any) {
    const response = await api.post("/recommend-playlists", params);
    // return response.data;
    return (response.data?.data?.items || []).reverse();
  }

  async query(params: IGenericApiQueryParams) {
    try {
      const { model } = params;
      const response = await fetch(
        `${config.siteUrl}/apiData/${model}.json.gz`,
      );
      let data = null;
      const contentType = response.headers.get("content-type");
      if (contentType?.includes("gzip")) {
        try {
          const arrayBuffer = await response.arrayBuffer();
          const uint8Array = new Uint8Array(arrayBuffer);
          const decompressed = pako.inflate(uint8Array, { to: "string" });
          data = JSON.parse(decompressed);
        } catch (e) {
          console.error("Error decompressing data for model: " + model, {
            e,
          });
          data = null;
        }
      } else {
        try {
          data = await response.json();
        } catch (e) {
          console.error("Error decompressing data for model: " + model, {
            e,
          });
          data = null;
        }
      }

      if (!data?.length) {
        throw new Error("No data found for model: " + model);
      }
      return { data: { items: data } };
    } catch (e) {
      console.error(
        "Error fetching static apiData file for model: " + params.model,
        {
          params,
          e,
        },
      );
      const response = await api.get("/query", {
        params: {
          ...params,
          cacheInvalidator: config.cacheInvalidator,
          // staticFileOnly: !config.isLocalClient,
          noCache: config.isLocalClient && NO_CACHE,
        },
      });

      const items = response.data.data || response.data;

      return { data: { items } };
    }
  }

  async queryPartners(params?: IGenericApiQueryParams) {
    return this.query({ model: "partner", ...params });
  }

  async querySubjects(params?: IGenericApiQueryParams) {
    return this.query({ model: "subject", ...params });
  }

  async queryJobFunctions(params?: IGenericApiQueryParams) {
    return this.query({ model: "jobFunction", ...params });
  }

  async queryTraits(params?: IGenericApiQueryParams) {
    return this.query({ model: "trait", ...params });
  }

  async queryIndustries(params?: IGenericApiQueryParams) {
    return this.query({ model: "industry", ...params });
  }

  async queryQuestions(params?: IGenericApiQueryParams) {
    return this.query({
      model: "question",
      include: { partners: true },
      ...params,
    });
  }

  async queryDescriptors(params?: IGenericApiQueryParams) {
    return this.query({ model: "descriptor", ...params });
  }

  async queryQuotes(params?: IGenericApiQueryParams) {
    return this.query({ model: "quote", ...params });
  }

  async queryBasicAdvizers(params?: IGenericApiQueryParams) {
    return this.query({
      model: "advizer",
      ...params,
      include: {
        interviews: {
          include: {
            videos: { include: { question: true } },
          },
        },
        partners: true,
        subjects: true,
        traits: true,
        industries: true,
        jobFunctions: true,
      },
    });
  }

  async queryAdvizersWithEverything(params?: IGenericApiQueryParams) {
    return this.query({
      model: "advizer",
      ...params,
      include: {
        interviews: {
          include: {
            videos: { include: { question: true, descriptors: false } },
            // videos: {
            //   include: {
            //     question: false,
            //     descriptors: { select: { id: true, text: true } },
            //   },
            // },
          },
        },
        partners: true,
        subjects: true,
        traits: true,
        industries: true,
        jobFunctions: true,
        quotes: false,
      },
    });
  }
}

export const AdvizeApi = new AdvizeApiClass();
