import { useStyles } from "@/styles/useStyles";

export const BarLoader = ({
  width = "100%",
  height = "2px",
  color = null,
  speed = "2s",
}) => {
  const { theme } = useStyles();
  return (
    <div
      style={{
        width,
        height,
        backgroundColor: "transparent",
        overflow: "hidden",
        position: "relative",
        borderRadius: "4px",
      }}
    >
      <div
        style={{
          width: "40%",
          height: "100%",
          backgroundColor: color || theme.colors.primary[4],
          position: "absolute",
          left: "-40%",
          animation: `barLoad ${speed} infinite ease-in-out`,
        }}
      />
      <style>
        {`
          @keyframes barLoad {
            0% { left: -40%; }
            50% { left: 100%; }
            100% { left: 100%; }
          }
        `}
      </style>
    </div>
  );
};

export default BarLoader;
