import { Notifications } from "@mantine/notifications";
import { UiUpdates } from "./UiUpdates";
import { Auth } from "./Auth";
import { Api } from "./Api";
import { Routing } from "./Routing";
import { Partner } from "./Partner";
import { PlaylistLogic } from "./PlaylistLogic";

export const LogicWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <UiUpdates />
      <Auth />
      <Notifications />
      <Api />
      <Routing />
      <Partner />
      <PlaylistLogic />
      {children}
    </>
  );
};
