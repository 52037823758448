import { MantineColorsTuple, lighten } from "@mantine/core";

// https://mantine.dev/colors-generator/

export const advizeBlue: MantineColorsTuple = [
  "#ecf4fd",
  "#d7e4f6",
  "#aac7ee",
  "#7ca8e8",
  "#568ee3",
  "#407de1",
  "#3575e0",
  "#2963c8",
  "#2058b3",
  "#0e4692",
];

export const advizeGreen: MantineColorsTuple = [
  "#e2fff7",
  "#cefeef",
  "#a0fbdd",
  "#6ef9cb",
  "#46f6bc",
  "#2ef6b2",
  "#1df5ac",
  "#09da96",
  "#00c184",
  "#00a770",
];

export const slate: MantineColorsTuple = [
  "#fff",
  "#f8fafc",
  "#f1f5f9",
  "#e2e8f0",
  "#cbd5e1",
  "#94a3b8",
  "#64748b",
  "#475569",
  "#334155",
  "#1e293b",
  "#0f172a",
];

export const advizeDarkGreen: MantineColorsTuple = [
  "#eef9f3",
  "#e3ece7",
  "#c6d5ce",
  "#a8beb2",
  "#8dab9b",
  "#7c9f8c",
  "#729983",
  "#608571",
  "#537763",
  "#426754",
];

export const highlightYellow: MantineColorsTuple = [
  "#fff8e1",
  "#ffe9b4",
  "#ffda82",
  "#ffcc4d",
  "#ffbd1d",
  "#ffae00",
  "#e69500",
  "#cc8000",
  "#b36b00",
  "#995600",
  "#804200",
];

const sacStateGreenBase = "#043927";
export const sacStateGreen: MantineColorsTuple = [
  lighten(sacStateGreenBase, 0.9),
  lighten(sacStateGreenBase, 0.8),
  lighten(sacStateGreenBase, 0.7),
  lighten(sacStateGreenBase, 0.6),
  lighten(sacStateGreenBase, 0.5),
  lighten(sacStateGreenBase, 0.4),
  lighten(sacStateGreenBase, 0.3),
  lighten(sacStateGreenBase, 0.2),
  lighten(sacStateGreenBase, 0.1),
  sacStateGreenBase,
];

const sacStateGoldBase = "#78682d";
export const sacStateGold: MantineColorsTuple = [
  lighten(sacStateGoldBase, 0.9),
  lighten(sacStateGoldBase, 0.8),
  lighten(sacStateGoldBase, 0.7),
  lighten(sacStateGoldBase, 0.6),
  lighten(sacStateGoldBase, 0.5),
  lighten(sacStateGoldBase, 0.4),
  lighten(sacStateGoldBase, 0.3),
  lighten(sacStateGoldBase, 0.2),
  lighten(sacStateGoldBase, 0.1),
  sacStateGoldBase,
];

const asuMaroonBase = "#8C1D40";
export const asuMaroon: MantineColorsTuple = [
  lighten(asuMaroonBase, 0.9),
  lighten(asuMaroonBase, 0.8),
  lighten(asuMaroonBase, 0.7),
  lighten(asuMaroonBase, 0.6),
  lighten(asuMaroonBase, 0.5),
  lighten(asuMaroonBase, 0.4),
  lighten(asuMaroonBase, 0.3),
  lighten(asuMaroonBase, 0.2),
  lighten(asuMaroonBase, 0.1),
  asuMaroonBase,
];

export const asuGoldBase = "#FFC627";
export const asuGold: MantineColorsTuple = [
  lighten(asuGoldBase, 0.95),
  lighten(asuGoldBase, 0.9),
  lighten(asuGoldBase, 0.8),
  lighten(asuGoldBase, 0.6),
  lighten(asuGoldBase, 0.5),
  lighten(asuGoldBase, 0.4),
  lighten(asuGoldBase, 0.3),
  lighten(asuGoldBase, 0.2),
  lighten(asuGoldBase, 0.1),
  asuGoldBase,
];

export const allianceGreenBase = "#36A857";
export const allianceGreen: MantineColorsTuple = [
  lighten(allianceGreenBase, 0.9),
  lighten(allianceGreenBase, 0.8),
  lighten(allianceGreenBase, 0.7),
  lighten(allianceGreenBase, 0.6),
  lighten(allianceGreenBase, 0.5),
  lighten(allianceGreenBase, 0.4),
  lighten(allianceGreenBase, 0.3),
  lighten(allianceGreenBase, 0.2),
  lighten(allianceGreenBase, 0.1),
  allianceGreenBase,
];

export const allianceOrangeBase = "#FFC700";
export const allianceOrange: MantineColorsTuple = [
  lighten(allianceOrangeBase, 0.9),
  lighten(allianceOrangeBase, 0.8),
  lighten(allianceOrangeBase, 0.7),
  lighten(allianceOrangeBase, 0.6),
  lighten(allianceOrangeBase, 0.5),
  lighten(allianceOrangeBase, 0.4),
  lighten(allianceOrangeBase, 0.3),
  lighten(allianceOrangeBase, 0.2),
  lighten(allianceOrangeBase, 0.1),
  allianceOrangeBase,
];

export const californiaLutheranPrimaryBase = "#3b2360";
export const californiaLutheranPrimary: MantineColorsTuple = [
  lighten(californiaLutheranPrimaryBase, 0.9),
  lighten(californiaLutheranPrimaryBase, 0.8),
  lighten(californiaLutheranPrimaryBase, 0.7),
  lighten(californiaLutheranPrimaryBase, 0.6),
  lighten(californiaLutheranPrimaryBase, 0.5),
  lighten(californiaLutheranPrimaryBase, 0.4),
  lighten(californiaLutheranPrimaryBase, 0.3),
  lighten(californiaLutheranPrimaryBase, 0.2),
  lighten(californiaLutheranPrimaryBase, 0.1),
  californiaLutheranPrimaryBase,
];

export const californiaLutheranSecondaryBase = "#ffc222";
export const californiaLutheranSecondary: MantineColorsTuple = [
  lighten(californiaLutheranSecondaryBase, 0.9),
  lighten(californiaLutheranSecondaryBase, 0.8),
  lighten(californiaLutheranSecondaryBase, 0.7),
  lighten(californiaLutheranSecondaryBase, 0.6),
  lighten(californiaLutheranSecondaryBase, 0.5),
  lighten(californiaLutheranSecondaryBase, 0.4),
  lighten(californiaLutheranSecondaryBase, 0.3),
  lighten(californiaLutheranSecondaryBase, 0.2),
  lighten(californiaLutheranSecondaryBase, 0.1),
  californiaLutheranSecondaryBase,
];

export const compassDiversifiedPrimaryBase = "#0B2337";
export const compassDiversifiedPrimary: MantineColorsTuple = [
  lighten(compassDiversifiedPrimaryBase, 0.9),
  lighten(compassDiversifiedPrimaryBase, 0.8),
  lighten(compassDiversifiedPrimaryBase, 0.7),
  lighten(compassDiversifiedPrimaryBase, 0.6),
  lighten(compassDiversifiedPrimaryBase, 0.5),
  lighten(compassDiversifiedPrimaryBase, 0.4),
  lighten(compassDiversifiedPrimaryBase, 0.3),
  lighten(compassDiversifiedPrimaryBase, 0.2),
  lighten(compassDiversifiedPrimaryBase, 0.1),
  compassDiversifiedPrimaryBase,
];

export const compassDiversifiedSecondaryBase = "#DEB873";
export const compassDiversifiedSecondary: MantineColorsTuple = [
  lighten(compassDiversifiedSecondaryBase, 0.9),
  lighten(compassDiversifiedSecondaryBase, 0.8),
  lighten(compassDiversifiedSecondaryBase, 0.7),
  lighten(compassDiversifiedSecondaryBase, 0.6),
  lighten(compassDiversifiedSecondaryBase, 0.5),
  lighten(compassDiversifiedSecondaryBase, 0.4),
  lighten(compassDiversifiedSecondaryBase, 0.3),
  lighten(compassDiversifiedSecondaryBase, 0.2),
  lighten(compassDiversifiedSecondaryBase, 0.1),
  compassDiversifiedSecondaryBase,
];

export const hericansPrimaryBase = "#000";
export const hericansPrimary: MantineColorsTuple = [
  lighten(hericansPrimaryBase, 0.9),
  lighten(hericansPrimaryBase, 0.8),
  lighten(hericansPrimaryBase, 0.7),
  lighten(hericansPrimaryBase, 0.6),
  lighten(hericansPrimaryBase, 0.5),
  lighten(hericansPrimaryBase, 0.4),
  lighten(hericansPrimaryBase, 0.3),
  lighten(hericansPrimaryBase, 0.2),
  lighten(hericansPrimaryBase, 0.1),
  hericansPrimaryBase,
];

export const hericansSecondaryBase = "#00afff";
// export const hericansSecondaryBase = "#9341cb";
export const hericansSecondary: MantineColorsTuple = [
  lighten(hericansSecondaryBase, 0.9),
  lighten(hericansSecondaryBase, 0.8),
  lighten(hericansSecondaryBase, 0.7),
  lighten(hericansSecondaryBase, 0.6),
  lighten(hericansSecondaryBase, 0.5),
  lighten(hericansSecondaryBase, 0.4),
  lighten(hericansSecondaryBase, 0.3),
  lighten(hericansSecondaryBase, 0.2),
  lighten(hericansSecondaryBase, 0.1),
  hericansSecondaryBase,
];

export const mindsMatterPrimaryBase = "#191919";
export const mindsMatterPrimary: MantineColorsTuple = [
  lighten(mindsMatterPrimaryBase, 0.9),
  lighten(mindsMatterPrimaryBase, 0.8),
  lighten(mindsMatterPrimaryBase, 0.7),
  lighten(mindsMatterPrimaryBase, 0.6),
  lighten(mindsMatterPrimaryBase, 0.5),
  lighten(mindsMatterPrimaryBase, 0.4),
  lighten(mindsMatterPrimaryBase, 0.3),
  lighten(mindsMatterPrimaryBase, 0.2),
  lighten(mindsMatterPrimaryBase, 0.1),
  mindsMatterPrimaryBase,
];

export const mindsMatterSecondaryBase = "#00ADD8";
export const mindsMatterSecondary: MantineColorsTuple = [
  lighten(mindsMatterSecondaryBase, 0.9),
  lighten(mindsMatterSecondaryBase, 0.8),
  lighten(mindsMatterSecondaryBase, 0.7),
  lighten(mindsMatterSecondaryBase, 0.6),
  lighten(mindsMatterSecondaryBase, 0.5),
  lighten(mindsMatterSecondaryBase, 0.4),
  lighten(mindsMatterSecondaryBase, 0.3),
  lighten(mindsMatterSecondaryBase, 0.2),
  lighten(mindsMatterSecondaryBase, 0.1),
  mindsMatterSecondaryBase,
];

export const berkeleyPrimaryBase = "#002676";
export const berkeleyPrimary: MantineColorsTuple = [
  lighten(berkeleyPrimaryBase, 0.9),
  lighten(berkeleyPrimaryBase, 0.8),
  lighten(berkeleyPrimaryBase, 0.7),
  lighten(berkeleyPrimaryBase, 0.6),
  lighten(berkeleyPrimaryBase, 0.5),
  lighten(berkeleyPrimaryBase, 0.4),
  lighten(berkeleyPrimaryBase, 0.3),
  lighten(berkeleyPrimaryBase, 0.2),
  lighten(berkeleyPrimaryBase, 0.1),
  berkeleyPrimaryBase,
];

export const berkeleySecondaryBase = "#FDB515";
export const berkeleySecondary: MantineColorsTuple = [
  lighten(berkeleySecondaryBase, 0.9),
  lighten(berkeleySecondaryBase, 0.8),
  lighten(berkeleySecondaryBase, 0.7),
  lighten(berkeleySecondaryBase, 0.6),
  lighten(berkeleySecondaryBase, 0.5),
  lighten(berkeleySecondaryBase, 0.4),
  lighten(berkeleySecondaryBase, 0.3),
  lighten(berkeleySecondaryBase, 0.2),
  lighten(berkeleySecondaryBase, 0.1),
  berkeleySecondaryBase,
];

export const uclaAndersonPrimaryBase = "#2774AE";
export const uclaAndersonPrimary: MantineColorsTuple = [
  lighten(uclaAndersonPrimaryBase, 0.9),
  lighten(uclaAndersonPrimaryBase, 0.8),
  lighten(uclaAndersonPrimaryBase, 0.7),
  lighten(uclaAndersonPrimaryBase, 0.6),
  lighten(uclaAndersonPrimaryBase, 0.5),
  lighten(uclaAndersonPrimaryBase, 0.4),
  lighten(uclaAndersonPrimaryBase, 0.3),
  lighten(uclaAndersonPrimaryBase, 0.2),
  lighten(uclaAndersonPrimaryBase, 0.1),
  uclaAndersonPrimaryBase,
];

export const uclaAndersonSecondaryBase = "#FFD100";
export const uclaAndersonSecondary: MantineColorsTuple = [
  lighten(uclaAndersonSecondaryBase, 0.9),
  lighten(uclaAndersonSecondaryBase, 0.8),
  lighten(uclaAndersonSecondaryBase, 0.7),
  lighten(uclaAndersonSecondaryBase, 0.6),
  lighten(uclaAndersonSecondaryBase, 0.5),
  lighten(uclaAndersonSecondaryBase, 0.4),
  lighten(uclaAndersonSecondaryBase, 0.3),
  lighten(uclaAndersonSecondaryBase, 0.2),
  lighten(uclaAndersonSecondaryBase, 0.1),
  uclaAndersonSecondaryBase,
];

export const knaussPrimaryBase = "#00294e";
export const knaussPrimary: MantineColorsTuple = [
  lighten(knaussPrimaryBase, 0.9),
  lighten(knaussPrimaryBase, 0.8),
  lighten(knaussPrimaryBase, 0.7),
  lighten(knaussPrimaryBase, 0.6),
  lighten(knaussPrimaryBase, 0.5),
  lighten(knaussPrimaryBase, 0.4),
  lighten(knaussPrimaryBase, 0.3),
  lighten(knaussPrimaryBase, 0.2),
  lighten(knaussPrimaryBase, 0.1),
  knaussPrimaryBase,
];

export const knaussSecondaryBase = "#75bee9";
export const knaussSecondary: MantineColorsTuple = [
  lighten(knaussSecondaryBase, 0.9),
  lighten(knaussSecondaryBase, 0.8),
  lighten(knaussSecondaryBase, 0.7),
  lighten(knaussSecondaryBase, 0.6),
  lighten(knaussSecondaryBase, 0.5),
  lighten(knaussSecondaryBase, 0.4),
  lighten(knaussSecondaryBase, 0.3),
  lighten(knaussSecondaryBase, 0.2),
  lighten(knaussSecondaryBase, 0.1),
  knaussSecondaryBase,
];

export const stanfordPrimaryBase = "#820000";
export const stanfordPrimary: MantineColorsTuple = [
  lighten(stanfordPrimaryBase, 0.9),
  lighten(stanfordPrimaryBase, 0.8),
  lighten(stanfordPrimaryBase, 0.7),
  lighten(stanfordPrimaryBase, 0.6),
  lighten(stanfordPrimaryBase, 0.5),
  lighten(stanfordPrimaryBase, 0.4),
  lighten(stanfordPrimaryBase, 0.3),
  lighten(stanfordPrimaryBase, 0.2),
  lighten(stanfordPrimaryBase, 0.1),
  stanfordPrimaryBase,
];

export const stanfordSecondaryBase = "#2E2D29";
export const stanfordSecondary: MantineColorsTuple = [
  lighten(stanfordSecondaryBase, 0.9),
  lighten(stanfordSecondaryBase, 0.8),
  lighten(stanfordSecondaryBase, 0.7),
  lighten(stanfordSecondaryBase, 0.6),
  lighten(stanfordSecondaryBase, 0.5),
  lighten(stanfordSecondaryBase, 0.4),
  lighten(stanfordSecondaryBase, 0.3),
  lighten(stanfordSecondaryBase, 0.2),
  lighten(stanfordSecondaryBase, 0.1),
  stanfordSecondaryBase,
];

export const stanfordAccent1Base = "#D1660F";
export const stanfordAccent2Base = "#009AB4";
export const stanfordAccent3Base = "#620059";
export const stanfordAccent4Base = "#C74632";
export const stanfordAccent5Base = "#175E54";

export const stanfordAccent1: MantineColorsTuple = [
  lighten(stanfordAccent1Base, 0.9),
  lighten(stanfordAccent1Base, 0.8),
  lighten(stanfordAccent1Base, 0.7),
  lighten(stanfordAccent1Base, 0.6),
  lighten(stanfordAccent1Base, 0.5),
  lighten(stanfordAccent1Base, 0.4),
  lighten(stanfordAccent1Base, 0.3),
  lighten(stanfordAccent1Base, 0.2),
  lighten(stanfordAccent1Base, 0.1),
  stanfordAccent1Base,
];

export const stanfordAccent2: MantineColorsTuple = [
  lighten(stanfordAccent2Base, 0.9),
  lighten(stanfordAccent2Base, 0.8),
  lighten(stanfordAccent2Base, 0.7),
  lighten(stanfordAccent2Base, 0.6),
  lighten(stanfordAccent2Base, 0.5),
  lighten(stanfordAccent2Base, 0.4),
  lighten(stanfordAccent2Base, 0.3),
  lighten(stanfordAccent2Base, 0.2),
  lighten(stanfordAccent2Base, 0.1),
  stanfordAccent2Base,
];

export const stanfordAccent3: MantineColorsTuple = [
  lighten(stanfordAccent3Base, 0.9),
  lighten(stanfordAccent3Base, 0.8),
  lighten(stanfordAccent3Base, 0.7),
  lighten(stanfordAccent3Base, 0.6),
  lighten(stanfordAccent3Base, 0.5),
  lighten(stanfordAccent3Base, 0.4),
  lighten(stanfordAccent3Base, 0.3),
  lighten(stanfordAccent3Base, 0.2),
  lighten(stanfordAccent3Base, 0.1),
  stanfordAccent3Base,
];

export const stanfordAccent4: MantineColorsTuple = [
  lighten(stanfordAccent4Base, 0.9),
  lighten(stanfordAccent4Base, 0.8),
  lighten(stanfordAccent4Base, 0.7),
  lighten(stanfordAccent4Base, 0.6),
  lighten(stanfordAccent4Base, 0.5),
  lighten(stanfordAccent4Base, 0.4),
  lighten(stanfordAccent4Base, 0.3),
  lighten(stanfordAccent4Base, 0.2),
  lighten(stanfordAccent4Base, 0.1),
  stanfordAccent4Base,
];

export const stanfordAccent5: MantineColorsTuple = [
  lighten(stanfordAccent5Base, 0.9),
  lighten(stanfordAccent5Base, 0.8),
  lighten(stanfordAccent5Base, 0.7),
  lighten(stanfordAccent5Base, 0.6),
  lighten(stanfordAccent5Base, 0.5),
  lighten(stanfordAccent5Base, 0.4),
  lighten(stanfordAccent5Base, 0.3),
  lighten(stanfordAccent5Base, 0.2),
  lighten(stanfordAccent5Base, 0.1),
  stanfordAccent5Base,
];

export const uscaPrimaryBase = "#002855";
export const uscaPrimary: MantineColorsTuple = [
  lighten(uscaPrimaryBase, 0.9),
  lighten(uscaPrimaryBase, 0.8),
  lighten(uscaPrimaryBase, 0.7),
  lighten(uscaPrimaryBase, 0.6),
  lighten(uscaPrimaryBase, 0.5),
  lighten(uscaPrimaryBase, 0.4),
  lighten(uscaPrimaryBase, 0.3),
  lighten(uscaPrimaryBase, 0.2),
  lighten(uscaPrimaryBase, 0.1),
  uscaPrimaryBase,
];

export const uscaSecondaryBase = "#E4002B";
export const uscaSecondary: MantineColorsTuple = [
  lighten(uscaSecondaryBase, 0.9),
  lighten(uscaSecondaryBase, 0.8),
  lighten(uscaSecondaryBase, 0.7),
  lighten(uscaSecondaryBase, 0.6),
  lighten(uscaSecondaryBase, 0.5),
  lighten(uscaSecondaryBase, 0.4),
  lighten(uscaSecondaryBase, 0.3),
  lighten(uscaSecondaryBase, 0.2),
  lighten(uscaSecondaryBase, 0.1),
  uscaSecondaryBase,
];

export const arizonaEllerPrimaryBase = "#0C234B";
export const arizonaEllerPrimary: MantineColorsTuple = [
  lighten(arizonaEllerPrimaryBase, 0.9),
  lighten(arizonaEllerPrimaryBase, 0.8),
  lighten(arizonaEllerPrimaryBase, 0.7),
  lighten(arizonaEllerPrimaryBase, 0.6),
  lighten(arizonaEllerPrimaryBase, 0.5),
  lighten(arizonaEllerPrimaryBase, 0.4),
  lighten(arizonaEllerPrimaryBase, 0.3),
  lighten(arizonaEllerPrimaryBase, 0.2),
  lighten(arizonaEllerPrimaryBase, 0.1),
  arizonaEllerPrimaryBase,
];

export const arizonaEllerSecondaryBase = "#AB0520";
export const arizonaEllerSecondary: MantineColorsTuple = [
  lighten(arizonaEllerSecondaryBase, 0.9),
  lighten(arizonaEllerSecondaryBase, 0.8),
  lighten(arizonaEllerSecondaryBase, 0.7),
  lighten(arizonaEllerSecondaryBase, 0.6),
  lighten(arizonaEllerSecondaryBase, 0.5),
  lighten(arizonaEllerSecondaryBase, 0.4),
  lighten(arizonaEllerSecondaryBase, 0.3),
  lighten(arizonaEllerSecondaryBase, 0.2),
  lighten(arizonaEllerSecondaryBase, 0.1),
  arizonaEllerSecondaryBase,
];

export const purduePrimaryBase = "#000000";
export const purduePrimary: MantineColorsTuple = [
  lighten(purduePrimaryBase, 0.9),
  lighten(purduePrimaryBase, 0.8),
  lighten(purduePrimaryBase, 0.7),
  lighten(purduePrimaryBase, 0.6),
  lighten(purduePrimaryBase, 0.5),
  lighten(purduePrimaryBase, 0.4),
  lighten(purduePrimaryBase, 0.3),
  lighten(purduePrimaryBase, 0.2),
  lighten(purduePrimaryBase, 0.1),
  purduePrimaryBase,
];

export const purdueSecondaryBase = "#CFB991";
export const purdueSecondary: MantineColorsTuple = [
  lighten(purdueSecondaryBase, 0.9),
  lighten(purdueSecondaryBase, 0.8),
  lighten(purdueSecondaryBase, 0.7),
  lighten(purdueSecondaryBase, 0.6),
  lighten(purdueSecondaryBase, 0.5),
  lighten(purdueSecondaryBase, 0.4),
  lighten(purdueSecondaryBase, 0.3),
  lighten(purdueSecondaryBase, 0.2),
  lighten(purdueSecondaryBase, 0.1),
  purdueSecondaryBase,
];

export const uvaDardenPrimaryBase = "#232D4B";
export const uvaDardenPrimary: MantineColorsTuple = [
  lighten(uvaDardenPrimaryBase, 0.9),
  lighten(uvaDardenPrimaryBase, 0.8),
  lighten(uvaDardenPrimaryBase, 0.7),
  lighten(uvaDardenPrimaryBase, 0.6),
  lighten(uvaDardenPrimaryBase, 0.5),
  lighten(uvaDardenPrimaryBase, 0.4),
  lighten(uvaDardenPrimaryBase, 0.3),
  lighten(uvaDardenPrimaryBase, 0.2),
  lighten(uvaDardenPrimaryBase, 0.1),
  uvaDardenPrimaryBase,
];

export const uvaDardenSecondaryBase = "#E57200";
export const uvaDardenSecondary: MantineColorsTuple = [
  lighten(uvaDardenSecondaryBase, 0.9),
  lighten(uvaDardenSecondaryBase, 0.8),
  lighten(uvaDardenSecondaryBase, 0.7),
  lighten(uvaDardenSecondaryBase, 0.6),
  lighten(uvaDardenSecondaryBase, 0.5),
  lighten(uvaDardenSecondaryBase, 0.4),
  lighten(uvaDardenSecondaryBase, 0.3),
  lighten(uvaDardenSecondaryBase, 0.2),
  lighten(uvaDardenSecondaryBase, 0.1),
  uvaDardenSecondaryBase,
];

export const sierraCollegePrimaryBase = "#AF2124";
export const sierraCollegePrimary: MantineColorsTuple = [
  lighten(sierraCollegePrimaryBase, 0.9),
  lighten(sierraCollegePrimaryBase, 0.8),
  lighten(sierraCollegePrimaryBase, 0.7),
  lighten(sierraCollegePrimaryBase, 0.6),
  lighten(sierraCollegePrimaryBase, 0.5),
  lighten(sierraCollegePrimaryBase, 0.4),
  lighten(sierraCollegePrimaryBase, 0.3),
  lighten(sierraCollegePrimaryBase, 0.2),
  lighten(sierraCollegePrimaryBase, 0.1),
  sierraCollegePrimaryBase,
];

export const sierraCollegeSecondaryBase = "#9D9D9D";
export const sierraCollegeSecondary: MantineColorsTuple = [
  lighten(sierraCollegeSecondaryBase, 0.9),
  lighten(sierraCollegeSecondaryBase, 0.8),
  lighten(sierraCollegeSecondaryBase, 0.7),
  lighten(sierraCollegeSecondaryBase, 0.6),
  lighten(sierraCollegeSecondaryBase, 0.5),
  lighten(sierraCollegeSecondaryBase, 0.4),
  lighten(sierraCollegeSecondaryBase, 0.3),
  lighten(sierraCollegeSecondaryBase, 0.2),
  lighten(sierraCollegeSecondaryBase, 0.1),
  sierraCollegeSecondaryBase,
];

export const jessupUniversityPrimaryBase = "#0f2c47";
export const jessupUniversityPrimary: MantineColorsTuple = [
  lighten(jessupUniversityPrimaryBase, 0.9),
  lighten(jessupUniversityPrimaryBase, 0.8),
  lighten(jessupUniversityPrimaryBase, 0.7),
  lighten(jessupUniversityPrimaryBase, 0.6),
  lighten(jessupUniversityPrimaryBase, 0.5),
  lighten(jessupUniversityPrimaryBase, 0.4),
  lighten(jessupUniversityPrimaryBase, 0.3),
  lighten(jessupUniversityPrimaryBase, 0.2),
  lighten(jessupUniversityPrimaryBase, 0.1),
  jessupUniversityPrimaryBase,
];

export const jessupUniversitySecondaryBase = "#00addb";
export const jessupUniversitySecondary: MantineColorsTuple = [
  lighten(jessupUniversitySecondaryBase, 0.9),
  lighten(jessupUniversitySecondaryBase, 0.8),
  lighten(jessupUniversitySecondaryBase, 0.7),
  lighten(jessupUniversitySecondaryBase, 0.6),
  lighten(jessupUniversitySecondaryBase, 0.5),
  lighten(jessupUniversitySecondaryBase, 0.4),
  lighten(jessupUniversitySecondaryBase, 0.3),
  lighten(jessupUniversitySecondaryBase, 0.2),
  lighten(jessupUniversitySecondaryBase, 0.1),
  jessupUniversitySecondaryBase,
];

export const pbaPrimaryBase = "#0c2340";
export const pbaPrimary: MantineColorsTuple = [
  lighten(pbaPrimaryBase, 0.9),
  lighten(pbaPrimaryBase, 0.8),
  lighten(pbaPrimaryBase, 0.7),
  lighten(pbaPrimaryBase, 0.6),
  lighten(pbaPrimaryBase, 0.5),
  lighten(pbaPrimaryBase, 0.4),
  lighten(pbaPrimaryBase, 0.3),
  lighten(pbaPrimaryBase, 0.2),
  lighten(pbaPrimaryBase, 0.1),
  pbaPrimaryBase,
];

export const pbaSecondaryBase = "#04d3df";
export const pbaSecondary: MantineColorsTuple = [
  lighten(pbaSecondaryBase, 0.9),
  lighten(pbaSecondaryBase, 0.8),
  lighten(pbaSecondaryBase, 0.7),
  lighten(pbaSecondaryBase, 0.6),
  lighten(pbaSecondaryBase, 0.5),
  lighten(pbaSecondaryBase, 0.4),
  lighten(pbaSecondaryBase, 0.3),
  lighten(pbaSecondaryBase, 0.2),
  lighten(pbaSecondaryBase, 0.1),
  pbaSecondaryBase,
];
