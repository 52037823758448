import { Playlist } from "@/components/playlists/Playlist";
import {
  useIsDemoApp,
  useIsPartnerApp,
} from "@/store/selectors/featureSelectors";
import { PartnerDashboard } from "./PartnerDashboard";
import { useActiveUserPartner, useIsLoggedIn } from "@/store/selectors";
import { AdvizerSearchPage } from "./AdvizerSearchPage";

export const HomePage = () => {
  const isPartnerApp = useIsPartnerApp();
  const isDemoApp = useIsDemoApp();
  const isLoggedIn = useIsLoggedIn();
  const userPartner = useActiveUserPartner();

  if (isPartnerApp) {
    return <PartnerDashboard />;
  }

  if ((!isLoggedIn || !userPartner) && !isDemoApp) {
    return <AdvizerSearchPage />;
  }

  return <Playlist />;
};
