import { firestore } from "@/firebase/firebase";
import { IAnalyticsEventData } from "@/services/Analytics";
import { addDoc, collection } from "firebase/firestore";
class FirestoreService {
  get db() {
    return firestore;
  }

  async saveEvent({
    event,
    data,
  }: {
    event: string;
    data?: IAnalyticsEventData;
  }) {
    try {
      const eventTypeCollection = collection(this.db, "events", "types", event);

      const docRef = await addDoc(eventTypeCollection, {
        timestamp: Date.now(),
        ...data,
      });

      console.log("Event saved to Firestore", { docId: docRef.id });
    } catch (e) {
      console.error("Error saving event to Firestore", { e, event, data });
    }
  }
}

export const Firestore = new FirestoreService();
