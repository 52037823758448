import { forwardRef, useState, useEffect, useRef, useCallback } from "react";
import {
  ActionIcon,
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import classes from "@/styles/TextInput.module.css";

type MantineTextInputValue = string;

export interface TextInputProps extends Omit<MantineTextInputProps, "value"> {
  id: string;
  value: MantineTextInputValue;
  defaultValue?: MantineTextInputValue;
  onDebouncedChange?: (value: MantineTextInputValue) => void;
  debounceDelay?: number;
  onClear?: () => void;
  multiline?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({
    id,
    onDebouncedChange,
    debounceDelay = 300,
    onClear,
    value,
    ...props
  }) => {
    const [localValue, setLocalValue] = useState<MantineTextInputValue>(
      value || props.defaultValue || "",
    );

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      setLocalValue(value || "");
    }, [value]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setLocalValue(newValue);
        props.onChange?.(event);

        // Clear existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set new timeout
        timeoutRef.current = setTimeout(() => {
          onDebouncedChange?.(newValue);
        }, debounceDelay);
      },
      [props, debounceDelay, onDebouncedChange],
    );

    const handleClear = useCallback(() => {
      setLocalValue("");
      onClear?.();

      // Clear existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }, [onClear]);

    return (
      <MantineTextInput
        id={id}
        name={id}
        value={localValue}
        aria-invalid={props.error ? true : undefined}
        aria-errormessage={props.error ? `${id}-error` : undefined}
        onChange={handleChange as any}
        classNames={{ input: classes.textInput }}
        rightSection={
          value && onClear ? (
            <ActionIcon
              onClick={handleClear}
              variant="transparent"
              color={!value ? "text.4" : "text.6"}
            >
              <IconX />
            </ActionIcon>
          ) : null
        }
        styles={{
          label: {
            color: "text.1",
          },
          // ...props.styles,
        }}
        {...props}
      />
    );
  },
);

TextInput.displayName = "TextInput";
