import { forwardRef, useCallback } from "react";
import {
  Image as MantineImage,
  ImageProps as MantineImageProps,
  AspectRatio,
  Stack,
} from "@mantine/core";
import { IQuote } from "@/types/ApiTypes";
import { Text } from "./Text";

interface AdditionalImageProps extends MantineImageProps {
  ratio?: number;
  width?: number | string;
  noRatio?: boolean;
  memeText?: React.ReactNode;
  alt: string;
  quote?: IQuote;
}

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> &
  AdditionalImageProps;

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const { ratio = 1, width, noRatio, memeText, alt, quote, ...rest } = props;

  const renderImage = useCallback(() => {
    return <MantineImage {...rest} alt={alt} ref={ref} />;
  }, [rest, ref, alt]);

  const renderCoreImageContent = useCallback(() => {
    if (memeText) {
      return (
        <div role="img" aria-label={`Meme: ${alt}`}>
          {renderImage()}
          <Stack
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            h="100%"
            w="100%"
            align="center"
            justify="center"
            bg="rgba(0,0,0,0.5)"
          >
            {memeText}
          </Stack>
        </div>
      );
    }

    if (quote) {
      console.log("IMAGE HAS A QUOTE");
      return (
        <div role="img" aria-label={`Quote: ${alt}`}>
          {renderImage()}
          <Stack
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            h="100%"
            w="100%"
            align="center"
            justify="center"
            bg="rgba(0,0,0,0.5)"
          >
            <Text
              size="xl"
              titleStyle="h2"
              c="white"
              style={{ WebkitTextStroke: "1px black" }}
            >
              {quote.text}
            </Text>
          </Stack>
        </div>
      );
    }

    return renderImage();
  }, [alt, memeText, quote, renderImage]);

  if (noRatio) {
    return renderCoreImageContent();
  }
  return (
    <AspectRatio ratio={ratio} maw={width}>
      {renderCoreImageContent()}
    </AspectRatio>
  );
});

Image.displayName = "Image";
