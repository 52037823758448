import { createColumnHelper } from "@tanstack/react-table";
import {
  useActivePlaylistDescriptors,
  usePlaylistTags,
  useActivePlaylistAdvizers,
  useFilteredPlaylists,
} from "@/store/selectors/playlistSelectors";
import { Table } from "../tables/Table";
import { useMemo, useState } from "react";
import { Text } from "../ui/Text";
import { Anchor, Group, Space, Stack } from "@mantine/core";
import { IconEye, IconEyeOff, IconPlus, IconTrash } from "@tabler/icons-react";
import { AdvizerTag } from "../AdvizerTag";
import { Button } from "../ui/Button";
import { useFilteredAdvizers } from "@/store/selectors";
import { useStyles } from "@/styles/useStyles";
import { useStore } from "@/store";
import { useActiveAssignment } from "@/store/selectors/partnerAppSelectors";
import { AssignmentService } from "@/services/Assignments";
import { CopyPlaylistLink } from "../playlists/CopyPlaylistLink";

const cellWidths = {
  index: 50,
  // duration: 100,
  title: 400,
  topics: 400,
  tags: 300,
  advizers: 400,
};

const totalWidth = Object.values(cellWidths).reduce(
  (acc, curr) => acc + curr,
  0,
);
export const PlaylistsTable = () => {
  const { playlists } = useFilteredPlaylists();
  const columnHelper = createColumnHelper();
  const { isSearchingAdvizers, isAdvizersLoading } = useFilteredAdvizers();
  const activePlaylistId = useStore(
    (state) => state.partnerApp.activePlaylistId,
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("index", {
        header: "",
        size: cellWidths.index,
        minSize: cellWidths.index,
        cell: (info) => (
          <Text lh={1.2} size="xs" c="text.5" ta="center" w="100%">
            {info.row.index + 1}
          </Text>
        ),
      }),
      // columnHelper.accessor("duration", {
      //   header: "Duration",
      //   size: cellWidths.duration,
      //   minSize: cellWidths.duration,
      //   cell: (info: any) => (
      //     <PlaylistDuration playlistId={info.row.original.id} />
      //   ),
      // }),
      columnHelper.accessor("title", {
        header: "Title",
        size: cellWidths.title,
        minSize: cellWidths.title,
        cell: (info: any) => (
          <PlaylistTitle
            title={info.getValue()}
            playlistId={info.row.original.id}
          />
        ),
      }),
      columnHelper.accessor("advizers", {
        header: "Advizers",
        minSize: cellWidths.advizers,
        size: cellWidths.advizers,
        cell: (info: any) => {
          // console.log("ROW", info.row.original);
          return (
            <PlaylistAdvizers
              playlistId={info.row.original.id}
              matchingAdvizerIds={info.row.original.advizerIdsMatchingFilters}
              isFilteredOnlyForMatchingAdvizers={
                info.row.original.isFilteredOnlyForMatchingAdvizers
              }
            />
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        size: cellWidths.tags,
        minSize: cellWidths.tags,
        cell: (info: any) => <PlaylistTags playlistId={info.row.original.id} />,
      }),
      columnHelper.accessor("topics", {
        header: "Topics",
        // size: cellWidths.topics,
        minSize: cellWidths.topics,
        cell: (info: any) => (
          <PlaylistTopics playlistId={info.row.original.id} />
        ),
      }),
    ];
  }, [columnHelper]);

  return (
    <Stack w="100%">
      <Table
        data={isSearchingAdvizers || isAdvizersLoading ? [] : playlists}
        columns={columns}
        minWidth={totalWidth}
        activeRowId={activePlaylistId || undefined}
      />
    </Stack>
  );
};

export default PlaylistsTable;

function PlaylistTitle({
  title,
  playlistId,
}: {
  title: string;
  playlistId: string;
}) {
  const [, setHovering] = useState(false);
  const activePlaylistId = useStore(
    (state) => state.partnerApp.activePlaylistId,
  );
  const activeAssignment = useActiveAssignment();
  const isPlaylistInActiveAssignment = useMemo(() => {
    return (activeAssignment?.playlists || {})[playlistId];
  }, [activeAssignment, playlistId]);

  const isActive = activePlaylistId === playlistId;

  const setActivePlaylistId = useStore(
    (state) => state.partnerApp.setActivePlaylistId,
  );

  return (
    <Stack gap="xs" py="xs">
      <Anchor
        title={title}
        href={`/playlists/${playlistId}`}
        target="_blank"
        c="text.8"
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
      >
        <Text titleStyle="h6" fz={15} lh={1.2}>
          {title}
        </Text>
      </Anchor>
      <Group gap="xs">
        <CopyPlaylistLink playlistId={playlistId} />
        <Button
          variant="light"
          radius="xl"
          size="xs"
          px="xs"
          h={24}
          fz={10}
          py={0}
          bg={isActive ? "pink.6" : undefined}
          c={isActive ? "pink.0" : undefined}
          onClick={() => setActivePlaylistId(isActive ? null : playlistId)}
        >
          {!isActive ? <IconEye size={12} /> : <IconEyeOff size={12} />}
          <Space w={4} />
          {isActive ? "Exit preview" : "Preview"}
        </Button>
        {activeAssignment && (
          <Button
            variant="filled"
            radius="xl"
            color={isPlaylistInActiveAssignment ? "red" : "primary"}
            size="xs"
            px="xs"
            h={24}
            fz={10}
            py={0}
            onClick={() => {
              if (isPlaylistInActiveAssignment) {
                AssignmentService.removePlaylistFromAssignment({
                  assignmentId: activeAssignment.id,
                  playlistId,
                });
              } else {
                AssignmentService.addPlaylistToAssignment({
                  assignmentId: activeAssignment.id,
                  playlistId,
                });
              }
            }}
          >
            {isPlaylistInActiveAssignment ? (
              <IconTrash size={12} />
            ) : (
              <IconPlus size={12} />
            )}
            <Space w={4} />
            {isPlaylistInActiveAssignment ? "Remove" : "Assign"}
          </Button>
        )}
      </Group>
    </Stack>
  );
}

function PlaylistTopics({ playlistId }: { playlistId: string }) {
  const topics = useActivePlaylistDescriptors(playlistId);
  return (
    <Text fz={12} lh={1.4}>
      {topics.map((topic) => topic.text).join(" • ")}
    </Text>
  );
}

function PlaylistTags({ playlistId }: { playlistId: string }) {
  const tags = usePlaylistTags(playlistId);
  return (
    <Group gap="xs" fz={12}>
      {tags.map((tag) => (
        <AdvizerTag
          key={tag.id}
          tag={tag}
          disableClick={false}
          maw={cellWidths.tags - 20}
        />
      ))}
    </Group>
  );
}

function PlaylistAdvizers({
  playlistId,
  matchingAdvizerIds = [],
  isFilteredOnlyForMatchingAdvizers = false,
}: {
  playlistId: string;
  matchingAdvizerIds: string[];
  isFilteredOnlyForMatchingAdvizers: boolean;
}) {
  const { theme } = useStyles();
  const advizers = useActivePlaylistAdvizers(playlistId);
  const sortedAdvizers = advizers.sort((a) => {
    return matchingAdvizerIds.includes(a.id) ? -1 : 1;
  });

  return (
    <Stack gap={0} fz={12}>
      {sortedAdvizers.map((advizer) => {
        const isMatchingAdvizer =
          isFilteredOnlyForMatchingAdvizers &&
          matchingAdvizerIds.includes(advizer.id);

        return (
          <Anchor
            key={advizer.id}
            href={`/advizers/${advizer.id}`}
            target="_blank"
            title={`${advizer.jobTitle} @ ${advizer.company}`}
            lineClamp={1}
            fw={isMatchingAdvizer ? "normal" : "normal"}
            fz={12}
            onClick={() => {
              console.log({ advizer });
            }}
            mb={4}
            style={
              isMatchingAdvizer
                ? {
                    backgroundColor: theme.colors.highlightYellow[0], // semi-transparent yellow
                    padding: "0 4px",
                    borderRadius: "2px",
                  }
                : undefined
            }
          >
            {advizer.jobTitle} @ {advizer.company}
          </Anchor>
        );
      })}
    </Stack>
  );
}
