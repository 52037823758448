import { Analytics } from "@/services/Analytics";
import { useStore } from "@/store";
import { useActivePlaylist } from "@/store/selectors/playlistSelectors";
import { Stack, Textarea } from "@mantine/core";
import { useEffect } from "react";

export const PlaylistCustomRequest = () => {
  const activePlaylist = useActivePlaylist();
  const userCustomPlaylistRecommendationRequest = useStore(
    (state) => state.playlists.userCustomPlaylistRecommendationRequest,
  );
  const updateUserCustomPlaylistRecommendationRequest = useStore(
    (state) => state.playlists.updateUserCustomPlaylistRecommendationRequest,
  );

  // make a debounced useEffect to send analytics event when custom request changes
  useEffect(() => {
    if (userCustomPlaylistRecommendationRequest) {
      const timeout = setTimeout(() => {
        Analytics.updateUserProfile({
          action: "custom_playlist_request",
          label: userCustomPlaylistRecommendationRequest,
          playlist: activePlaylist,
        });
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [userCustomPlaylistRecommendationRequest]);

  return (
    <Stack>
      <Textarea
        placeholder={`This can be anything! Some examples... 
- I'm not sure if becoming a lawyer is right for me
- I'm applying to a job at a manufacturing company and need help prepping for the interview`}
        size="lg"
        autosize
        minRows={5}
        maxLength={500}
        value={userCustomPlaylistRecommendationRequest}
        onChange={(e) =>
          updateUserCustomPlaylistRecommendationRequest(e.target.value)
        }
      />
    </Stack>
  );
};
