import { create, StateCreator } from "zustand";
import { produce } from "immer";
import {
  IAdvizeApiDataKeys,
  IAdvizerSearchFilterKeys,
  IStoreState,
} from "../types/StoreTypes";
import { IAdvizerModel } from "../models/Advizer";
import { User as FirebaseUser } from "firebase/auth";
import { createPartnerAppSlice } from "./slices/partnerAppSlice";

export const STORAGE_KEY = "advize-local-storage";

const defaultLocalStorage = {
  partner: {},
  user: {
    playlistData: {
      playlists: {},
      recommendedPlaylists: {},
    },
    videoData: {},
    profileData: {
      subjects: {},
      industries: {},
      jobFunctions: {},
      traits: {},
      companies: {},
    },
  },
  playlists: {},
  partnerApp: {
    assignments: {
      assignments: [],
      activeAssignmentId: null,
    },
  },
  demo: {
    didDismissDemoBar: false,
    demoMode: null,
  },
};

export const loadFromStorage = () => {
  const stored = localStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : defaultLocalStorage;
};

export type UpdateConfig = {
  saveLocal?: boolean;
  convertFunc?: (val: any) => any;
};

export const createStore: StateCreator<IStoreState> = (set) => {
  const localStorageState = loadFromStorage() || {};

  const setAndProduce = (
    fn: (state: IStoreState) => void,
    config?: UpdateConfig,
  ) => {
    return set(
      produce((state) => {
        try {
          if (config?.saveLocal) {
            const localStorageStateNow = loadFromStorage() || {};

            (
              Object.keys(defaultLocalStorage) as Array<
                keyof typeof defaultLocalStorage
              >
            ).forEach((key) => {
              if (!localStorageStateNow[key]) {
                localStorageStateNow[key] = defaultLocalStorage[key];
              }
            });

            const updatedLocalState = produce(localStorageStateNow, fn);
            localStorage.setItem(
              STORAGE_KEY,
              JSON.stringify(updatedLocalState),
            );
          }
        } catch (error) {
          console.error("Error setting local storage", error);
        }

        return fn(state);
      }),
    );
  };

  return {
    partnerApp: createPartnerAppSlice({ setAndProduce, localStorageState }),
    isAppLoading: true,
    createStateUpdater: setAndProduce,
    setIsAppLoading: (isLoading: boolean) =>
      setAndProduce((state) => {
        state.isAppLoading = isLoading;
      }),
    showMainNavbar: true,
    setShowMainNavbar: (show: boolean) =>
      setAndProduce((state) => {
        state.showMainNavbar = show;
      }),
    didScrollDown: false,
    setDidScrollDown: (didScrollDown: boolean) =>
      setAndProduce((state) => {
        state.didScrollDown = didScrollDown;
      }),
    navPathAfterLogin: null,
    setNavPathAfterLogin: (path: string | null) =>
      setAndProduce(
        (state) => {
          state.navPathAfterLogin = path;
        },
        { saveLocal: true },
      ),

    mostRecentPathname: null,
    setMostRecentPathname: (pathname: string | null) =>
      setAndProduce((state) => {
        state.mostRecentPathname = pathname;
      }),

    partner: {
      activePartner: localStorageState?.partner?.activePartner || null,
      setActivePartner: (partner: any | null) =>
        setAndProduce(
          (state) => {
            if (typeof partner !== "undefined") {
              state.partner.activePartner = partner;
            }
          },
          { saveLocal: true, convertFunc: (val) => val.toJSON() },
        ),
    },

    user: {
      activeUser: localStorageState?.user?.activeUser || null,
      didLoadUserData: false,
      numVideosPlayed: localStorageState?.user?.numVideosPlayed || 0,
      showUserInterviewForm: false,
      profileData:
        localStorageState?.user?.profileData ||
        defaultLocalStorage.user.profileData,
      playlistData:
        localStorageState?.user?.playlistData ||
        defaultLocalStorage.user.playlistData,
      videoData:
        localStorageState?.user?.videoData ||
        defaultLocalStorage.user.videoData,
      setShowUserInterviewForm: (show: boolean) =>
        setAndProduce((state) => {
          state.user.showUserInterviewForm = show;
        }),
      setUser: (user: FirebaseUser) => {
        setAndProduce(
          (state) => {
            state.user.activeUser = user?.toJSON() || null;
          },
          { saveLocal: true, convertFunc: (val) => val.toJSON() },
        );
      },
      updateActiveUser: (data: any) =>
        setAndProduce(
          (state) => {
            state.user.activeUser = { ...state.user.activeUser, ...data };
          },
          { saveLocal: true, convertFunc: (val) => val.toJSON() },
        ),
      updateUserState: (data: any) =>
        setAndProduce((state) => {
          state.user = { ...state.user, ...data };
        }),
      // updateUserPlaylistData: (data: any) =>
      //   setAndProduce((state) => {
      //     state.user.playlistData = { ...state.user.playlistData, ...data };
      //   }),
      // updateUserPlaylistStepData: (params: {
      //   playlistId: string;
      //   stepKey: string;
      //   data: any;
      // }) =>
      //   setAndProduce((state) => {
      //     state.user.playlistData.playlists[params.playlistId][params.stepKey] =
      //       params.data;
      //   }),
      incrementNumVideosPlayed: () =>
        setAndProduce(
          (state) => {
            if (state.user.activeUser) return;
            state.user.numVideosPlayed = (state.user.numVideosPlayed || 0) + 1;
          },
          { saveLocal: true },
        ),
      updateUserProfileData: (data: any) =>
        setAndProduce((state) => {
          state.user.profileData = { ...state.user.profileData, ...data };
        }),

      setUserProfileData: (data: any) =>
        setAndProduce((state) => {
          state.user.profileData = data;
        }),
      setUserVideoData: (data: any) =>
        setAndProduce((state) => {
          state.user.videoData = data;
        }),
      setDidLoadUserData: (didLoadUserData: boolean) =>
        setAndProduce((state) => {
          state.user.didLoadUserData = didLoadUserData;
        }),
      clearNumVideosPlayed: () =>
        setAndProduce(
          (state) => {
            state.user.numVideosPlayed = 0;
          },
          { saveLocal: true },
        ),
      clearUser: () =>
        setAndProduce(
          (state) => {
            state.user.activeUser = null;
          },
          { saveLocal: true },
        ),
    },

    navigation: {
      navToPath: null,
      setNavToPath: (path: string | null) =>
        setAndProduce((state) => {
          state.navigation.navToPath = path;
        }),
    },

    advizerSearch: {
      activeFilterPartnerId: null,
      setActiveFilterPartnerId: (partnerId: string | null) =>
        setAndProduce((state) => {
          state.advizerSearch.activeFilterPartnerId = partnerId;
        }),
      searchText: "",
      setSearchText: (searchText?: string) =>
        setAndProduce((state) => {
          state.advizerSearch.searchText = searchText || "";
        }),
      activeQuestionId: null,
      activeQuestionNumber: null,
      setActiveQuestionId: (questionId: string | null) =>
        setAndProduce((state) => {
          state.advizerSearch.activeQuestionId = questionId || null;
        }),
      setActiveQuestionNumber: (questionNumber: number | null) =>
        setAndProduce((state) => {
          state.advizerSearch.activeQuestionNumber = questionNumber || null;
        }),
      isInlineAdvizerSearchOpen: false,
      setIsInlineAdvizerSearchOpen: (isOpen: boolean) =>
        setAndProduce((state) => {
          state.advizerSearch.isInlineAdvizerSearchOpen = isOpen;
        }),
      isSearchingAdvizers: false,
      setIsSearchingAdvizers: (isSearching: boolean) =>
        setAndProduce((state) => {
          state.advizerSearch.isSearchingAdvizers = isSearching;
        }),
      activeSearchGradDegree: null,
      setActiveSearchGradDegree: (gradDegree: string | null) =>
        setAndProduce((state) => {
          state.advizerSearch.activeSearchGradDegree = gradDegree || null;
        }),
      subjects: {},
      industries: {},
      jobFunctions: {},
      traits: {},
      companies: {},
      toggleFilterOption: (key: IAdvizerSearchFilterKeys, value: string) =>
        setAndProduce((state) => {
          if (state.advizerSearch[key][value]) {
            delete state.advizerSearch[key][value];
          } else {
            state.advizerSearch[key][value] = true;
          }
        }),
      resetAdvizerSearch: () =>
        setAndProduce((state) => {
          state.advizerSearch.searchText = "";
          state.advizerSearch.isSearchingAdvizers = false;
          state.advizerSearch.subjects = {};
          state.advizerSearch.industries = {};
          state.advizerSearch.jobFunctions = {};
          state.advizerSearch.traits = {};
          state.advizerSearch.companies = {};
          state.advizerSearch.activeQuestionId = null;
          state.advizerSearch.activeQuestionNumber = null;
        }),
      collapseHeader: false,
      setCollapseHeader: (collapseHeader: boolean) =>
        setAndProduce((state) => {
          state.advizerSearch.collapseHeader = collapseHeader;
        }),
      playlistsForFiltering: {},
      updatePlaylistInPlaylistsForFiltering: (params: {
        playlistId: string;
        data: any;
      }) =>
        setAndProduce((state) => {
          state.advizerSearch.playlistsForFiltering[params.playlistId] = {
            ...state.advizerSearch.playlistsForFiltering[params.playlistId],
            ...params.data,
          };
        }),
    },

    videos: {
      activeVideoId: null,
      setActiveVideoId: (videoId: string | null) =>
        setAndProduce((state) => {
          state.videos.activeVideoId = videoId;
        }),
    },

    drawers: {
      activeDrawerId: null,
      setActiveDrawer: (drawerId: string | null) =>
        setAndProduce((state) => {
          state.drawers.activeDrawerId = drawerId;
        }),
      closeActiveDrawer: () =>
        setAndProduce((state) => {
          state.drawers.activeDrawerId = null;
        }),
    },

    dialog: {
      activeDialogId: null,
      setActiveDialog: (dialogId: string | null) =>
        setAndProduce((state) => {
          state.dialog.activeDialogId = dialogId;
        }),
    },

    advizeApiData: {
      advizers: null,
      subjects: null,
      jobFunctions: null,
      traits: null,
      industries: null,
      companies: null,
      questions: null,
      partners: null,
      quotes: null,
      descriptors: null,
      setApiData: (params: { key: IAdvizeApiDataKeys; data: any[] }) => {
        setAndProduce((state) => {
          state.advizeApiData[params.key] = params.data;
        });
      },
      addAdvizers: (advizers: IAdvizerModel[]) =>
        setAndProduce((state) => {
          state.advizeApiData.advizers = [
            ...(state.advizeApiData.advizers || []),
            ...advizers,
          ];
        }),
    },

    advizerInfoById: {},
    setAdvizerInfoById: (advizerId: string, info: any) =>
      setAndProduce((state) => {
        state.advizerInfoById[advizerId] = info;
      }),

    experience: {
      showConfetti: false,
      setShowConfetti: (showConfetti: boolean) =>
        setAndProduce((state) => {
          state.experience.showConfetti = showConfetti;
        }),
    },

    playlists: {
      activePlaylistId: null,
      activePlaylistStepKey: null,
      isPlaylistLoading: false,
      explicitPlaylistMessage: null,
      showPlaylistLoading: false,
      playlistLibrary: null,
      isLoggingInDuringPlaylist: false,
      allPlaylists: [],
      activePlaylistOutcomeKey:
        localStorageState?.playlists?.activePlaylistOutcomeKey || null,
      activePlaylist: null,
      userCustomPlaylistRecommendationRequest: "",
      setIsLoggingInDuringPlaylist: (isLoggingInDuringPlaylist: boolean) =>
        setAndProduce((state) => {
          state.playlists.isLoggingInDuringPlaylist = isLoggingInDuringPlaylist;
        }),
      setActivePlaylistStepKey: (stepKey: string | null) =>
        setAndProduce((state) => {
          state.playlists.activePlaylistStepKey = stepKey;
        }),
      updateUserCustomPlaylistRecommendationRequest: (request: string) =>
        setAndProduce(
          (state) => {
            state.playlists.userCustomPlaylistRecommendationRequest = request;
          },
          { saveLocal: true },
        ),
      setActivePlaylistOutcomeKey(outcomeKey) {
        setAndProduce((state) => {
          state.playlists.activePlaylistOutcomeKey = outcomeKey;
        });
      },
      setPlaylistLoading: (loading: string | boolean) =>
        setAndProduce((state) => {
          state.playlists.isPlaylistLoading = loading;
        }),
      clearActivePlaylist: () =>
        setAndProduce((state) => {
          state.playlists.activePlaylist = null;
          state.playlists.activePlaylistId = null;
        }),
      setShowPlaylistLoading: (show: boolean) =>
        setAndProduce((state) => {
          state.playlists.showPlaylistLoading = show;
        }),
      setExplicitPlaylistMessage: (message: string | null) =>
        setAndProduce((state) => {
          state.playlists.explicitPlaylistMessage = message;
        }),
      setPlaylistLibrary: (playlistLibrary: any) =>
        setAndProduce((state) => {
          state.playlists.playlistLibrary = playlistLibrary;
        }),
      setAllPlaylists: (allPlaylists: any) =>
        setAndProduce((state) => {
          state.playlists.allPlaylists = allPlaylists;
        }),

      updatePlaylistsState: (newState: any) =>
        setAndProduce(
          (state) => {
            state.playlists = { ...state.playlists, ...newState };
          },
          { saveLocal: true },
        ),
    },

    demo: {
      didDismissDemoBar: localStorageState?.demo?.didDismissDemoBar || false,
      setDidDismissDemoBar: (didDismissDemoBar: boolean) =>
        setAndProduce(
          (state) => {
            state.demo.didDismissDemoBar = didDismissDemoBar;
          },
          { saveLocal: true },
        ),
      demoPrimaryColor: localStorageState?.demo?.demoPrimaryColor || null,
      setDemoPrimaryColor: (demoPrimaryColor: string | null) =>
        setAndProduce(
          (state) => {
            state.demo.demoPrimaryColor = demoPrimaryColor;
          },
          { saveLocal: true },
        ),
      demoSecondaryColor: localStorageState?.demo?.demoSecondaryColor || null,
      setDemoSecondaryColor: (demoSecondaryColor: string | null) =>
        setAndProduce(
          (state) => {
            state.demo.demoSecondaryColor = demoSecondaryColor;
          },
          { saveLocal: true },
        ),
      demoMode: localStorageState?.demo?.demoMode || null,
      setDemoMode: (demoMode: string | null) =>
        setAndProduce(
          (state) => {
            state.demo.demoMode = demoMode;
          },
          { saveLocal: true },
        ),
    },
  };
};

export const useStore = create(createStore);
