import {
  useActivePlaylistDescriptors,
  useDidUserCompletePlaylist,
  useIsPlaylistCompleted,
  useIsPlaylistInProgressForUser,
  useIsRecommendedPlaylist,
  usePlaylistById,
  usePlaylistTags,
} from "@/store/selectors/playlistSelectors";
import { Badge, Button, Group, Space, Stack } from "@mantine/core";
import { Text } from "../ui/Text";
import { IconArrowRight, IconCheck, IconStar } from "@tabler/icons-react";
import { useIsTabletOrBigger, useStyles } from "@/styles/useStyles";
import { PlaylistAdvizerList } from "./PlaylistAdvizerList";
import { Navigation } from "@/services/Navigation";
import { StoreService } from "@/services/StoreService";
import { Analytics } from "@/services/Analytics";
import { useCallback, useMemo, useState } from "react";
import { AdvizerTag } from "../AdvizerTag";
import { useGoToNextStepInPlaylist } from "@/store/selectors/playlistSelectors";
export function PlaylistCard({
  playlistId,
  isPlaylistIntro,
  maxAdvizerListHeight,
  advizerQuoteLineClamp,
  maxWidth,
  height,
  isPlaylistConclusion,
  isPlaylistLibrary,
  isPreview,
}: {
  playlistId: string;
  isPlaylistIntro?: boolean;
  maxAdvizerListHeight?: number;
  maxSpoilerHeight?: number;
  advizerQuoteLineClamp?: number;
  maxWidth?: number;
  height?: number;
  isPlaylistConclusion?: boolean;
  isPlaylistLibrary?: boolean;
  isPreview?: boolean;
}) {
  const playlist = usePlaylistById(playlistId);
  const { theme } = useStyles();
  const [, setIsHovered] = useState(false);
  const isPlaylistInProgressForUser =
    useIsPlaylistInProgressForUser(playlistId);
  const isRecommendedPlaylist = useIsRecommendedPlaylist(playlistId);
  const playlistDescriptors = useActivePlaylistDescriptors(playlistId);
  const playlistTags = usePlaylistTags(playlistId);
  const isTabletOrBigger = useIsTabletOrBigger();
  const didUserCompletePlaylist = useDidUserCompletePlaylist(playlistId);
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();
  const isPlaylistCompleted = useIsPlaylistCompleted(playlistId);
  const handleStartPlaylist = useCallback(() => {
    const action = isPlaylistInProgressForUser ? "continue" : "start";
    // if (isPlaylistIntro) {
    //   goToNextStepInPlaylist();
    // } else {
    if (!isPlaylistIntro) {
      StoreService.clearActivePlaylist();
    }

    if (isPlaylistIntro && didUserCompletePlaylist) {
      goToNextStepInPlaylist();
    } else {
      Navigation.navToPlaylistAndAutoNavToNextStep({ playlistId });
    }

    Analytics.playlistStart({
      playlist,
      context: isPlaylistConclusion
        ? "playlist_conclusion"
        : isPlaylistIntro
          ? "playlist_intro"
          : "playlist_library",
      action,
    });
  }, [
    didUserCompletePlaylist,
    goToNextStepInPlaylist,
    isPlaylistConclusion,
    isPlaylistInProgressForUser,
    isPlaylistIntro,
    playlist,
    playlistId,
  ]);

  const PlaylistTags = useMemo(() => {
    if (isPreview) return null;
    return (
      <Group
        gap={10}
        align="center"
        justify={isPlaylistIntro ? "flex-start" : "flex-start"}
        w="100%"
      >
        {isPlaylistCompleted && (
          <>
            <Badge
              color="green"
              size="md"
              leftSection={<IconCheck size={16} />}
              style={{ textTransform: "none" }}
            >
              Completed
            </Badge>
          </>
        )}
        {/* {isPlaylistInProgressForUser && (
          <Badge
            color="secondary"
            variant="outline"
            // c="text.6"
            leftSection={<IconClock size={12} />}
            // color="transparent"
            size="xl"
            style={{ textTransform: "none" }}
            // lh={1.1}
          >
            Almost done!
          </Badge>
        )} */}

        {isRecommendedPlaylist && (
          <>
            <Badge
              color="primary"
              size="md"
              leftSection={<IconStar size={12} />}
              style={{ textTransform: "none" }}
            >
              Recommended
            </Badge>
          </>
        )}
        {playlistTags.map((tag) => (
          <AdvizerTag key={tag.id} tag={tag} disableClick={true} maw={250} />
        ))}
      </Group>
    );
  }, [
    isPlaylistCompleted,
    isPlaylistIntro,
    isPreview,
    isRecommendedPlaylist,
    playlistTags,
  ]);

  const PlaylistTitle = useMemo(() => {
    return (
      <Group
        w="100%"
        justify="space-between"
        wrap="nowrap"
        align="baseline"
        gap="xl"
      >
        <a
          title={playlist?.title}
          aria-label={playlist?.title}
          onClick={handleStartPlaylist}
        >
          <Text
            titleStyle={isPlaylistIntro ? "h2" : "h3"}
            lh={1.1}
            ta={isPlaylistIntro ? "left" : "left"}
            c="text.9"
            flex={1}
            lineClamp={isTabletOrBigger && isPlaylistLibrary ? 2 : 3}
          >
            {playlist?.title}
          </Text>
        </a>
        {isTabletOrBigger && isPlaylistLibrary && (
          <Button
            variant={
              isPlaylistCompleted
                ? "outline"
                : isPlaylistInProgressForUser
                  ? "filled"
                  : "light"
            }
            color="primary"
            size="sm"
            radius="xl"
            rightSection={<IconArrowRight size={18} />}
            onClick={handleStartPlaylist}
            miw="fit-content"
          >
            {isPlaylistCompleted
              ? "View"
              : isPlaylistInProgressForUser
                ? "Continue"
                : "Start"}
          </Button>
        )}
      </Group>
    );
  }, [
    playlist?.title,
    handleStartPlaylist,
    isPlaylistIntro,
    isTabletOrBigger,
    isPlaylistLibrary,
    isPlaylistInProgressForUser,
    isPlaylistCompleted,
  ]);

  const PlalistDescriptors = useMemo(() => {
    return (
      <Group>
        <Text
          c="text.7"
          lh={1.3}
          ta="left"
          titleStyle="h6"
          fz={16}
          fw={400}
          lineClamp={
            isPlaylistIntro || isPreview ? 5 : isTabletOrBigger ? 2 : 3
          }
        >
          <span style={{ fontWeight: 600 }}>Topics: </span>
          {playlistDescriptors.map((d) => d.text).join(", ")}
        </Text>
      </Group>
    );
  }, [isPlaylistIntro, isPreview, isTabletOrBigger, playlistDescriptors]);

  if (!playlist) return null;

  return (
    <Group
      mx="auto"
      p={isPreview ? 0 : "xl"}
      bg={isPreview || isPlaylistCompleted ? "background.1" : "background.0"}
      h={"100%"}
      w="100%"
      maw={maxWidth}
      mah={height}
      align={isTabletOrBigger ? "center" : "flex-start"}
      wrap="nowrap"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      classNames={{
        root: isPlaylistInProgressForUser ? "pulseBorder" : "",
      }}
      style={{
        borderRadius: 8,
        // cursor: "pointer",
        border: isPreview
          ? "none"
          : `1px solid ${isPlaylistCompleted ? theme.colors.border[3] : theme.colors.border[2]}`,
        boxShadow:
          isPlaylistCompleted || isPreview
            ? undefined
            : "0 0 3px 0 rgba(0, 0, 0, 0.1)",
      }}
      // className="shadow-on-hover"
    >
      <Stack gap={0} w="100%" justify="space-between" h="100%">
        <Stack gap={0} w="100%" h="100%" flex={1} style={{ overflow: "auto" }}>
          {PlaylistTitle}
          <Space h={isTabletOrBigger ? "md" : "sm"} />

          <Stack gap={0} pr="xl">
            {PlalistDescriptors}

            {/* {(!isTabletOrBigger || !isPlaylistLibrary) && ( */}
            <>
              <Space h="md" />
              {PlaylistTags}
            </>
            {/* )} */}
            {isPreview ? null : (
              <>
                <Space h={isTabletOrBigger ? "lg" : "xl"} />

                <PlaylistAdvizerList
                  playlistId={playlistId}
                  maxAdvizerListHeight={maxAdvizerListHeight}
                  advizerQuoteLineClamp={advizerQuoteLineClamp}
                />
              </>
            )}
          </Stack>
        </Stack>

        {
          !isPreview && (!isTabletOrBigger || !isPlaylistLibrary) ? (
            <Stack gap={0} h={80} justify="flex-end">
              <Button
                variant={
                  isPlaylistIntro || isPlaylistInProgressForUser
                    ? "filled"
                    : "light"
                }
                color="primary"
                size="md"
                radius="xl"
                fullWidth
                rightSection={<IconArrowRight size={18} />}
                mih={40}
                onClick={handleStartPlaylist}
              >
                {isPlaylistInProgressForUser ? "Continue" : "Start"} Playlist
              </Button>
            </Stack>
          ) : null
          // PlaylistTags
        }
      </Stack>
      {/* {isLaptopOrBigger && (
        <Stack w={40} align="flex-end">
          <IconArrowRight size={24} />
        </Stack>
      )} */}
    </Group>
  );
}
