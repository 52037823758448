import { RealtimeDatabase } from "@/services/RealtimeDatabase";
import { useStore } from "@/store";
import {
  useActiveAssignmentId,
  useAssignments,
} from "@/store/selectors/partnerAppSelectors";
import { useEffect } from "react";

export function PartnerDashboardLogic() {
  const setAssignments = useStore((state) => state.partnerApp.setAssignments);
  const assignments = useAssignments();
  const activeAssignmentId = useActiveAssignmentId();
  const fullActiveAssignment = useStore(
    (state) => state.partnerApp.fullActiveAssignment,
  );
  const setFullActiveAssignment = useStore(
    (state) => state.partnerApp.setFullActiveAssignment,
  );
  const isAssignmentManagerOpen = useStore(
    (state) => state.partnerApp.isAssignmentManagerOpen,
  );
  const setActiveAssignmentId = useStore(
    (state) => state.partnerApp.setActiveAssignmentId,
  );

  useEffect(() => {
    RealtimeDatabase.subscribeToUserAssignments((assignments: any[]) => {
      console.log("assignments", assignments);
      setAssignments(assignments);
    });
  }, [setAssignments]);

  useEffect(() => {
    if (activeAssignmentId && fullActiveAssignment?.id !== activeAssignmentId) {
      RealtimeDatabase.subscribeToFullAssignment(
        activeAssignmentId,
        (assignment) => {
          setFullActiveAssignment(assignment);
        },
      );
    } else if (!activeAssignmentId) {
      setFullActiveAssignment(null);
    }
  }, [activeAssignmentId, fullActiveAssignment?.id, setFullActiveAssignment]);

  useEffect(() => {
    if (isAssignmentManagerOpen && !activeAssignmentId) {
      setActiveAssignmentId(Object.keys(assignments)[0]);
    }
  }, [
    isAssignmentManagerOpen,
    activeAssignmentId,
    assignments,
    setActiveAssignmentId,
  ]);

  return null;
}
