import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Space,
  Stack,
} from "@mantine/core";
import { PlaylistsTable } from "@/components/partners/PlaylistsTable";
import { useStyles } from "@/styles/useStyles";
import { AdvizerSearchInput } from "@/components/advizerSearchControls/AdvizerSearchInput";
import { QuestionSelector } from "@/components/advizerSearchControls/QuestionSelector";
import { useFilteredPlaylists } from "@/store/selectors/playlistSelectors";
import { Text } from "@/components/ui/Text";
import {
  useArrayOfSelectedAdvizerSearchFilters,
  useFilteredAdvizers,
  // useIsLoggedIn,
} from "@/store/selectors";
import { ResetAdvizerSearchButton } from "@/components/advizerSearchControls/ResetAdvizerSearchButton";
import { SelectedSearchTags } from "@/components/advizerSearchControls/SelectedSearchTags";
import { AdvizerFilterButton } from "@/components/advizerSearchControls/AdvizerFilterButton";
import { useEffect, useMemo, useRef } from "react";
import { useStore } from "@/store";
import { AdvizerSearchFilters } from "@/components/AdvizerSearchFilters";
import { ResourceConfigs } from "@/configs/resourceConfigs";
// import { Link } from "@/components/ui/Link";
import { IconPlus, IconX } from "@tabler/icons-react";
import { PlaylistPreview } from "@/components/partners/PlaylistPreview";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";
// import { Navigation } from "@/services/Navigation";
import { AssignmentManager } from "@/components/assignments/AssignmentManager";
import { usePartnerUserHasAnyAssignments } from "@/store/selectors/partnerAppSelectors";
import { PartnerDashboardLogic } from "@/components/logic/PartnerDashboardLogic";
import BarLoader from "@/components/ui/BarLoader";
import { useIsDemoApp } from "@/store/selectors/featureSelectors";

export function PartnerDashboard() {
  const { theme, ...styles } = useStyles();
  const { totalFilteredPlaylists } = useFilteredPlaylists();
  const { isAdvizersLoading, isSearchingAdvizers } = useFilteredAdvizers();
  const arrayOfSelectedAdvizerSearchFilters =
    useArrayOfSelectedAdvizerSearchFilters();
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const isInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.isInlineAdvizerSearchOpen,
  );
  const isDemoApp = useIsDemoApp();
  const isAssignmentManagerOpen = useStore(
    (state) => isDemoApp && state.partnerApp.isAssignmentManagerOpen,
  );
  const setIsAssignmentManagerOpen = useStore(
    (state) => state.partnerApp.setIsAssignmentManagerOpen,
  );
  const setIsInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.setIsInlineAdvizerSearchOpen,
  );
  const activePlaylistId = useStore(
    (state) => state.partnerApp.activePlaylistId,
  );
  const partnerUserHasAnyAssignments = usePartnerUserHasAnyAssignments();

  const setMostRecentPathname = useStore(
    (state) => state.setMostRecentPathname,
  );
  const leftDrawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMostRecentPathname("/");
  }, [setMostRecentPathname]);

  // scroll to top if playlistId changes
  useEffect(() => {
    if (leftDrawerRef.current) {
      leftDrawerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [activePlaylistId]);

  useEffect(() => {
    if (activePlaylistId) {
      setIsInlineAdvizerSearchOpen(false);
    }
  }, [activePlaylistId, setIsInlineAdvizerSearchOpen]);

  const MainContent = useMemo(() => {
    return (
      <Stack
        // pt={styles.dimensions.headerHeight + 16}
        py="md"
        px={0}
        style={{ overflow: "hidden" }}
        h="100%"
        bg="background.1"
        w="100%"
        mx="auto"
        gap={0}
        mah="100vh"
        flex={2}
      >
        <Group px="md" justify="space-between" align="baseline">
          <Group w="100%" flex={1}>
            <AdvizerFilterButton size="sm" buttonText="Filter" />
            <Box flex={1} maw={250} miw={200}>
              <AdvizerSearchInput
                size="sm"
                placeholder="Type to search for playlists"
              />
            </Box>
            <Box flex={1} maw={250} miw={200}>
              <QuestionSelector size="sm" />
            </Box>
            <Group px="xs">
              <Text titleStyle="h6" lh={1.2}>
                Showing {totalFilteredPlaylists} playlists
              </Text>
            </Group>
          </Group>
          {!isAssignmentManagerOpen && isDemoApp && (
            <Button
              variant="light"
              color="primary"
              size="sm"
              radius="xl"
              leftSection={<ResourceConfigs.assignment.Icon size={20} />}
              onClick={() => setIsAssignmentManagerOpen(true)}
            >
              Assignments
            </Button>
          )}
        </Group>

        {arrayOfSelectedAdvizerSearchFilters.length > 0 && (
          <Group px="lg" pt="sm">
            <SelectedSearchTags />
            <ResetAdvizerSearchButton />
          </Group>
        )}
        <Space h="md" />
        <Group p={0} w="100%" mah={4}>
          {(isAdvizersLoading || isSearchingAdvizers) && (
            <BarLoader />
            // <BarLoader
            //   width={500}
            //   color={theme.colors.primary[4]}
            //   height={2}
            // />
          )}
        </Group>
        <Divider />
        <Stack style={{ overflow: "auto" }}>
          <PlaylistsTable />
        </Stack>
      </Stack>
    );
  }, [
    arrayOfSelectedAdvizerSearchFilters.length,
    isAdvizersLoading,
    isAssignmentManagerOpen,
    isDemoApp,
    isSearchingAdvizers,
    setIsAssignmentManagerOpen,
    totalFilteredPlaylists,
  ]);

  const RightDrawerContent = useMemo(() => {
    if (!isAssignmentManagerOpen) return null;

    if (partnerUserHasAnyAssignments) {
      return <AssignmentManager />;
    }

    return <AssignmentDefaultContent />;
  }, [isAssignmentManagerOpen, partnerUserHasAnyAssignments]);

  const LeftDrawerContent = useMemo(() => {
    if (isInlineAdvizerSearchOpen) {
      return (
        <AdvizerSearchFilters
          hideCompanyFilter
          noTabTitles
          hidePartnerToggle
          hideTabTitle
        />
      );
    }

    if (activePlaylistId) {
      return <PlaylistPreview playlistId={activePlaylistId} />;
    }

    return null;
  }, [activePlaylistId, isInlineAdvizerSearchOpen]);

  return (
    <>
      <Group
        pt={styles.dimensions.headerHeight}
        gap={0}
        align="start"
        h="100vh"
        mah="100vh"
      >
        {LeftDrawerContent ? (
          <Stack
            ref={leftDrawerRef}
            h={`calc(100vh - ${
              collapseHeader ? 0 : styles.dimensions.headerHeight
            }px)`}
            maw={isInlineAdvizerSearchOpen ? 340 : 450}
            miw={300}
            flex={1}
            pos="sticky"
            top={collapseHeader ? 0 : styles.dimensions.headerHeight - 10}
            bg="background.0"
            style={{
              zIndex: 10,
              borderRight: `2px solid ${theme.colors.border[3]}`,
              overflow: "auto",
            }}
            gap={0}
            p={0}
          >
            {LeftDrawerContent}
          </Stack>
        ) : null}
        {MainContent}
        {RightDrawerContent ? (
          <Stack
            h={`calc(100vh - ${
              collapseHeader ? 0 : styles.dimensions.headerHeight
            }px)`}
            maw={450}
            bg="background.1"
            flex={1}
            pos="sticky"
            top={collapseHeader ? 0 : styles.dimensions.headerHeight - 10}
            style={{
              zIndex: 10,
              borderLeft: `4px solid ${theme.colors.border[3]}`,
              // boxShadow: `-4px 0 8px -2px rgba(0, 0, 0, 0.15)`,
            }}
            gap={0}
            p={0}
          >
            {RightDrawerContent}
          </Stack>
        ) : null}
      </Group>
      <PartnerDashboardLogic />
    </>
  );
}

function AssignmentDefaultContent() {
  const { theme } = useStyles();
  // const isLoggedIn = useIsLoggedIn();
  const setIsAssignmentManagerOpen = useStore(
    (state) => state.partnerApp.setIsAssignmentManagerOpen,
  );
  return (
    <Stack align="center" justify="center" px={0} gap="lg">
      <Group justify="flex-end" w="100%" px="sm" py="sm">
        <ActionIcon
          variant="light"
          size="md"
          radius="xl"
          color="text.6"
          style={{ zIndex: 100 }}
          onClick={() => setIsAssignmentManagerOpen(false)}
        >
          <IconX size={20} />
        </ActionIcon>
      </Group>
      <Stack align="center" justify="center" px="xl" gap="lg">
        <ResourceConfigs.assignment.Icon
          size={80}
          color={theme.colors.primary[7]}
        />
        <Text titleStyle="h4" lh={1.2} ta="center">
          Design career exploration assignments that lead to measurable outcomes
          for your learners
        </Text>
        {/* <Space h="xs" /> */}
        <Button
          variant="filled"
          radius="xl"
          size="sm"
          color="primary"
          leftSection={<IconPlus />}
          onClick={() => {
            // if (!isLoggedIn) {
            //   Navigation.navToLogin();
            //   return;
            // }
            RealtimeDatabase.createNewBlankAssignment();
          }}
        >
          Create an assignment
        </Button>
        <Space h="xs" />
        {/* <Text size="sm" ta="center">
          <Link
            to="/partner/assignments"
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            Click here to check out an example assignment
          </Link>
        </Text> */}
      </Stack>
    </Stack>
  );
}
