import { useStore } from "@/store";

class NavigationServiceClass {
  navigate(
    path: string,
    params: {
      clearParams?: boolean;
      keepParams?: string[];
      newQueryParams?: Record<string, string | boolean>;
      queryParamsToRemove?: string[];
      simpleReplace?: boolean;
    } = {},
  ) {
    if (params.simpleReplace) {
      useStore.getState().navigation.setNavToPath(path);
      return;
    }

    const currentUrlParams = new URLSearchParams(window.location.search);

    if (params.clearParams) {
      Array.from(currentUrlParams?.keys() || []).forEach((key) => {
        if (params.keepParams?.includes(key)) {
          return;
        }
        currentUrlParams.delete(key);
      });
    }

    if (params.queryParamsToRemove) {
      params.queryParamsToRemove.forEach((key: string) => {
        currentUrlParams.delete(key);
      });
    }

    if (params.newQueryParams) {
      Object.entries(params.newQueryParams || {}).forEach(([key, value]) => {
        currentUrlParams.set(key, value.toString());
      });
    }

    path = path.split("?")[0];

    const urlString = `${path}?${currentUrlParams.toString()}`;

    // dont navigate if the url is the same
    if (urlString === `${window.location.pathname}${window.location.search}`) {
      return;
    }

    useStore.getState().navigation.setNavToPath(urlString);
  }

  updateQueryParams(params: Record<string, string>) {
    this.navigate(window.location.pathname, {
      newQueryParams: params,
    });
  }

  clearQueryParams() {
    this.navigate(window.location.pathname, {
      clearParams: true,
    });
  }

  navToPlaylistStep(step: string) {
    this.updateQueryParams({ step });
  }

  navToPlaylist(playlistId: string) {
    this.navigate(`/playlists/${playlistId}`, { clearParams: true });
  }

  navToPlaylistLibrary() {
    this.navigate("/playlists", { clearParams: true });
  }

  navToPlaylistAndStep(params: { playlistId: string; step: string }) {
    if (!params.step) return;

    this.navigate(`/playlists/${params.playlistId}`, {
      clearParams: true,
      keepParams: ["partner"],
      newQueryParams: { step: params.step },
    });
  }

  navToPlaylistAndAutoNavToNextStep(params: { playlistId: string }) {
    this.navigate(`/playlists/${params.playlistId}`, {
      clearParams: true,
      newQueryParams: { autoNav: true },
    });
  }

  navToRecommendedPlaylists() {
    this.navigate("/playlists/onboarding?step=get-playlists", {
      clearParams: true,
    });
  }

  removeQueryParam(key: string) {
    this.navigate(window.location.pathname, {
      queryParamsToRemove: [key],
    });
  }

  openExternalLink(url: string) {
    window.open(url, "_blank");
  }

  navToLogin() {
    this.navigate("/login");
  }

  navToMostRecentPathname() {
    console.log("navToMostRecentPathname");
    const mostRecentPathname = useStore.getState().mostRecentPathname;
    if (mostRecentPathname) {
      this.navigate(mostRecentPathname, { simpleReplace: true });
    }
  }

  navToProfilePage() {
    this.navigate("/profile", { clearParams: true });
  }
}

export const Navigation = new NavigationServiceClass();
