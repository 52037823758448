import {
  IconAward,
  IconBook,
  IconBriefcase,
  IconBuilding,
  IconChecklist,
  IconPlaylist,
  IconUserCog,
  IconWorld,
  IconZoomQuestion,
} from "@tabler/icons-react";
import { IAdvizerSearchFilterKeys, IResourceKeys } from "@/types/StoreTypes";

export interface IResourceConfig {
  key: IResourceKeys;
  filtersKey: IAdvizerSearchFilterKeys;
  color: string;
  Icon: typeof IconBriefcase;
  order: number;
  label: string;
  labelLower: string;
}

const baseConfigs = {
  industry: {
    key: "industry" as IResourceKeys,
    filtersKey: "industries" as IAdvizerSearchFilterKeys,
    label: "Industry",
    labelLower: "industry",
    color: "accentColor1",
    Icon: IconWorld,
    order: 1,
  },
  jobFunction: {
    key: "jobFunction" as IResourceKeys,
    filtersKey: "jobFunctions" as IAdvizerSearchFilterKeys,
    label: "Job Function",
    labelLower: "job role",
    color: "accentColor2",
    Icon: IconBriefcase,
    order: 2,
  },
  subject: {
    key: "subject" as IResourceKeys,
    filtersKey: "subjects" as IAdvizerSearchFilterKeys,
    label: "Major",
    labelLower: "major",
    color: "accentColor3",
    Icon: IconBook,
    order: 3,
  },
  trait: {
    key: "trait" as IResourceKeys,
    filtersKey: "traits" as IAdvizerSearchFilterKeys,
    label: "Trait",
    labelLower: "trait",
    color: "accentColor4",
    Icon: IconUserCog,
    order: 4,
  },
  company: {
    key: "company" as IResourceKeys,
    filtersKey: "companies" as IAdvizerSearchFilterKeys,
    label: "Company",
    labelLower: "company",
    color: "accentColor5",
    Icon: IconBuilding,
    order: 5,
  },
  question: {
    key: "question" as IResourceKeys,
    filtersKey: "questions" as IAdvizerSearchFilterKeys,
    label: "Question",
    labelLower: "question",
    color: "primary",
    Icon: IconZoomQuestion,
    order: 6,
  },
  playlist: {
    key: "playlist" as IResourceKeys,
    label: "Playlist",
    labelLower: "playlist",
    filtersKey: "playlists" as IAdvizerSearchFilterKeys,
    color: "primary",
    Icon: IconPlaylist,
    order: 7,
  },
  assignment: {
    key: "assignment" as IResourceKeys,
    label: "Assignment",
    labelLower: "assignment",
    filtersKey: "assignments" as IAdvizerSearchFilterKeys,
    color: "orange",
    Icon: IconChecklist,
    order: 8,
  },
  degree: {
    key: "degree" as IResourceKeys,
    label: "Degree",
    labelLower: "degree",
    filtersKey: "degrees" as IAdvizerSearchFilterKeys,
    color: "indigo",
    Icon: IconAward,
    order: 9,
  },
};

export const ResourceConfigs: Record<
  keyof typeof baseConfigs | IAdvizerSearchFilterKeys,
  IResourceConfig
> = {
  ...baseConfigs,
  industries: baseConfigs.industry,
  jobFunctions: baseConfigs.jobFunction,
  subjects: baseConfigs.subject,
  traits: baseConfigs.trait,
  companies: baseConfigs.company,
  question: baseConfigs.question,
};
