import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Space,
  Stack,
} from "@mantine/core";
import { Text } from "../ui/Text";
import { PlaylistCard } from "../playlists/PlaylistCard";
import { IconExternalLink, IconPlus, IconX } from "@tabler/icons-react";
import { useStore } from "@/store";
import { useState, useEffect, useMemo } from "react";
import { PlaylistService } from "@/services/PlaylistService";
import { IPlaylistConfig } from "@/types/PlaylistTypes";
import { Link } from "../ui/Link";
import { PlaylistScale } from "../playlists/PlaylistScale";
import { PlaylistVideo } from "../playlists/PlaylistVideo";
import { AssignmentService } from "@/services/Assignments";
import { useActiveAssignmentId } from "@/store/selectors/partnerAppSelectors";
import { CopyPlaylistLink } from "../playlists/CopyPlaylistLink";
export function PlaylistPreview({ playlistId }: { playlistId: string }) {
  const [playlist, setPlaylist] = useState<IPlaylistConfig | null>(null);
  const activeAssignmentId = useActiveAssignmentId();
  const setActivePlaylistId = useStore(
    (state) => state.partnerApp.setActivePlaylistId,
  );
  const updatePlaylistState = useStore(
    (state) => state.playlists.updatePlaylistsState,
  );
  //   const playlist = useActivePlaylist(playlistId);

  console.log({ previewPlaylist: playlist });

  useEffect(() => {
    async function handleFetchPlaylist() {
      const playlist = await PlaylistService.fetchPlaylistById(playlistId);
      setPlaylist(playlist);
    }

    if (playlistId) {
      handleFetchPlaylist();
    }
  }, [playlistId, updatePlaylistState]);

  console.log("playlist", playlist);

  const PlaylistStepsPreview = useMemo(() => {
    return (
      <Stack gap={40} px="lg" pb={100}>
        {Object.entries(playlist?.steps || {})
          .filter(([stepKey]) => {
            return !["playlist-intro"].includes(stepKey);
          })
          .map(([stepKey, step]) => {
            let content = null;
            const isAfterStep = stepKey.endsWith("-after");
            const stepTitle =
              isAfterStep && typeof step.title === "string"
                ? (step.title || "").split("?")[0].trim()
                : step.title;

            switch (step.stepType) {
              case "scale-1-to-5":
                content = (
                  <PlaylistScale
                    id={stepKey}
                    playlistId={playlistId}
                    lowerText={step.stepParams?.lowerText || null}
                    upperText={step.stepParams?.upperText || null}
                    readOnly
                  />
                );
                break;
              case "video":
                content = (
                  <Stack gap={0} p={0}>
                    <PlaylistVideo
                      advizerId={step.stepParams?.advizerId}
                      videoQuestionNumber={step.stepParams?.videoQuestionNumber}
                      minimal
                    />
                  </Stack>
                );
                break;
              case "playlist-conclusion":
                content = (
                  <Stack>
                    <Text titleStyle="h5" lh={1.2}>
                      {stepTitle}
                    </Text>
                  </Stack>
                );
                break;
            }

            return (
              <Stack key={stepKey} gap="xs">
                <Text titleStyle="h4" lh={1.2} c="text.8" fw={500}>
                  {/* Step {index + 1}:  */}
                  {stepTitle}
                  {isAfterStep ? " " : ""}
                  {isAfterStep ? (
                    <span style={{ textDecoration: "underline" }}>
                      after watching the playlist?
                    </span>
                  ) : null}
                </Text>
                {content}
              </Stack>
            );
          })}
      </Stack>
    );
  }, [playlist?.steps, playlistId]);

  const Actions = useMemo(() => {
    return (
      <>
        <Text
          titleStyle="h4"
          lh={1.2}
          px="lg"
          style={{ textDecoration: "underline" }}
        >
          Actions
        </Text>

        <Group gap="xs" pb="md" pt="sm" px="lg">
          {activeAssignmentId && (
            <Button
              variant="filled"
              size="xs"
              radius="xl"
              color="primary"
              leftSection={<IconPlus size={16} />}
              onClick={() => {
                AssignmentService.addPlaylistToAssignment({
                  assignmentId: activeAssignmentId,
                  playlistId,
                });
              }}
            >
              Add to assignment
            </Button>
          )}
          <CopyPlaylistLink playlistId={playlistId} size="sm" />
          <Link to={`/playlists/${playlistId}`} target="_blank">
            <Button
              variant="light"
              size="xs"
              radius="xl"
              color="primary"
              leftSection={<IconExternalLink size={16} />}
            >
              View as student
            </Button>
          </Link>
        </Group>
      </>
    );
  }, [activeAssignmentId, playlistId]);

  return (
    <Stack gap={0} w="100%" p={0} pt={0} bg="background.1">
      <Group
        justify="flex-end"
        gap="xs"
        align="center"
        p={0}
        pt="xs"
        px="xs"
        pos="sticky"
        top={0}
        // bg="background.0"
        h={24}
        style={{
          zIndex: 10,
        }}
      >
        <ActionIcon
          variant="light"
          size="md"
          radius="xl"
          color="text.6"
          style={{ zIndex: 100 }}
          onClick={() => setActivePlaylistId(null)}
        >
          <IconX size={16} />
        </ActionIcon>
      </Group>
      <Space h="xs" />

      <Stack gap={0} px="lg" pr="xl">
        <PlaylistCard
          playlistId={playlistId}
          isPreview
          advizerQuoteLineClamp={5}
        />
      </Stack>
      <Space h="md" />
      <Divider w="100%" />
      <Space h="md" />
      {Actions}
      <Divider w="100%" />
      <Space h="xl" />
      <Text
        titleStyle="h4"
        lh={1}
        px="lg"
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          textDecoration: "underline",
        }}
      >
        {/* <IconPlaylist size={24} /> */}
        Playlist Preview
      </Text>
      <Space h="lg" />

      {PlaylistStepsPreview}
    </Stack>
  );
}
