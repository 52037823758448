import { Avatar, Group, Loader, Space, Stack } from "@mantine/core";
import { AdvizerVideoCard } from "@/components/AdvizerVideoCard";
import { AdvizerSearchControls } from "@/components/advizerSearchControls/AdvizerSearchControls";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import emilyAvatar from "@/assets/emily-avatar.jpeg";
import {
  useActiveUserPartner,
  useCurrentPartnerHasSpecialQuestions,
  useFilteredAdvizers,
  useIsAdvizersFiltered,
  useIsApiDown,
  useIsLoggedIn,
  useIsSearchingAdvizers,
  useQuestions,
} from "@/store/selectors";
import { AdvizerSearchFilters } from "@/components/AdvizerSearchFilters";
import { FC, useCallback, useMemo, useState } from "react";
// import { AutoSizer } from "react-virtualized";
import { useStore, useValueFromBreakpoint } from "@/hooks";
import { ErrorOrNoResults } from "@/components/templates/ErrorOrNoResults";
import { IconArrowDown } from "@tabler/icons-react";
import { Title } from "@/components/ui/Title";
import { Text } from "@/components/ui/Text";
import { Button } from "@/components/ui/Button";
import { Analytics } from "@/services/Analytics";
import { useLocation } from "react-router-dom";
import { config } from "@/config";
import {
  FixedSizeList as _FixedSizeList,
  FixedSizeListProps,
} from "react-window";
import { AutoSizer as _AutoSizer, AutoSizerProps } from "react-virtualized";

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const FixedSizeList = _FixedSizeList as unknown as FC<FixedSizeListProps>;

// TODO use virtualized list
export const AdvizerSearchPage = () => {
  const { theme, ...styles } = useStyles();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isApiDown = useIsApiDown();
  const isInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.isInlineAdvizerSearchOpen,
  );
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const setCollapseHeader = useStore(
    (state) => state.advizerSearch.setCollapseHeader,
  );
  const { advizers, isAdvizersLoading } = useFilteredAdvizers();
  const isAdvizersFiltered = useIsAdvizersFiltered();
  const partner = useActiveUserPartner();
  const partnerHasSpecialQuestions = useCurrentPartnerHasSpecialQuestions();
  const questions = useQuestions();
  const showUserInterviewForm = useStore(
    (state) => state.user.showUserInterviewForm,
  );
  const { isSearchingAdvizers } = useIsSearchingAdvizers();
  const isExplorePath = useLocation()?.pathname === "/explore";
  const [isTogglingFilters] = useState(false);
  const isLoggedIn = useIsLoggedIn();

  // useEffect(() => {
  //   console.log("advizers", { advizers });
  // }, [advizers]);

  // useEffect(() => {
  //   // if (isAdvizersFiltered) {
  //   //   setIsTogglingFilters(true);
  //   // }
  //   setIsTogglingFilters(true);
  //   setTimeout(() => {
  //     setIsTogglingFilters(false);
  //   }, 200);
  // }, [isInlineAdvizerSearchOpen]);

  const advizerVideoCardHeight = useValueFromBreakpoint({
    mobile: 800,
    tablet: 850,
    laptop: 500,
    desktop: 550,
  });

  const maxVideoCardWidth = useValueFromBreakpoint({
    mobile: 500,
    tablet: 650,
    laptop: 1050,
    desktop: 1200,
  });

  const showMainBanner = useMemo(
    () => !isExplorePath && !isAdvizersFiltered && !isLoggedIn,
    [isAdvizersFiltered, isExplorePath, isLoggedIn],
  );

  const MainTitleText = useMemo(() => {
    // if (showUserInterviewForm) {
    //   return (
    //     <Title order={1} fz={40} c="primary.7" lh={1.2}>
    //       Help us improve Advize and get an Amazon Gift Card
    //     </Title>
    //   );
    // }
    // if (partner && partnerHasSpecialQuestions) {
    //   return (
    //     <Title order={1} fz={40} c="primary.7" lh={1.2}>
    //       Your alums answered
    //       <br />
    //       <b>special questions!</b>
    //     </Title>
    //   );
    // }
    // return (
    //   <>
    //     <Title order={1} c="primary.8" fz={40} lh={1.2}>
    //       Stressed about
    //     </Title>
    //     <Title order={1} c="primary.8" fz={40} lh={1.2}>
    //       career choices?
    //     </Title>
    //   </>
    // );

    return (
      <Stack align="center" justify="center" gap="lg" maw={600}>
        <Avatar
          src={emilyAvatar}
          size={100}
          alt="Emily, CEO of Advize"
          style={{}}
        />
        <Title order={1} size="h2" lh={1.2}>
          Hey! I’m Emily. Watch {config.dbTotals.videos.toLocaleString()}+ short
          interview videos from {config.dbTotals.advizers.toLocaleString()}+
          working professionals to build your career confidence and land a job
          you'll love.
        </Title>
      </Stack>
    );
  }, []);

  const SubTitleText = useMemo(() => {
    return null;
    if (showUserInterviewForm) {
      return (
        <Stack w="100%" align="center" justify="center" gap="lg" ta="left">
          <Title order={2} size="h3" c="text.8" fw={600} ta="center" maw={500}>
            We're always learning from our users! Fill out a super short survey
            to be eligible for a 30 minute user interview. If interviewed, we'll
            send you a $10 Amazon gift card!
          </Title>
          <Button
            size="lg"
            radius="xl"
            fullWidth
            maw={300}
            w="100%"
            variant="light"
            to="https://forms.gle/gTzTTRtsn25h8EFa9"
            target="_blank"
            onClick={() => {
              Analytics.click({
                action: "Take the survey",
              });

              localStorage.setItem(
                "advize_didClickUserInterviewSurvey",
                "true",
              );
            }}
          >
            Take the survey
          </Button>
        </Stack>
      );
    }
    if (partner && partnerHasSpecialQuestions) {
      return (
        <Stack
          w="100%"
          align="start"
          justify="center"
          pt="md"
          gap="lg"
          ta="left"
        >
          {questions
            .filter((question) => !question.isStandard)
            .map((question) => (
              <Title order={4} size="h4" key={question.id} c="text.8">
                ⭐ Q{question.number}:{" "}
                {question.title.endsWith("?")
                  ? question.title
                  : question.title + "?"}
              </Title>
            ))}
        </Stack>
      );
    }
    return (
      <>
        Easily explore {config.dbTotals.videos.toLocaleString()}+ career videos
        from {config.dbTotals.advizers.toLocaleString()}+ professionals working
        in all sorts of industries. Get the inside scoop, discover your dream
        career, and start planning your future with confidence.
      </>
    );
  }, [partner, partnerHasSpecialQuestions, questions, showUserInterviewForm]);

  const renderMainBanner = useCallback(
    ({ style, key }: { style: any; key: string }) => {
      if (!showMainBanner) return null;

      return (
        <Group
          id={"banner"}
          align="start"
          justify="center"
          key={key}
          pt={0}
          w="100%"
          style={{
            ...style,
            overflow: "hidden",
          }}
          gap={0}
        >
          <Stack
            h="100%"
            justify="center"
            align="center"
            ta="center"
            maw={600}
            gap="xs"
            p="xs"
          >
            <Group w="100%" gap={6} justify="center" align="center">
              {MainTitleText}
            </Group>

            <Title
              order={2}
              size="h3"
              c="text.8"
              fw={600}
              ta="center"
              maw={500}
            >
              {SubTitleText}
              <br />
            </Title>
            <Space h="xs" />

            {!showUserInterviewForm && (
              <Stack
                align="center"
                justify="center"
                style={{ fontSize: 44 }}
                gap={2}
              >
                {/* <span>😨</span> */}
                <IconArrowDown
                  size={32}
                  color={theme.colors.primary[7]}
                  className="pulse-animation"
                />
                {/* <span>😌</span> */}
              </Stack>
            )}
          </Stack>
        </Group>
      );
    },
    [
      MainTitleText,
      SubTitleText,
      showMainBanner,
      showUserInterviewForm,
      theme.colors.primary,
    ],
  );

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      if (showMainBanner && index === 0) {
        return renderMainBanner({ style, key: String(index) });
      }

      const advizer = advizers?.[showMainBanner ? index - 1 : index];
      if (!advizer) return null;

      return (
        <Group
          id={String(index)}
          align="start"
          justify="center"
          key={advizer.id}
          pt={0}
          w="100%"
          style={{
            ...style,
            overflow: "hidden",
          }}
          gap={0}
        >
          <AdvizerVideoCard
            advizerId={advizer.id}
            advizer={advizer}
            maxWidth={maxVideoCardWidth}
          />
        </Group>
      );
    },
    [showMainBanner, advizers, maxVideoCardWidth, renderMainBanner],
  );

  const renderFailedApi = useCallback(() => {
    return (
      <Stack mih={400} h="50vh" align="center" justify="center" pt="xl" gap={4}>
        <Title order={1} size="h2" c="text.9">
          Shoot, something went wrong 😖
        </Title>
        <Text c="text.9">
          We're working on it and will be back up as soon as possible!
        </Text>
      </Stack>
    );
  }, []);

  const renderAdvizers = useCallback(() => {
    if (isAdvizersLoading || isSearchingAdvizers || isTogglingFilters) {
      return (
        <Stack mih={400} h="50vh" align="center" justify="center" pt="xl">
          <Loader color="primary.5" size="xl" />
          <Title order={1} size="h2" c="text.7">
            Loading Advizers...
          </Title>
        </Stack>
      );
    }

    if (!advizers?.length) {
      return <ErrorOrNoResults />;
    }

    // TODO get tallest one in list, just force row height and card height to be same
    return (
      <Stack flex={1} w="100%" align="flex-start">
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              width={width}
              height={height}
              itemCount={advizers?.length + (showMainBanner ? 1 : 0) || 0}
              itemSize={advizerVideoCardHeight}
              onScroll={(props) => {
                if (
                  props.scrollDirection === "forward" &&
                  props.scrollOffset > styles.dimensions.headerHeight
                ) {
                  setCollapseHeader(true);
                } else {
                  setCollapseHeader(false);
                }
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Stack>
    );
  }, [
    advizerVideoCardHeight,
    advizers?.length,
    isAdvizersLoading,
    isSearchingAdvizers,
    isTogglingFilters,
    rowRenderer,
    setCollapseHeader,
    showMainBanner,
    styles.dimensions.headerHeight,
  ]);

  return (
    <Group
      pt={collapseHeader ? 0 : styles.dimensions.headerHeight}
      gap={0}
      align="start"
      h="100vh"
      mah="100vh"
    >
      {isLaptopOrBigger && isInlineAdvizerSearchOpen ? (
        <Stack
          h={`calc(100vh - ${
            collapseHeader ? 0 : styles.dimensions.headerHeight
          }px)`}
          maw={400}
          flex={1}
          pos="sticky"
          top={collapseHeader ? 0 : styles.dimensions.headerHeight - 10}
          style={{ zIndex: 10 }}
          gap={0}
          p={0}
        >
          <AdvizerSearchFilters />
        </Stack>
      ) : null}
      <Stack p={0} gap={0} flex={2} mah="100vh">
        <AdvizerSearchControls />
        <Stack align="center" w="100%" mih={"100vh"} gap="lg" bg="background.0">
          {isApiDown ? renderFailedApi() : renderAdvizers()}
        </Stack>
      </Stack>
    </Group>
  );
};
